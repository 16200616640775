import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../../../styles/select.css";

const SelectTruckType = ({ title, data, onChange }) => {
  const [selected, setSelected] = useState("");

  // const handleChange = (e) => {
  //   const selectedValue = e.target.value;
  //   setSelected(selectedValue);

  //   onChange && onChange(selectedValue);
  // };

  // const handleChange = (e) => {
  //   const selectedValue = e.target.value;
  //   const selectedItem = data.find((item) => {
  //     return (
  //       item.id === selectedValue ||
  //       item.types.some((type) => type.id === selectedValue)
  //     );
  //   });
  //   setSelected(selectedItem.id);
  //   onChange && onChange(selectedItem.id);
  //   console.log(selectedItem.title)
  // };

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    const selectedItem = data.find((item) => {
      return (
        item.id === selectedValue ||
        item.types.some((type) => type.id === selectedValue)
      );
    });

    setSelected(selectedValue);
    if (selectedItem) {
      onChange({
        selectedId: selectedValue,
        selectedTitle: selectedItem.title
      });
    }
  };

  const menuItems = [];

  data.forEach((index) => {
    menuItems.push(
      <MenuItem key={index.id} value={index.id} className="!font-medium">
        {index.title}
      </MenuItem>
    );

    index.types.forEach((typeIndex) => {
      menuItems.push(
        <MenuItem
        className="!pl-10"
          key={typeIndex.id}
          value={typeIndex.id}
        >
          {typeIndex.title}
        </MenuItem>
      );
    });
  });

  return (
    <div className="w-full h-full">
      <Select
        className="w-full h-full"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selected}
        onChange={handleChange}
        displayEmpty
        MenuProps={{
          PaperProps: {
            sx: {
              "& .MuiMenuItem-root": {
                padding: 2,
              },
            },
          },
        }}
      >
        <MenuItem id="defaultvalue" value={""}>
          {title}
        </MenuItem>
        {menuItems}
      </Select>
    </div>
  );
};

export default SelectTruckType;
