import { useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { format } from "date-fns";

const ReportDetail = ({ data, darkMode }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data !== "") {
      setLoading(false);
    }
  }, [data]);

  if (loading) {
    return (
      <div
        className={`fixed h-screen w-full flex flex-col justify-center items-center pb-[11.5rem]  ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <PulseLoader color="#fbbf24" />
      </div>
    );
  }

  const formatDateCreate = (dateString) => {
    const formattedDate = format(new Date(dateString), "MM/dd/yyyy");
    return formattedDate;
  };

  return (
    <div
      className={`w-full h-auto flex flex-col justify-center items-start gap-4 rounded-md p-4 cursor-default font-normal text-sm ${
        darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
      }`}
    >
      <h1 className="w-full h-auto text-xl uppercase max-sm:text-xl">
        Report details
      </h1>
      <div className="w-full h-auto flex flex-row justify-between items-center ">
        <p className="cursor-default text-[#a2a6ad] max-md:text-sm">
          Created {formatDateCreate(data.created_at)}
        </p>
      </div>
      <div className="flex flex-col justify-start items-start gap-3 font-normal">
        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            {data.report_type === "dropoff"
              ? "Reciver Name"
              : data.report_type === "pickup"
              ? "Shipper Name"
              : "Name"}
          </p>
          <div className="flex flex-row justify-start items-center gap-1">
            <p>{data.name !== null ? data.name : "- - -"}</p>
          </div>
        </div>

        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Description
          </p>
          <p>{data.description}</p>
        </div>

        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Piece
          </p>
          <p>{data.piece !== null ? data.piece : "---"} </p>
        </div>

        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            BOL number
          </p>
          <p>{data.bol_number !== null ? data.bol_number : "---"} </p>
        </div>

        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Weight
          </p>
          <p>{data.weight !== null ? data.weight : "---"} </p>
        </div>

        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            citizenship
          </p>
          <p>{data.citizenship !== null ? data.citizenship : "---"} </p>
        </div>
        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            phone
          </p>
          <p>{data.phone !== null ? data.phone : "---"} </p>
        </div>
        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Report Type
          </p>
          <p>{data.report_type}</p>
        </div>
        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            location
          </p>
          <p>{data.location}</p>
        </div>
        <div className="w-full h-auto flex flex-col justify-center items-start gap-1 flex-wrap pt-2">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Images
          </p>
          <div className="w-full h-auto flex flex-row justify-start items-center gap-1 flex-wrap pt-2">
            {data.galleries.length > 0 ? (
              data.galleries.map((index) => (
                <img
                  className="w-5/12 h-auto rounded-md"
                  key={index.id}
                  src={`${process.env.REACT_APP_REGISTER_BASE_URL}/${index?.image}`}
                  alt={index.name}
                />
              ))
            ) : (
              <p> --- </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDetail;
