import { createSlice } from "@reduxjs/toolkit";

const initialState = 1;

const refetchMessagesSlice = createSlice({
  name: "refetchMessages",
  initialState,
  reducers: {
   setRefetchMessages: (state, action) => {
      return action.payload;
    },
  },
});

export const { setRefetchMessages } = refetchMessagesSlice.actions;
export default refetchMessagesSlice.reducer;


