import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import DisplayStatusUser from "../../components/module/DisplayStatusUser";
import MobileMenu from "../../components/module/MobileMenu";
import axiosConfig from "../../config/axiosConfig";
import Cookies from "js-cookie";
import { GoInbox } from "react-icons/go";
import { PulseLoader } from "react-spinners";
import MessageCard from "../../components/module/card/MessageCard";
import { setRefetchMessages } from "../../redux_toolkit/features/refetchMessagesSlice";

const Messages = () => {
  const userId = Cookies.get("userId");
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode);
  const refetchMessages = useSelector((state) => state.refetchMessages);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMessages = async () => {
      if (userId) {
        const token = Cookies.get("token");
        const baseURL = "https://atoz-messenger-service.888shipping.online";
        const endpoint = `/api/v1/user/${userId}/get-messages`;
        const axiosInstance = axiosConfig(baseURL, token);

        try {
          const res = await axiosInstance.get(endpoint);
          if (res.status === 200) {
          
            const messageIds = res.data.data.data.map((message) => message.id);

            setData(res.data.data.data);
            setLoading(false);

            if(res.data.data.data.length > 0){
              const seenMessagesEndpoint = "/api/v1/user/seen-messages";
              const seenMessagesAxiosInstance = axiosConfig(baseURL, token);
  
              try {
                const values = {
                  notification_ids: messageIds,
                  user_id: userId,
                };
  
                const seenMessagesRes = await seenMessagesAxiosInstance.post(seenMessagesEndpoint, values);
  
                if (seenMessagesRes.status === 200) {
                  dispatch(setRefetchMessages(refetchMessages + 1));
                  fetchMessagesAgain()
                }
              } catch (error) {
                console.error(error);
              }
            }
          }
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    };

    fetchMessages();
  }, [userId, dispatch]);

  const fetchMessagesAgain = async () => {
    const token = Cookies.get("token");
    const baseURL = "https://atoz-messenger-service.888shipping.online";
    const endpoint = `/api/v1/user/${userId}/get-messages`;
    const axiosInstance = axiosConfig(baseURL, token);

    try {
      const res = await axiosInstance.get(endpoint);
      if (res.status === 200) {
        setData(res.data.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Account</title>
      </Helmet>
      <div className={`fixed w-full min-h-screen flex flex-col justify-start items-center ${darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"}`}>
        <div className="w-full h-auto fixed top-0 left-0 right-0">
          <DisplayStatusUser />
        </div>
        <div className="w-full h-full pt-20 overflow-auto">
          {loading ? (
            <div className={`fixed h-screen w-full flex flex-col justify-center items-center pb-[11.5rem] ${darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"}`}>
              <PulseLoader color="#fbbf24" />
            </div>
          ) : (
            <div className="w-full h-screen flex flex-col justify-start items-center gap-2 px-4 overflow-auto pb-[11.5rem]">
              {data && data.length === 0 ? (
                <div className={`w-full h-auto flex flex-col justify-center items-center gap-2 rounded-md p-4 ${darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"}`}>
                  <GoInbox className="text-3xl" />
                  <p className="cursor-default text-base max-md:text-sm max-sm:text-xs">There are no messages</p>
                </div>
              ) : (
                data && data.map((message) => <MessageCard key={message.id} {...message} />)
              )}
            </div>
          )}
        </div>
        <div className="fixed bottom-0 left-0 right-0 shadow-2xl">
          <MobileMenu />
        </div>
      </div>
    </>
  );
};

export default Messages;
