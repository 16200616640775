import { createSlice } from "@reduxjs/toolkit";

const initialState = 'offline';

const statusUserSlice = createSlice({
  name: "statusUser",
  initialState,
  reducers: {
    setStatusUser: (state, action) => {
      return action.payload;
    },
  },
});

export const { setStatusUser } = statusUserSlice.actions;
export default statusUserSlice.reducer;
