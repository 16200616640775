import { useEffect, useState } from "react";
import DefaultField from "../../module/field/DefaultField";
import { Formik, Form, ErrorMessage } from "formik";
import MaskedInput from "react-maskedinput";
import styles from "../../../styles/styles.module.css";
import ImageUploader from "../../module/ImageUploader";
import AboutMessage from "../../module/AboutMessage";
import axiosConfig from "../../../config/axiosConfig";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";
import Spinner from "../../module/Spinner";
import InfoDisplayBox from "../../module/InfoDisplayBox";
import MoreInfoDriverLicence from "../../module/modal/MoreInfoDriverLicence";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScreenProtector from "../../module/modal/ScreenProtector";

const DriverLicenceForm = ({ setActiveStep }) => {
  const navigate = useNavigate();
  const statusRegister = useSelector((state) => state.statusRegister);

  const [screenProtector, setScreenProtector] = useState(false);
  const [driverLicenceImage, setDriverLicenceImage] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [driverLicenceInfo, setDriverLicenceInfo] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const fetchTruckInfo = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_DRIVER_LICENCE_INFO;
      const axiosInstance = axiosConfig(baseURL, token);

      try {
        const res = await axiosInstance.get(endpoint);
        setDriverLicenceInfo(res.data.data);

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchTruckInfo();
  }, []);

  if (loading) {
    return (
      <div className="h-screen w-full flex flex-col justify-center items-center bg-[#F1F5F9]">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full h-screen flex flex-col justify-start items-center bg-[#F1F5F9] text-black font-normal pt-8">
      <div className="w-11/12 h-auto flex flex-col justify-center items-start gap-10 bg-[#E2E8F0] rounded-md p-8">
        <div className="flex flex-row justify-center items-end gap-2">
          <h1 className="text-xl uppercase max-sm:text-sm">Register</h1>
          <p className="text-xl text-[#1976D2] uppercase max-sm:text-sm">
            Driver Licence
          </p>
        </div>
        <Formik
          initialValues={{
            driver_licence_image: "",
            driver_licence: "",
            expiration: "",
          }}
          validate={(values) => {
            const errors = {};

            // if (!values.driver_licence) {
            //   errors.driver_licence = "Please enter driver licence number";
            // } else if (
            //   !(
            //     /[A-Za-z]/.test(values.driver_licence) &&
            //     /\d/.test(values.driver_licence) &&
            //     /[@$!%*?&^~#=+\-×)(]/.test(values.driver_licence)
            //   )
            // ) {
            //   errors.driver_licence =
            //     "should contain at least one letter, one number, and one special character";
            // }

            if (!values.driver_licence) {
              errors.driver_licence = "Please enter driver licence number";
            } else if (!/^[A-Za-z0-9]+$/.test(values.driver_licence)) {
              errors.driver_licence = "Driver licence number must contain only letters and numbers";
            }
            if (!values.expiration) {
              errors.expiration = "Please enter expiration";
            }

            return errors;
          }}
          onSubmit={async (values) => {
            try {
              values.driver_licence_image = driverLicenceImage;

              if (values.driver_licence_image.length === 0) {
                AboutMessage({
                  status: "error",
                  message: "Please upload file driver licence image",
                });
                return;
              }

              setBtnLoading(true);
              setScreenProtector(true);
              const token = Cookies.get("token");
              const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
              const endpoint = process.env.REACT_APP_DRIVER_LINCENCE_STORE_URL;
              const axiosInstance = axiosConfig(baseURL, token);

              const config = {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              };

              const response = await axiosInstance.post(
                endpoint,
                values,
                config
              );
              if (response.status === 200) {
                if (statusRegister === "complete") {
                  navigate("/");
                } else {
                  setActiveStep("bank-info");
                }
              }
            } catch (error) {
              if (error.response) {
                const errorsObject = error.response.data.message;
                Object.entries(errorsObject).forEach(([key, errorMessages]) => {
                  errorMessages.forEach((errorMessage) => {
                    AboutMessage({ status: "error", message: errorMessage });
                  });
                });
              }
            } finally {
              setBtnLoading(false);
              setScreenProtector(false);
            }
          }}
        >
          {({ setFieldValue }) => (
            <Form className="w-full h-auto flex flex-col gap-2 font-medium">
              {driverLicenceInfo.length !== 0 && (
                <InfoDisplayBox
                  title={"Your driver licence information"}
                  data={driverLicenceInfo}
                  setSelected={setSelected}
                  setShowModal={setShowModal}
                />
              )}
              {showModal && (
                <MoreInfoDriverLicence
                  data={selected}
                  setShowModal={setShowModal}
                />
              )}

              <ImageUploader
                title={"Driver Licence Image"}
                selectedFiles={driverLicenceImage}
                setSelectedFiles={setDriverLicenceImage}
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="driver_licence"
                minLength="0"
                maxLength="255"
                placeholder="Driver Licence Number"
              />
              <MaskedInput
                id="datefield"
                className={styles.stylefield}
                mask="11/11/1111"
                placeholder="Expiration mm/dd/yyyy"
                name="expiration"
                value="expiration"
                onChange={(e) => {
                  const result = e.target.value.replace(/\D/g, "-");
                  setFieldValue("expiration", result);
                }}
              />
              <ErrorMessage
                name="expiration"
                component="div"
                className="w-full h-auto text-sm text-red-600 font-normal max-sm:text-xs"
              />
              <div className="w-full h-auto flex flex-row justify-end items-center gap-1">
                <button
                  type="submit"
                  className="w-1/2 h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                >
                  {btnLoading ? (
                    <CircularProgress size={16} sx={{ color: "black" }} />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {screenProtector && <ScreenProtector />}
    </div>
  );
};

export default DriverLicenceForm;
