import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../../../styles/select.css";

const SelectReportType = ({ title, data, onChange }) => {
  const [selected, setSelected] = useState("");

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setSelected(selectedValue);
    onChange && onChange(selectedValue);
  };

  return (
    <div className="w-full h-full">
      <Select
        className="w-full h-full"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selected}
        onChange={handleChange}
        displayEmpty
        MenuProps={{
          PaperProps: {
            sx: {
              "& .MuiMenuItem-root": {
                padding: 2,
              },
            },
          },
        }}
      >
        <MenuItem id="defaultvalue" value={""}>
          {title}
        </MenuItem>
        {data &&
          data.map((item) => (
            <MenuItem key={item} value={item}>
              {`${item}`}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};

export default SelectReportType;
