import { Formik, Form } from "formik";
import DefaultField from "../../module/field/DefaultField";
import PasswordField from "../../module/field/PasswordField";
import axiosConfig from "../../../config/axiosConfig";
import AboutMessage from "../../module/AboutMessage";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";
import { useState } from "react";

const LoginForm = () => {
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  return (
    <Formik
      initialValues={{
        username: "",
        password: "",
      }}
      validate={(values) => {
        const errors = {};

        if (!values.username) {
          errors.username = "Please enter your email or phone number";
        } else if (
          !(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.username) ||
            /^\d+$/i.test(values.username)
          )
        ) {
          errors.username = "The email address or phone number is invalid";
        }

        if (!values.password) {
          errors.password = "Please enter your password";
        } else if (!/(?=.*[A-Z])(?=.*\d)/.test(values.password)) {
          errors.password =
            "The password must be a combination of upper and lower case letters and numbers";
        }

        return errors;
      }}
      onSubmit={async (values) => {
        const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
        const endpoint = process.env.REACT_APP_LOGIN_URL;
        
        const axiosInstance = axiosConfig(baseURL);
        setBtnLoading(true);
        try {
          const response = await axiosInstance.post(endpoint, values);
          const token = response.data.token;

          let expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 365);
          Cookies.set("token", token, {
            expires: expirationDate,
          });

          setBtnLoading(false);
          navigate("/register");
        } catch (error) {
          setBtnLoading(false);
          if (error.response) {
            AboutMessage({
              status: "error",
              message: error.response.data.message,
            });
          } else {
            console.error(error);
            setBtnLoading(false);
          }
        }
      }}
    >
      {() => (
        <Form className="w-6/12 h-auto flex flex-col gap-2 font-medium max-sm:w-10/12 max-md:w-8/12">
          <DefaultField
            stylefield="w-full flex flex-row justify-start items-center rounded-md p-3 text-white text-base outline-none bg-[#111827a6] placeholder:text-white max-sm:text-xs"
            type="text"
            name="username"
            minLength="0"
            maxLength="255"
            placeholder="Email or Phone Number"
          />
          <PasswordField
            name="password"
            minLength="8"
            maxLength="255"
            placeholder="Password"
          />
          <button
            type="button"
            className="flex flex-row justify-start items-center gap-1 font-normal text-base text-white max-md:text-sm max-sm:text-xs"
            onClick={() =>
              AboutMessage({
                status: "info",
                message: "This section will be activated soon",
              })
            }
          >
            <span className="text-amber-400">Forget Password ?</span>
          </button>
          <div className="w-full h-auto flex flex-col gap-2 text-base pt-10 text-white">
            <button
              type="submit"
              className="w-full h-auto flex flex-row justify-center items-center gap-1 bg-gradient-to-l from-amber-500 to-amber-400 rounded-md p-2 max-sm:text-xs"
            >
              {btnLoading ? (
                <CircularProgress size={16} sx={{ color: "black" }} />
              ) : (
                "Login"
              )}
            </button>
            <button
              type="button"
              className="w-full h-auto rounded-md p-2 max-sm:text-xs max-md:text-sm"
              id="register_btn"
              onClick={() => navigate("/register")}
            >
              Regesteration
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
