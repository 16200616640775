
import { Helmet } from "react-helmet";
import ProfileDetails from "../../components/template/ProfileDetails";
import MobileMenu from "../../components/module/MobileMenu";
import { useSelector } from "react-redux";
import useCheckRegistration from "../../hooks/useCheckRegistration";
import { useState } from "react";
import fetchUserInfo from "../../utils/fetchUserInfo";
import { useEffect } from "react";
import DisplayStatusUser from "../../components/module/DisplayStatusUser";

const Profile = () => {
  const darkMode = useSelector((state) => state.darkMode);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [data, setData] = useState(null);

  useCheckRegistration({ setChecked });

  useEffect(() => {
    fetchUserInfo({ setData, setLoading });
  }, []);

  return (
    <>
      <Helmet>
        <title>Profile</title>
      </Helmet>
      <div
        className={`w-full max-h-screen flex flex-col justify-start items-center ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <div className="w-full h-auto fixed top-0 left-0 right-0">
          <DisplayStatusUser />
        </div>
        <div className="w-full h-screen flex flex-col justify-end items-center pt-20 pb-[6.5rem]">
          <ProfileDetails data={data} checked={checked} loading={loading} />
        </div>
        <div className="fixed bottom-0 left-0 right-0 shadow-2xl">
          <MobileMenu />
        </div>
      </div>
    </>
  );
};

export default Profile;
