import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaAngleRight } from "react-icons/fa6";
import { format } from "date-fns";

const ReportCard = ({ id, name, created_at, description, report_type }) => {
  const darkMode = useSelector((state) => state.darkMode);

  const formatDateCreate = (dateString) => {
    const formattedDate = format(new Date(dateString), "MM/dd/yyyy");
    return formattedDate;
  };

  return (
    <Link
      to={`/loads/report/${id}`}
      className={`w-4/12 h-auto flex flex-col justify-center items-start gap-4 rounded-md p-4 cursor-pointer font-normal text-sm max-md:w-full ${
        darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
      }`}
    >
      <div className="w-full h-auto flex flex-row justify-between items-center ">
        <p className="cursor-default text-[#a2a6ad] max-md:text-sm">
          Created {formatDateCreate(created_at)}
        </p>
        <FaAngleRight className="text-[#a2a6ad]" />
      </div>
      <div className="w-full h-auto flex flex-col justify-start items-start gap-3">
        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            {report_type === "dropoff"
              ? "Reciver Name"
              : report_type === "pickup"
              ? "Shipper Name"
              : "Name"}
          </p>
          <div className="flex flex-row justify-start items-center gap-1">
            <p>{name !== null ? name : "- - -"}</p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Description
          </p>
          <p>{description}</p>
        </div>

        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Report Type
          </p>
          <p>{report_type}</p>
        </div>
      </div>
    </Link>
  );
};

export default ReportCard;
