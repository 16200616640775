import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

const unConfirmedStepSlice = createSlice({
  name: "unConfirmedStep",
  initialState,
  reducers: {
    setUnConfirmedStep: (state, action) => {
      return action.payload;
    },
  },
});

export const { setUnConfirmedStep } = unConfirmedStepSlice.actions;
export default unConfirmedStepSlice.reducer;