const DisplayAdminNote = ({ note , setAdminNote , dispatch }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60 z-10">
      <div className="modal font-normal">
        <div className="modal-content">
          <p>{note}</p>
          <button 
          type="button"
          className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
          onClick={() => dispatch(setAdminNote(null))}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default DisplayAdminNote;
