import { format } from "date-fns";
import { FaAngleRight } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { FaCircle } from "react-icons/fa";
import { useState } from "react";
import DetailsFinancialReports from "../modal/DetailsFinancialReports";

const FinancialReportCard = ({
  created_at,
  status,
  amount,
  history_number,
  description,
  type,
}) => {
  const darkMode = useSelector((state) => state.darkMode);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const formatDateCreate = (dateString) => {
    const formattedDate = format(new Date(dateString), "dd/MM/yyyy");
    return formattedDate;
  };

  const openModal = () => {
    setModalIsOpen(true);
  };

  return (
    <>
      <div
        onClick={openModal}
        className={`w-full h-auto flex flex-col justify-center items-start gap-3 rounded-md p-4 cursor-pointer font-normal text-sm max-md:w-full ${
          darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
        }`}
      >
        <div className="w-full h-auto flex flex-row justify-between items-center ">
          <p className="cursor-default flex flex-row justify-center items-center gap-1 text-[#a2a6ad] max-md:text-sm">
            Posted on
            <span>{formatDateCreate(created_at)}</span>
          </p>
          <FaAngleRight className="text-[#a2a6ad]" />
        </div>

        <div className="flex flex-row justify-center items-center gap-1 cursor-default font-normal text-xs text-green-600">
          <FaCircle className="relative top-[1px]" />
          <span className="text-sm">{status}</span>
        </div>
        <div className="flex flex-row justify-center items-center gap-1 cursor-default font-normal text-xs">
          <p>Amount : </p>
          <p className="text-sm">{amount}</p>
        </div>
      </div>

      {modalIsOpen && (
        <DetailsFinancialReports
          amount={amount}
          created_at={created_at}
          history_number={history_number}
          status={status}
          description={description}
          type={type}
          setModalIsOpen={setModalIsOpen}
        />
      )}
    </>
  );
};

export default FinancialReportCard;
