import React, { useRef } from "react";
import SignatureCanvas from "../SignatureCanvas";
import AboutMessage from "../AboutMessage";

const ModalSignatureCanvas = ({ setShowModalSignatureCanvas, setSignature }) => {
  const signatureRef = useRef(null);

  const handleSaveSignature = () => {
    if (signatureRef.current.isEmpty()) {
      AboutMessage({
        status: "error",
        message: 'Please enter your signature.',
      });
      return;
    }

    const signatureImage = signatureRef.current.toDataURL();
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    img.onload = function () {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      canvas.toBlob((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          setSignature(base64data)
          setShowModalSignatureCanvas(false)
        };
      }, "image/webp");
    };

    img.src = signatureImage;
  };

  return (
    <div>
      <div id="modalstylesignaturecanvas" className="modal font-normal">
        <div id="modalcontentsignaturecanvas" className="modal-content">
          <p className="h-[5vh] w-full flex flex-row justify-start items-center text-black text-base font-normal pb-2">
            Signature
          </p>
          <div className="w-full h-[65vh] rounded-md shadow-md">
            <SignatureCanvas ref={signatureRef} />
          </div>
          <div className="w-full h-[5vh] flex flex-row justify-center items-center gap-1">
            <button
              className="w-1/2 h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
              type="button"
              onClick={handleSaveSignature}
            >
              Save
            </button>
            <button
              className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
              type="button"
              onClick={() => setShowModalSignatureCanvas(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalSignatureCanvas;
