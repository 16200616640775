import { useSelector } from "react-redux";
import { PiUserCircleThin } from "react-icons/pi";
import { format } from "date-fns";
import { PulseLoader } from "react-spinners";

const AccountDetails = ({
  loading,
  first_name,
  middle_name,
  last_name,
  email,
  status_register,
  best_call_contact,
  phone_number,
  company,
  company_name,
  citizenship_ssn,
  citizenship_ein,
  company_type,
  created_at,
}) => {
  const darkMode = useSelector((state) => state.darkMode);

  const formatDateCreate = (dateString) => {
    const formattedDate = format(new Date(dateString), "MM/dd/yyyy");
    return formattedDate;
  };

  if (loading) {
    return (
      <div
        className={`h-screen w-full flex flex-col justify-center items-center pb-[11.5rem] ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <PulseLoader color="#fbbf24"/>
      </div>
    );
  }

  return (
    <div
      className={`w-full h-auto flex flex-col justify-center items-start gap-4 rounded-md p-4 cursor-default font-normal text-sm ${
        darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
      }`}
    >
      <div className="w-full flex flex-col justify-center items-start gap-4">
        <div className="flex flex-row justify-start items-center gap-2">
          <PiUserCircleThin className="text-5xl" />
          <div>
            <div className="flex flex-ow justify-start items-center gap-1">
              <p>{first_name}</p>
              <p>{middle_name}</p>
              <p>{last_name}</p>
            </div>
            <div className="w-full h-auto flex flex-row justify-start items-center flex-wrap">
              <span className="break-all">{email}</span>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col justify-start items-start gap-4">
          <div className="w-full h-auto flex flex-row justify-between items-center">
            <div className="w-1/2 flex flex-col justify-center items-start cursor-default max-md:text-sm">
              <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                Status Register
              </p>
              <p>{status_register}</p>
            </div>
            <div className="w-1/2 flex flex-col justify-center items-start cursor-default max-md:text-sm">
              <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                Best way to contact
              </p>
              <p>{best_call_contact}</p>
            </div>
          </div>
          <div className="w-full h-auto flex flex-col justify-center items-start">
            {phone_number.map((phoneNumber, index) => (
              <div
                key={index}
                className="w-full flex flex-row justify-between items-center"
              >
                <div className="w-1/2 flex flex-col justify-center items-start cursor-default max-md:text-sm">
                  <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                    phone
                  </p>
                  <p>{phoneNumber.phone}</p>
                </div>
                <div className="w-1/2 flex flex-col justify-center items-start cursor-default max-md:text-sm">
                  <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                    backup number
                  </p>
                  <p>{phoneNumber.backup_number}</p>
                </div>
              </div>
            ))}
          </div>
          <div className="w-full h-auto flex flex-row justify-between items-center">
            <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
              <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                Company
              </p>
              <p>{company}</p>
            </div>
            <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
              <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                Company name
              </p>
              {company_name !== null ? <p>{company_name}</p> : <p> - - - </p>}
            </div>
          </div>
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              Company type
            </p>
            {company_type !== null ? <p>{company_type}</p> : <p> - - - </p>}
          </div>
          <div className="w-full flex flex-row justify-between items-center">
            <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
              <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                Citizenship ssn
              </p>
              {citizenship_ssn !== null ? (
                <p>{citizenship_ssn}</p>
              ) : (
                <p> - - - </p>
              )}
            </div>
            <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
              <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                Citizenship ein
              </p>
              {citizenship_ein !== null ? (
                <p>{citizenship_ein}</p>
              ) : (
                <p> - - - </p>
              )}
            </div>
          </div>
          <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              Create
            </p>
            <p>{formatDateCreate(created_at)}</p>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
