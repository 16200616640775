import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import axiosConfig from "../../config/axiosConfig";
import SubmitOffer from "../module/modal/SubmitOffer";
import SubmitNewOffer from "../module/modal/SubmitNewOffer";
import RejectLoad from "../module/modal/RejectLoad";
import SubmitReport from "../module/modal/SubmitReport";
import AboutMessage from "../module/AboutMessage";
import MessageBox from "../module/MessageBox";
// import fetchReports from "../../utils/fetchReports";
import LoadDetailInfo from "../module/LoadDetailInfo";
import { useDispatch, useSelector } from "react-redux";
import { setMessageBoxInfo } from "../../redux_toolkit/features/messageBoxInfoSlice";

const LoadDetail = ({ loadData, userData, darkMode, setReloadPage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const parts = pathname.split("/");
  const loadId = parts[2];

  const messageList = useSelector((state) => state.messageList);
  const messageBoxInfo = useSelector((state) => state.messageBoxInfo);

  const [reports, setReports] = useState([]);
  const [showModalSubmitOffer, setShowModalSubmitOffer] = useState(false);
  const [showModalSubmitNewOffer, setShowModalSubmitNewOffer] = useState(false);
  const [showModalSubmitReport, setShowModalSubmitReport] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showBtnsSubmitOffer, setShowBtnsSubmitOffer] = useState(true);
  const [showBtnsSubmitNewOffer, setShowBtnsSubmitNewOffer] = useState(false);
  const [showMessageBox, setShowMessageBox] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  const fetchReports = async () => {
    const token = Cookies.get("token");
    const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;

    const endpoint = `/api/v1/user/my-load/reports/${messageBoxInfo[0].offer_load_id}`;
    const axiosInstance = axiosConfig(baseURL, token);
    try {
      const response = await axiosInstance.get(endpoint);
      if (response.status === 200) {
        setReports(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    messageList.forEach((item) => {
      if (item.loadId === loadId) {
        dispatch(setMessageBoxInfo([...messageBoxInfo, item]));
      }
    });
  }, [loadId, dispatch, messageList]);

  useEffect(() => {
    // messageBoxInfo !== "" && fetchReports({ setReports, messageBoxInfo });
    messageBoxInfo !== "" && fetchReports();
  }, [messageBoxInfo]);

  useEffect(() => {
    const fetchOffer = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_GET_OFFER_LOAD_MESSAGES;
      const axiosInstance = axiosConfig(baseURL, token);

      const values = {
        load_id: loadData.id,
      };

      try {
        const response = await axiosInstance.post(endpoint, values);
        if (response.status === 200) {
          if (response.data.data.length === 0) {
            setShowBtnsSubmitOffer(true);
          } else {
            setShowBtnsSubmitOffer(false);
            setShowMessageBox(true);
            setShowBtnsSubmitNewOffer(true);

            dispatch(setMessageBoxInfo(response.data.data));
            const offerLoadId = JSON.stringify(
              response.data.data[0].offer_load_id
            );
            Cookies.set("offerLoadId", offerLoadId);
          }
        }
      } catch (error) {
        AboutMessage({ status: "error", message: error.response.data.message });
      }
    };

    fetchOffer();
  }, [loadData, dispatch]);

  return (
    <div
      className={`w-full h-auto flex flex-col justify-center items-start gap-2 rounded-md p-4 cursor-default font-normal text-sm ${
        darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
      }`}
    >
      <LoadDetailInfo loadData={loadData} />

      {loading ? (
        <div className="w-full h-auto flex flex-col justify-center items-start gap-2 bg-[#F1F5F9] rounded-md shadow p-5 animate-pulse">
          <div className="w-full flex flex-row justify-end items-center">
            <div className="h-[5rem] w-6/12 flex flex-row justify-center items-start gap-1 bg-gray-300 text-white py-2 px-3 rounded-l-md rounded-br-md"></div>
          </div>
          <div className="w-full flex flex-row justify-start items-center">
            <div className="h-[5rem] w-6/12 flex flex-row justify-center items-start gap-1 bg-gray-300 text-white py-2 px-3 rounded-r-md rounded-bl-md"></div>
          </div>
        </div>
      ) : (
        <>
          {showMessageBox && messageBoxInfo.length > 0 && (
            <MessageBox messageBoxInfo={messageBoxInfo} />
          )}
        </>
      )}

      {loading ? (
        <div className="w-full h-auto flex flex-row justify-start items-center gap-1 bg-[#F1F5F9] rounded-md shadow p-5 animate-pulse">
          <div className="w-1/2 h-[2rem] px-6 rounded-md bg-gray-300 "></div>
          <div className="w-1/2 h-[2rem] px-6 rounded-md bg-gray-300 "></div>
        </div>
      ) : (
        <>
          {showBtnsSubmitOffer &&
            loadData.status !== "reject" &&
            loadData.status !== "accept" &&
            loadData.status !== "missed" && (
              <div className="w-full h-auto flex flex-row justify-end items-center gap-1">
                <button
                  onClick={() => setShowModalSubmitOffer(true)}
                  className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                >
                  Send your Offer
                </button>
                <button
                  onClick={() => setShowRejectModal(true)}
                  className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                >
                  Reject
                </button>
              </div>
            )}
          {showBtnsSubmitNewOffer &&
            loadData.status !== "reject" &&
            loadData.status !== "accept" &&
            loadData.status !== "transit" &&
            loadData.status !== "delivered" && (
              <div className="w-full h-auto flex flex-row justify-center items-center gap-1">
                {loadData.status === "missed" ? (
                  <p className="h-auto w-full text-red-600 font-normal p-4">
                    This time it was received by someone else.
                  </p>
                ) : (
                  <>
                    <button
                      className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                      type="button"
                      onClick={() => setShowModalSubmitNewOffer(true)}
                    >
                      Send new Offer
                    </button>
                    <button
                      className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                      onClick={() => setShowRejectModal(true)}
                    >
                      Reject
                    </button>
                  </>
                )}
              </div>
            )}
          {(loadData.status === "accept" || loadData.status === "transit") && (
            <div className="w-full h-auto flex flex-row justify-start items-center gap-1">
              <button
                onClick={() => setShowModalSubmitReport(true)}
                className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
              >
                Send Report
              </button>
              {reports.length !== 0 && (
                <button
                  onClick={() => navigate("/loads/reports")}
                  className="w-1/2 h-auto flex flex-row justify-center items-center gap-1 px-6 py-2 rounded-md bg-gradient-to-l from-[#f0ac00] to-[#fbbf24] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                >
                  Show Reports
                  <span>( {reports.length} )</span>
                </button>
              )}
            </div>
          )}
          {loadData.status === "delivered" && (
            <div className="w-full h-auto flex flex-row justify-end items-center gap-1">
              {reports.length !== 0 && (
                <button
                  onClick={() => navigate("/loads/reports")}
                  className="w-1/2 h-auto flex flex-row justify-center items-center gap-1 px-6 py-2 rounded-md bg-gradient-to-l from-[#fbbf24] to-[#fbbf24] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                >
                  Show Reports
                  <span>( {reports.length} )</span>
                </button>
              )}
            </div>
          )}
        </>
      )}
      {showModalSubmitOffer && (
        <SubmitOffer
          loadData={loadData}
          setShowModalSubmitOffer={setShowModalSubmitOffer}
          setShowBtnsSubmitOffer={setShowBtnsSubmitOffer}
          setShowBtnsSubmitNewOffer={setShowBtnsSubmitNewOffer}
          setShowMessageBox={setShowMessageBox}
          setMessageBoxInfo={setMessageBoxInfo}
        />
      )}
      {showModalSubmitNewOffer && (
        <SubmitNewOffer
          loadData={loadData}
          setShowModalSubmitNewOffer={setShowModalSubmitNewOffer}
          setMessageBoxInfo={setMessageBoxInfo}
        />
      )}
      {showRejectModal && (
        <RejectLoad
          loadData={loadData}
          userData={userData}
          setShowRejectModal={setShowRejectModal}
          setReloadPage={setReloadPage}
        />
      )}
      {showModalSubmitReport && (
        <SubmitReport
          load_id={loadData.id}
          offer_load_id={messageBoxInfo[0].offer_load_id}
          setShowModalSubmitReport={setShowModalSubmitReport}
          fetchReports={fetchReports}
        />
      )}
    </div>
  );
};

export default LoadDetail;