import { useEffect } from "react";
import axiosConfig from "../config/axiosConfig";
import Cookies from "js-cookie";

const FetchUserId = ({ userId, setUserId }) => {
  useEffect(() => {
    if (userId === null) {
      const fetchData = async () => {
        const token = Cookies.get("token");
        const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
        const endpoint = process.env.REACT_APP_PERSONAL_INFO_URL;
        const axiosInstance = axiosConfig(baseURL, token);

        if (token) {
          try {
            const response = await axiosInstance.get(endpoint);
            setUserId(response.data.data.id);
          } catch (error) {
            console.error(error);
          }
        }
      };

      fetchData();
    }
  }, [userId, setUserId]);

  return null;
};

export default FetchUserId;
