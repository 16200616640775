import { useEffect, useState } from "react";
import DefaultField from "../../module/field/DefaultField";
import { Formik, Form, ErrorMessage } from "formik";
import SelectTruckType from "../../module/select/SelectTruckType";
import styles from "../../../styles/styles.module.css";
import axiosConfig from "../../../config/axiosConfig";
import AboutMessage from "../../module/AboutMessage";
import Cookies from "js-cookie";
import { Checkbox, CircularProgress } from "@mui/material";
import fetchTruckType from "../../../utils/fetchTruckType";
import Spinner from "../../module/Spinner";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ScreenProtector from "../../module/modal/ScreenProtector";
import AddDriver from "../../module/modal/AddDriver";
import RegistrationImage from "../../module/uploader/RegistrationImage";
import TruckImages from "../../module/uploader/TruckImages";
import MaskedInput from "react-maskedinput";
import InsuranceImage from "../../module/uploader/InsuranceImage";

const TruckInfo = ({ setActiveStep }) => {
  const navigate = useNavigate();
  const statusRegister = useSelector((state) => state.statusRegister);

  const [truckType, setTruckType] = useState([]);
  const [registrationImage, setRegistrationImage] = useState([]);
  const [insuranceImage, setInsuranceImage] = useState([]);
  const [truckFrontImages, setTruckFrontImages] = useState([]);
  const [truckSideRightImages, setTruckSideRightImages] = useState([]);
  const [truckSideLeftImages, setTruckSideLeftImages] = useState([]);
  const [truckBackImages, setTtruckBackImages] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [screenProtector, setScreenProtector] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dockHeight, setDockHeight] = useState("0");
  const [liftGate, setLiftGate] = useState("0");
  const [palletJack, setPalletJack] = useState("0");
  const [statusCompany, setStatusCompany] = useState("no");
  const [selectedTruckType, setSelectedTruckType] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_PERSONAL_INFO_URL;
      const axiosInstance = axiosConfig(baseURL, token);

      if (token) {
        try {
          const response = await axiosInstance.get(endpoint);
          // console.log(response.data.data.company);
          setStatusCompany(response.data.data.company);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    fetchTruckType({ setTruckType });
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="h-screen w-full flex flex-col justify-center items-center bg-[#F1F5F9]">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full h-auto flex flex-col justify-center items-center bg-[#F1F5F9] text-black font-normal py-8">
      <div className="w-11/12 h-auto flex flex-col justify-center items-start gap-10 bg-[#E2E8F0] rounded-md p-8">
        <div className="flex flex-row justify-center items-end gap-2">
          <h1 className="text-xl uppercase max-sm:text-sm">Register</h1>
          <p className="text-xl text-[#1976D2] uppercase max-sm:text-sm">
            Truck Info
          </p>
        </div>
        <Formik
          initialValues={{
            truck_name: "",
            truck_type: "",
            truck_type_other: "",
            ownership: "",
            vin_number: "",
            mc_number: "",
            year_vehicle_manufacture: "",
            mileage: "",
            max_pallets: "",
            max_weight: "",
            payload: "",
            clearance_fit: "",
            clearance_int: "",
            dock_height: "",
            lift_gate: "",
            pallet_jack: "",
            vehicle_length: "",
            wheel_width: "",
            vehicle_height: "",
            backdoor_width: "",
            backdoor_height: "",
            sidedoor_width: "",
            sidedoor_height: "",
            registration_image: "",

            insurance_company_name: "",
            insurance_policy_number: "",
            insurance_expiration_date: "",
            insurance_agent_contact_phone: "",

            insurance_image: "",

            truck_front_images: "",
            truck_side_right_images: "",
            truck_side_left_images: "",
            truck_back_images: "",
          }}
          validate={(values) => {
            const errors = {};
            if (!values.truck_type) {
              errors.truck_type = "Please select truck type";
            }

            if (selectedTruckType === "Other") {
              if (!values.truck_type_other) {
                errors.truck_type_other = "Please fill in this field";
              } else if (!/^[0-9]+$/.test(values.truck_type_other)) {
                errors.truck_type_other = "Should only contain numbers 0 - 9";
              }
            }
            if (!values.ownership) {
              errors.ownership = "Please select ownership";
            }
            if (!values.vin_number) {
              errors.vin_number = "Please enter vin number";
            } else if (values.vin_number.length !== 16) {
              errors.vin_number =
                "VIN number must be exactly 16 characters long";
            } else if (!/[A-Za-z0-9]{16}/.test(values.vin_number)) {
              errors.vin_number =
                "VIN number must contain only letters and numbers";
            }
            if (
              [
                "16'",
                "18'",
                "20'",
                "22'",
                "24'",
                "26'",
                "Hot Shot",
                "CDL STRAIGHT",
                "Dry van",
                "FLATBED",
                "TRACTOR / Power only",
                "Refrigerator truck",
              ].includes(selectedTruckType) &&
              !values.mc_number
            ) {
              errors.mc_number = "MC number is required for this truck type";
            } else if (values.mc_number && !/^[0-9]+$/.test(values.mc_number)) {
              errors.mc_number =
                "MC number or DOT number should only contain numbers 0 - 9";
            }

            if (!values.year_vehicle_manufacture) {
              errors.year_vehicle_manufacture =
                "Please enter year vehich manufacture";
            } else if (!/^[0-9]+$/.test(values.year_vehicle_manufacture)) {
              errors.year_vehicle_manufacture =
                "Should only contain numbers 0 - 9";
            }
            if (!values.mileage) {
              errors.mileage = "Please enter your Mileage";
            } else if (!/^[0-9]+$/.test(values.mileage)) {
              errors.mileage = "Mileage should only contain numbers 0 - 9";
            }
            if (!values.max_pallets) {
              errors.max_pallets = "Please enter maxPallets";
            } else if (!/^[0-9]+$/.test(values.max_pallets)) {
              errors.max_pallets = "Should only contain numbers 0 - 9";
            }
            if (!values.max_weight) {
              errors.max_weight = "Please enter max weight";
            } else if (!/^[0-9]+$/.test(values.max_weight)) {
              errors.max_weight = "Should only contain numbers 0 - 9";
            }
            if (!values.payload) {
              errors.payload = "Please enter payload";
            } else if (!/^[0-9]+$/.test(values.payload)) {
              errors.payload = "Should only contain numbers 0 - 9";
            }
            if (!values.vehicle_length) {
              errors.vehicle_length = "Please enter length";
            } else if (!/^[0-9]+$/.test(values.vehicle_length)) {
              errors.vehicle_length = "Should only contain numbers 0 - 9";
            }
            if (!values.wheel_width) {
              errors.wheel_width = "Please enter wheel wheel width";
            } else if (!/^[0-9]+$/.test(values.wheel_width)) {
              errors.wheel_width = "Should only contain numbers 0 - 9";
            }
            if (!values.vehicle_height) {
              errors.vehicle_height = "Please enter height";
            } else if (!/^[0-9]+$/.test(values.vehicle_height)) {
              errors.vehicle_height = "Should only contain numbers 0 - 9";
            }
            if (!values.backdoor_width) {
              errors.backdoor_width = "Please enter width";
            } else if (!/^[0-9]+$/.test(values.backdoor_width)) {
              errors.backdoor_width = "Should only contain numbers 0 - 9";
            }
            if (!values.backdoor_height) {
              errors.backdoor_height = "Please enter height";
            } else if (!/^[0-9]+$/.test(values.backdoor_height)) {
              errors.backdoor_height = "Should only contain numbers 0 - 9";
            }
            if (!values.sidedoor_width) {
              errors.sidedoor_width = "Please enter Width";
            } else if (!/^[0-9]+$/.test(values.sidedoor_width)) {
              errors.sidedoor_width = "Should only contain numbers 0 - 9";
            }
            if (!values.sidedoor_height) {
              errors.sidedoor_height = "Please enter height";
            } else if (!/^[0-9]+$/.test(values.sidedoor_height)) {
              errors.sidedoor_height = "Should only contain numbers 0 - 9";
            }

            if (!values.insurance_company_name) {
              errors.insurance_company_name =
                "Please enter insurance company name";
            }

            if (!values.insurance_expiration_date) {
              errors.insurance_expiration_date =
                "Please enter insurance expiration date";
            }

            if (!values.insurance_policy_number) {
              errors.insurance_policy_number =
                "Please enter insurance policy number";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              values.dock_height = dockHeight;
              values.lift_gate = liftGate;
              values.pallet_jack = palletJack;
              values.registration_image = registrationImage;
              values.insurance_image = insuranceImage;

              values.truck_front_images = truckFrontImages;
              values.truck_back_images = truckBackImages;
              values.truck_side_right_images = truckSideRightImages;
              values.truck_side_left_images = truckSideLeftImages;

              if (values.registration_image.length === 0) {
                AboutMessage({
                  status: "error",
                  message: "Please upload file registration image",
                });
                setSubmitting(false);
                return;
              }
              if (values.insurance_image.length === 0) {
                AboutMessage({
                  status: "error",
                  message: "Please upload file insurance image",
                });
                setSubmitting(false);
                return;
              }
              if (values.truck_front_images.length === 0) {
                AboutMessage({
                  status: "error",
                  message: "Please upload file truck front images",
                });
                setSubmitting(false);
                return;
              }

              if (values.truck_back_images.length === 0) {
                AboutMessage({
                  status: "error",
                  message: "Please upload file truck back images",
                });
                setSubmitting(false);
                return;
              }

              if (values.truck_side_right_images.length === 0) {
                AboutMessage({
                  status: "error",
                  message: "Please upload file truck side right imagess",
                });
                setSubmitting(false);
                return;
              }

              if (values.truck_side_left_images.length === 0) {
                AboutMessage({
                  status: "error",
                  message: "Please upload file truck side left images",
                });
                setSubmitting(false);
                return;
              }

              setBtnLoading(true);
              setScreenProtector(true);
              const token = Cookies.get("token");
              const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
              const endpoint = process.env.REACT_APP_TRUCK_INFO_STORE_URL;
              const axiosInstance = axiosConfig(baseURL, token);

              const config = {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              };

              const response = await axiosInstance.post(
                endpoint,
                values,
                config
              );

              if (response.status === 200) {
                if (statusRegister === "complete") {
                  navigate("/");
                } else {
                  setActiveStep("bank-info");
                }
              }
            } catch (error) {
              console.error(error);
              if (error.response) {
                const errors = error.response.data.message;

                AboutMessage({
                  status: "error",
                  message: errors,
                });
              }
            } finally {
              setBtnLoading(false);
              setScreenProtector(false);
            }
          }}
        >
          {({ setFieldValue }) => (
            <Form className="w-full h-auto flex flex-col gap-2 font-normal">
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="truck_name"
                minLength="0"
                maxLength="255"
                placeholder="Truck Name"
              />
              <SelectTruckType
                title="Truck Type"
                name="truck_type"
                data={truckType}
                onChange={({ selectedId, selectedTitle }) => {
                  setFieldValue("truck_type", selectedId);
                  setSelectedTruckType(selectedTitle);
                }}
              />
              <ErrorMessage
                name="truck_type"
                component="div"
                className="w-full h-auto text-sm text-red-600 font-normal max-sm:text-xs"
              />

              {selectedTruckType === "Other" && (
                <DefaultField
                  stylefield={styles.stylefield}
                  type="text"
                  name="truck_type_other"
                  minLength="0"
                  maxLength="2"
                  placeholder="Please type your truck size if different in feet"
                />
              )}

              <div className="flex flex-col justify-center items-start gap-2 py-2">
                <p className="cursor-default text-sm text-[#4b5563] max-sm:text-xs">
                  Type Of Ownership
                </p>
                <div className="flex flex-row justify-center items-center gap-6">
                  <div className="flex flex-row justify-start items-center gap-1">
                    <input
                      type="radio"
                      id="owner"
                      name="ownership"
                      value="Owner"
                      onChange={() => setFieldValue("ownership", "owner")}
                    />
                    <label
                      htmlFor="owner"
                      className="relative bottom-[1px] text-[#4b5563] cursor-default max-sm:text-xs"
                    >
                      Owner
                    </label>
                  </div>
                  <div className="flex flex-row justify-start items-center gap-1">
                    <input
                      type="radio"
                      id="leasing"
                      name="ownership"
                      value="Leasing"
                      onChange={() => setFieldValue("ownership", "leasing")}
                    />
                    <label
                      htmlFor="leasing"
                      className="relative bottom-[1px] text-[#4b5563] cursor-default max-sm:text-xs"
                    >
                      Leasing
                    </label>
                  </div>
                  <div className="flex flex-row justify-start items-center gap-1">
                    <input
                      type="radio"
                      id="rent"
                      name="ownership"
                      value="Rent"
                      onChange={() => setFieldValue("ownership", "rent")}
                    />
                    <label
                      htmlFor="rent"
                      className="relative bottom-[1px] text-[#4b5563] cursor-default max-sm:text-xs"
                    >
                      Rent
                    </label>
                  </div>
                </div>
                <ErrorMessage
                  name="ownership"
                  component="div"
                  className="w-full h-auto text-sm text-red-600 font-normal max-sm:text-xs"
                />
              </div>
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="vin_number"
                minLength="0"
                maxLength="255"
                placeholder="Vin Number"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="mc_number"
                minLength="0"
                maxLength="255"
                placeholder="MC Number or DOT Number (Optional)"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="year_vehicle_manufacture"
                minLength="0"
                maxLength="255"
                placeholder="Year of Vehicle Manufacture"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="mileage"
                minLength="0"
                maxLength="255"
                placeholder="Mileage"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="max_pallets"
                minLength="0"
                maxLength="255"
                placeholder="Max Pallets"
              />

              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="max_weight"
                minLength="0"
                maxLength="255"
                placeholder="Max Weight"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="payload"
                minLength="0"
                maxLength="255"
                placeholder="Payload"
              />

              <div className="flex flex-col justify-center items-start gap-2">
                <p className="cursor-default font-normal text-sm text-[#4b5563] max-sm:text-xs">
                  Clearnce
                </p>
                <DefaultField
                  stylefield={styles.stylefield}
                  type="text"
                  name="clearance_fit"
                  minLength="0"
                  maxLength="2"
                  placeholder="ft"
                />
                <DefaultField
                  stylefield={styles.stylefield}
                  type="text"
                  name="clearance_int"
                  minLength="0"
                  maxLength="2"
                  placeholder="in"
                />
              </div>

              <div className="flex flex-row justify-between items-center py-2">
                <div className="flex flex-row justify-start items-center gap-1">
                  <Checkbox
                    id="dockHeightCheckbox"
                    value={dockHeight}
                    onChange={() =>
                      setDockHeight(dockHeight === "0" ? "1" : "0")
                    }
                    size="small"
                    sx={{ padding: "0.1rem" }}
                  />
                  <label
                    htmlFor="dockHeightCheckbox"
                    className="relative bottom-[1px] text-[#4b5563] cursor-pointer max-sm:text-xs"
                  >
                    Dock Height
                  </label>
                </div>
                <div className="flex flex-row justify-start items-center gap-1">
                  <Checkbox
                    id="liftGateCheckbox"
                    value={liftGate}
                    onChange={() => setLiftGate(liftGate === "0" ? "1" : "0")}
                    sx={{ padding: "0.1rem" }}
                    size="small"
                  />
                  <label
                    htmlFor="liftGateCheckbox"
                    className="relative bottom-[1px] text-[#4b5563] cursor-default max-sm:text-xs"
                  >
                    Lift Gate
                  </label>
                </div>
                <div className="flex flex-row justify-start items-center gap-1">
                  <Checkbox
                    id="palletJackCheckbox"
                    value={palletJack}
                    onChange={() =>
                      setPalletJack(palletJack === "0" ? "1" : "0")
                    }
                    sx={{ padding: "0.1rem" }}
                    size="small"
                  />
                  <label
                    htmlFor="palletJackCheckbox"
                    className="relative bottom-[1px] text-[#4b5563] cursor-default max-sm:text-xs"
                  >
                    Pallet Jack
                  </label>
                </div>
              </div>

              <div className="flex flex-col justify-center items-start gap-2">
                <p className="cursor-default text-sm text-[#4b5563] max-sm:text-xs">
                  Dimensions (of Cargo space interior)
                </p>
                <div className="w-full h-auto flex flex-col justify-center items-start gap-2">
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="vehicle_length"
                    minLength="0"
                    maxLength="255"
                    placeholder="Length ( in )"
                  />
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="wheel_width"
                    minLength="0"
                    maxLength="255"
                    placeholder="Wheel to wheel Width ( in )"
                  />
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="vehicle_height"
                    minLength="0"
                    maxLength="255"
                    placeholder="Height ( in )"
                  />
                </div>
              </div>
              <div className="flex flex-col justify-center items-start gap-2">
                <p className="cursor-default font-normal text-sm text-[#4b5563] max-sm:text-xs">
                  Back Door
                </p>
                <DefaultField
                  stylefield={styles.stylefield}
                  type="text"
                  name="backdoor_width"
                  minLength="0"
                  maxLength="255"
                  placeholder="Width ( in )"
                />
                <DefaultField
                  stylefield={styles.stylefield}
                  type="text"
                  name="backdoor_height"
                  minLength="0"
                  maxLength="255"
                  placeholder="Height ( in )"
                />
              </div>
              <div className="flex flex-col justify-center items-start gap-2">
                <p className="cursor-default font-normal text-sm text-[#4b5563] max-sm:text-xs">
                  Side Door
                </p>
                <DefaultField
                  stylefield={styles.stylefield}
                  type="text"
                  name="sidedoor_width"
                  minLength="0"
                  maxLength="255"
                  placeholder="Width ( in )"
                />
                <DefaultField
                  stylefield={styles.stylefield}
                  type="text"
                  name="sidedoor_height"
                  minLength="0"
                  maxLength="255"
                  placeholder="Height ( in )"
                />
              </div>
              <RegistrationImage
                title={"Registration Image"}
                selectedFiles={registrationImage}
                setSelectedFiles={setRegistrationImage}
              />
              <p className=" text-[#4b5563] cursor-default max-sm:text-xs">
                Insurance Info
              </p>
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="insurance_company_name"
                minLength="0"
                maxLength="255"
                placeholder="Insurance Company Name"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="insurance_policy_number"
                minLength="0"
                maxLength="255"
                placeholder="Insurance Policy Number"
              />
              <MaskedInput
                id="datefield"
                className={styles.stylefield}
                mask="11/11/1111"
                placeholder="Insurance Expiration mm/dd/yyyy"
                name="insurance_expiration_date"
                value="insurance_expiration_date"
                onChange={(e) => {
                  const result = e.target.value.replace(/\D/g, "-");
                  setFieldValue("insurance_expiration_date", result);
                }}
              />
              <ErrorMessage
                name="insurance_expiration_date"
                component="div"
                className="w-full h-auto text-sm text-red-600 font-normal max-sm:text-xs"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="insurance_agent_contact_phone"
                minLength="0"
                maxLength="255"
                placeholder="Insurance Agent Contact Phone"
              />
              <InsuranceImage
                title={"Insurance Image"}
                selectedFiles={insuranceImage}
                setSelectedFiles={setInsuranceImage}
              />
              <p className=" text-[#4b5563] cursor-default max-sm:text-xs">
                Truck Images
              </p>
              <TruckImages
                title={"Truck Front Image"}
                selectedFiles={truckFrontImages}
                setSelectedFiles={setTruckFrontImages}
              />
              <TruckImages
                title={"Truck Back Image"}
                selectedFiles={truckBackImages}
                setSelectedFiles={setTtruckBackImages}
              />
              <TruckImages
                title={"Truck Right Image"}
                selectedFiles={truckSideRightImages}
                setSelectedFiles={setTruckSideRightImages}
              />
              <TruckImages
                title={"Truck Left Image"}
                selectedFiles={truckSideLeftImages}
                setSelectedFiles={setTruckSideLeftImages}
              />
              {statusCompany === "yes" && (
                <div className="w-full h-auto flex flex-row justify-start items-center">
                  <button
                    type="button"
                    onClick={() => setShowModal(true)}
                    className="w-full h-auto flex flex-row justify-center items-center gap-1 px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm "
                  >
                    Add Driver
                  </button>
                </div>
              )}
              <div className="w-full h-auto flex flex-row justify-end items-center gap-1">
                <button
                  type="submit"
                  className="w-full h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                >
                  {btnLoading ? (
                    <CircularProgress size={16} sx={{ color: "black" }} />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {screenProtector && <ScreenProtector />}
      {showModal && <AddDriver setShowModal={setShowModal} />}
    </div>
  );
};

export default TruckInfo;
