import { useState } from "react";
import styles from "../../../styles/styles.module.css";
import DefaultField from "../field/DefaultField";
import { Formik, Form, ErrorMessage } from "formik";
import SelectReportType from "../select/SelectReportType";
import ImageUploader from "../ImageUploader";
import AboutMessage from "../AboutMessage";
import { useSelector } from "react-redux";
import axiosConfig from "../../../config/axiosConfig";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";
import { PiSignatureLight } from "react-icons/pi";
import ModalSignatureCanvas from "./ModalSignatureCanvas";
import { BsCheck } from "react-icons/bs";

const SubmitReport = ({
  load_id,
  offer_load_id,
  setShowModalSubmitReport,
  fetchReports,
}) => {
  const darkMode = useSelector((state) => state.darkMode);
  const statusUser = useSelector((state) => state.statusUser);

  const [signature, setSignature] = useState("");
  const [description, setDescription] = useState("");
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState("");
  const [showModalSignatureCanvas, setShowModalSignatureCanvas] =
    useState(false);
  const dataSelectReportType = ["pickup", "carrying", "dropoff"];

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60">
      <div id="modalstylesubmitreport" className="modal font-normal">
        <div id="modalcontentstylesubmitreport" className="modal-content">
          <div className="w-full h-auto flex flex-col justify-between items-start gap-2">
            <p className="text-black text-base font-normal pb-2">Send Report</p>
            <Formik
              initialValues={{
                load_id: "",
                offer_load_id: "",
                location: "",
                shipper_name: "",
                bol_number: "",
                piece: "",
                weight: "",
                phone: "",
                description: "",
                name: "",
                report_type: "",
                signature: "",
                images: "",
              }}
              validate={(values) => {
                const errors = {};

                if (!values.report_type) {
                  errors.report_type = "Please enter your report type";
                }
                if (reportType === "" || reportType === "pickup") {
                  if (!values.shipper_name) {
                    errors.shipper_name = "Please enter your shipper name";
                  } else if (!/^[A-Za-z]+$/i.test(values.shipper_name)) {
                    errors.shipper_name =
                      "should only contain Latin letters a - z - A - Z";
                  }
                  if (!values.bol_number) {
                    errors.bol_number = "Please enter your BOL number";
                  } else if (!/^[0-9]+$/i.test(values.bol_number)) {
                    errors.bol_number =
                      "should only contain Latin letters 0 - 9";
                  }
                  if (!values.weight) {
                    errors.weight = "Please enter your weight";
                  } else if (!/^[0-9]+$/i.test(values.weight)) {
                    errors.weight = "should only contain Latin letters 0 - 9";
                  }
                  values.name = "";
                }

                if (reportType === "dropoff") {
                  if (!values.name) {
                    errors.name = "Please enter your reciever name";
                  } else if (!/^[A-Za-z]+$/i.test(values.name)) {
                    errors.name =
                      "should only contain Latin letters a - z - A - Z";
                  }
                  values.bol_number = "";
                  values.piece = "";
                  values.shipper_name = "";
                  values.weight = "";
                }
                if (reportType === "carrying") {
                  values.bol_number = "";
                  values.piece = "";
                  values.shipper_name = "";
                  values.weight = "";
                  values.name = "";
                }
                return errors;
              }}
              onSubmit={async (values) => {
                values.load_id = load_id;
                values.offer_load_id = offer_load_id;
                values.report_type = reportType;
                values.signature = signature;
                values.description = description;
                values.images = images;

                if (values.description === "") {
                  AboutMessage({
                    status: "error",
                    message: "Please enter description",
                    darkMode,
                  });
                  return;
                }

                if (images.length === 0) {
                  AboutMessage({
                    status: "error",
                    message: "Please upload file load image",
                    darkMode,
                  });
                  return;
                }

                if (statusUser === "offline") {
                  AboutMessage({
                    status: "error",
                    message: "You are offline",
                    darkMode,
                  });
                  return;
                }

                if (
                  reportType === "" ||
                  reportType === "pickup" ||
                  reportType === "dropoff"
                ) {
                  if (signature === "") {
                    AboutMessage({
                      status: "error",
                      message: "Please enter your signature",
                      darkMode,
                    });
                    return;
                  }
                }

                const locationCookie = Cookies.get("location");
                const locationParse = JSON.parse(locationCookie);

                const lat = locationParse.lat;
                const lng = locationParse.lng;
                const locationString = `${lat},${lng}`;
                values.location = locationString;

                try {
                  setLoading(true);
                  const token = Cookies.get("token");
                  const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
                  const endpoint = process.env.REACT_APP_SUBMIT_REPORT;
                  const axiosInstance = axiosConfig(baseURL, token);
                  const config = {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  };
                  const response = await axiosInstance.post(
                    endpoint,
                    values,
                    config
                  );
                  if (response.status === 200) {
                    setShowModalSubmitReport(false);
                    fetchReports();
                    AboutMessage({
                      status: "success",
                      message: response.data.message,
                      darkMode,
                    });
                  }
                } catch (error) {
                  console.log(error);
                  if (error.response) {
                    const errorsMessage = error.response.data.message;
                    AboutMessage({
                      status: "error",
                      message: errorsMessage,
                      darkMode,
                    });
                  } else {
                    console.error(error.message);
                  }
                } finally {
                  setLoading(false);
                }
              }}
            >
              {({ setFieldValue }) => (
                <Form className="w-full h-auto flex flex-col gap-2">
                  <SelectReportType
                    title="Report Type"
                    name="report_type"
                    data={dataSelectReportType}
                    onChange={(selectedValue) => {
                      setFieldValue("report_type", selectedValue);
                      setReportType(selectedValue);
                    }}
                  />
                  <ErrorMessage
                    name="report_type"
                    component="div"
                    className="w-full h-auto text-sm text-red-600 font-normal max-sm:text-xs"
                  />
                  {(reportType === "" || reportType === "pickup") && (
                    <DefaultField
                      stylefield={styles.stylefield}
                      type="text"
                      name="shipper_name"
                      minLength="0"
                      maxLength="255"
                      placeholder="Shipper Name"
                    />
                  )}
                  {(reportType === "" || reportType === "pickup") && (
                    <DefaultField
                      stylefield={styles.stylefield}
                      type="text"
                      name="bol_number"
                      minLength="0"
                      maxLength="255"
                      placeholder="BOL Number"
                    />
                  )}

                  {(reportType === "" || reportType === "pickup") && (
                    <DefaultField
                      stylefield={styles.stylefield}
                      type="text"
                      name="piece"
                      minLength="0"
                      maxLength="255"
                      placeholder="Piece"
                    />
                  )}

                  {(reportType === "" || reportType === "pickup") && (
                    <DefaultField
                      stylefield={styles.stylefield}
                      type="text"
                      name="weight"
                      minLength="0"
                      maxLength="255"
                      placeholder="Weight"
                    />
                  )}

                  {reportType === "dropoff" && (
                    <DefaultField
                      stylefield={styles.stylefield}
                      type="text"
                      name="name"
                      minLength="0"
                      maxLength="255"
                      placeholder="Reciever Name"
                    />
                  )}

                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="phone"
                    minLength="0"
                    maxLength="255"
                    placeholder="Phone (optional)"
                  />
                  <textarea
                    className={styles.stylefield}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                  ></textarea>
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="w-full h-auto text-sm text-red-600 font-normal max-sm:text-xs"
                  />
                  <ImageUploader
                    title="Load Image & Truck Image & etc"
                    selectedFiles={images}
                    setSelectedFiles={setImages}
                  />

                  {(reportType === "" ||
                    reportType === "pickup" ||
                    reportType === "dropoff") && (
                    <div className="flex flex-col justify-center items-start gap-4 bg-[#F1F5F9] font-normal rounded-md p-4">
                      <div className="flex flex-row justify-center items-center gap-1 text-black cursor-default max-sm:text-xs max-md:text-sm">
                        <p>Signature</p>
                        <div>
                          {signature !== "" && (
                            <BsCheck className="text-green-700 text-xl" />
                          )}
                        </div>
                      </div>
                      <button
                        type="button"
                        className="w-full h-auto flex flex-row justify-center items-center gap-2 px-6 py-2 !rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] bg-[#E2E8F0] text-white text-base !font-normal shadow-md max-sm:text-xs max-md:text-sm"
                        onClick={() => setShowModalSignatureCanvas(true)}
                      >
                        <PiSignatureLight className="text-xl" />
                      </button>
                    </div>
                  )}

                  <div className="w-full h-auto flex flex-row justify-center items-center gap-1 pt-10">
                    <button
                      className="w-1/2 h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                      type="submit"
                    >
                      {loading ? (
                        <CircularProgress size={16} sx={{ color: "black" }} />
                      ) : (
                        "Submit"
                      )}
                    </button>
                    <button
                      className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                      type="button"
                      onClick={() => setShowModalSubmitReport(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {showModalSignatureCanvas && (
        <ModalSignatureCanvas
          setSignature={setSignature}
          setShowModalSignatureCanvas={setShowModalSignatureCanvas}
        />
      )}
    </div>
  );
};

export default SubmitReport;
