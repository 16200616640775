import { IoIosClose } from "react-icons/io";

const MoreInfoDriverLicence = ({ data, setShowModal }) => {
  const driverLicenceInfo = [
    { label: "Driver licence Number", value: data.driver_licence },
    { label: "Expiration", value: data.expiration },
    { label: "Create at", value: data.created_at },
  ];
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60 z-10">
      <div id="modalstylemoreinfo" className="modal font-normal">
        <div id="modalcontentstylemoreinfo" className="modal-content">
          <button
            type="button"
            className="close"
            onClick={() => setShowModal(false)}
          >
            <IoIosClose />
          </button>
          <div className="w-full h-auto flex flex-col justify-center items-start gap-3">
          <p className="text-black text-base font-normal pb-2">More information</p>
            {driverLicenceInfo.map((info, index) => (
              <div
                key={index}
                className="flex flex-col justify-center items-start cursor-default max-md:text-sm"
              >
                <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                  {info.label}
                </p>
                <p>{info.value}</p>
              </div>
            ))}
            <div className="w-full h-auto flex flex-row justify-start items-center gap-1 flex-wrap pt-2">
              {data.galleries.map((index) => {
                return (
                  <img
                    className="h-20 w-20 rounded-md"
                    key={index.id}
                    src={`${process.env.REACT_APP_REGISTER_BASE_URL}/${index?.image}`}
                    alt={index.name}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreInfoDriverLicence;
