import Cookies from "js-cookie";
import axiosConfig from "../../../config/axiosConfig";
import AboutMessage from "../AboutMessage";

const handlerReject = async (userData, loadData, setShowRejectModal,setReloadPage) => {
  try {
    const token = Cookies.get("token");
    const baseURL = process.env.REACT_APP_LOADAL_BASE_URL;
    const endpoint = process.env.REACT_APP_REJECT_LOAD_URL;
    const axiosInstance = axiosConfig(baseURL, token);

    const values = {
      user_id: userData.id,
      load_id: loadData.id,
    };

    const response = await axiosInstance.post(endpoint, values);

    if (response.status === 200) {
      AboutMessage({ status: "success", message: response.data.message });
      setShowRejectModal(false);
      setReloadPage(true);
    }
  } catch (error) {
    if (error.response) {
      AboutMessage({
        status: "error",
        message: error.response.data.message,
      });
    } else {
      console.log(error.message);
    }
  }
};

const RejectLoad = ({ userData, loadData, setShowRejectModal,setReloadPage }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60 z-10">
      <div className="modal font-normal">
        <div className="modal-content">
          <p className="text-black">Are you sure?</p>
          <div className="w-full h-auto flex flex-row justify-center items-center gap-1">
            <button
              type="button"
              className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
              onClick={() => handlerReject(userData, loadData, setShowRejectModal, setReloadPage)}
            >
              Yes reject it!
            </button>
            <button
              type="button"
              onClick={() => setShowRejectModal(false)}
              className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-red-600 to-red-500 text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectLoad;

