import { useEffect } from "react";
import Cookies from "js-cookie";
import axiosConfig from "../config/axiosConfig";

const useFirebaseConfigFetcher = () => {
  useEffect(() => {
    const firebaseConfig = Cookies.get("firebaseConfig");
    if (firebaseConfig === undefined) {
      const fetchConfig = async () => {
        const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
        const endpoint = process.env.REACT_APP_GET_FIREBASE_CONFIG;
        const axiosInstance = axiosConfig(baseURL);
        try {
          const response = await axiosInstance.get(endpoint);
          const firebaseConfig = JSON.stringify(response.data.data);
          Cookies.set("firebaseConfig", firebaseConfig);
        } catch (error) {
          console.error(error);
        }
      };
  
      fetchConfig();
    }
  }, []);
};

export default useFirebaseConfigFetcher;
