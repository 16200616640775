import { GoInbox } from "react-icons/go";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import TruckCard from "../module/card/TruckCard";
import { v4 as uuidv4 } from "uuid";

const ListTruckCards = ({ data, checked, loading }) => {
  const darkMode = useSelector((state) => state.darkMode);

  if (checked || loading) {
    return (
      <div
        className={`fixed h-screen w-full flex flex-col justify-center items-center pb-[11.5rem] ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <PulseLoader color="#fbbf24" />
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-row justify-center items-center flex-wrap gap-3 px-4 font-normal ">
      {data.length === 0 ? (
        <div
          className={`w-full h-screen flex flex-row justify-center items-start ${
            darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
          }`}
        >
          <div
            className={`w-full h-auto flex flex-col justify-center items-center gap-2 rounded-md p-4 ${
              darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
            }`}
          >
            <GoInbox className="text-3xl" />
            <p className="cursor-default text-base max-md:text-sm max-sm:text-xs">
              You have no Truck
            </p>
          </div>
        </div>
      ) : (
        data.all_trucks.map((index) => (
          <TruckCard key={uuidv4()} darkMode={darkMode} {...index} />
        ))
      )}
    </div>
  );
};

export default ListTruckCards;
