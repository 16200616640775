import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { setDarkMode } from "../../redux_toolkit/features/darkModeSlice";
import { useNavigate } from "react-router-dom";
import { BiSupport } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
import { TbLogout2 } from "react-icons/tb";
import { TbReportSearch, TbBuildingBank } from "react-icons/tb";
import { MdOutlineLightMode, MdOutlineDarkMode } from "react-icons/md";
import { FaAngleRight } from "react-icons/fa6";
import { PiUserCircleThin } from "react-icons/pi";
import { FaTruckFast } from "react-icons/fa6";
import AboutMessage from "../module/AboutMessage";
import { PulseLoader } from "react-spinners";

const ProfileDetails = ({ data, checked, loading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode);

  const toggleDarkMode = (e) => {
    dispatch(setDarkMode(e.target.checked));
    localStorage.setItem("darkMode", e.target.checked);
  };

  const logout = () => {
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    navigate("/login");
  };

  if (checked || loading) {
    return (
      <div
        className={`h-[81vh] w-full flex flex-col justify-center items-center ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <PulseLoader color="#fbbf24" />
      </div>
    );
  }

  return (
    <div className="w-full h-full px-4 flex flex-col justify-start items-center">
      <div
        className={`w-full h-full flex flex-col justify-start items-start gap-10 font-normal rounded-md cursor-default text-sm p-6 ${
          darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
        }`}
      >
        <div className="w-full h-auto flex flex-row justify-start items-center gap-2">
          <PiUserCircleThin className="text-6xl" />
          <div>
            <div className="flex flex-row justify-start items-center gap-1">
              <span>{data.first_name}</span>
              <span>{data.last_name}</span>
            </div>

            <div className="w-full h-auto flex flex-row justify-start items-center flex-wrap">
              <span className="break-all">{data.email}</span>
            </div>
          </div>
        </div>

        <div className="w-full h-full flex flex-col justify-between items-start">
          <div className="w-full flex flex-col gap-4">
            <div
              className="w-full h-auto flex flex-row justify-between items-center cursor-pointer"
              onClick={() => navigate("/profile/account")}
            >
              <div className="flex flex-row justify-center items-center gap-2">
                <FiUser className="text-xl" />
                User account information
              </div>
              <FaAngleRight className="text-sm" />
            </div>

            <div
              className="w-full h-auto flex flex-row justify-between items-center gap-2 cursor-pointer"
              onClick={() => navigate("/profile/bank")}
            >
              <div className="flex flex-row justify-center items-center gap-2">
                <TbBuildingBank className="text-xl" />
                Banks information
              </div>
              <FaAngleRight className="text-sm" />
            </div>
            <div
              className="w-full h-auto flex flex-row justify-between items-center gap-2 cursor-pointer"
              onClick={() => navigate("/truck")}
            >
              <div className="flex flex-row justify-center items-center gap-2">
                <FaTruckFast className="text-xl" />
                Trucks information
              </div>
              <FaAngleRight className="text-sm" />
            </div>
            <div
              className="w-full h-auto flex flex-row justify-between items-center gap-2"
              onClick={() => navigate("/profile/financialreport")}
            >
              <div className="flex flex-row justify-center items-center gap-2">
                <TbReportSearch className="text-xl" />
                Financial report
              </div>
              <FaAngleRight className="text-sm" />
            </div>
            <div
              className="w-full h-auto flex flex-row justify-between items-center gap-2"
              onClick={() =>
                AboutMessage({
                  status: "info",
                  message: "This section will be activated soon",
                })
              }
            >
              <div className="flex flex-row justify-center items-center gap-2">
                <BiSupport className="text-xl" />
                Support
              </div>
              <FaAngleRight className="text-sm" />
            </div>
            <div className="w-full h-auto flex flex-row justify-between items-center gap-2">
              <div className="flex flex-row justify-center items-center gap-1">
                <span className="text-xl">
                  {darkMode ? <MdOutlineDarkMode /> : <MdOutlineLightMode />}
                </span>
                <p>Dark theme</p>
              </div>
              <div>
                <Switch
                  className="relative top-[2px]"
                  checked={darkMode}
                  onChange={toggleDarkMode}
                  inputProps={{ "aria-label": "controlled" }}
                  size="small"
                />
              </div>
            </div>
            <button
              type="submit"
              className="w-auto h-auto flex flex-row justify-start items-center gap-2 text-red-600"
              onClick={logout}
            >
              <TbLogout2 className="text-xl" />
              Logout
            </button>
          </div>

          <div className="w-full h-auto flex flex-row justify-center items-center">
            <div className="flex flex-row justify-start items-center gap-2 text-xs">
              <p>Version</p>
              <p>1.19</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
