import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

const statusRegisterSlice = createSlice({
  name: "statusRegister",
  initialState,
  reducers: {
   setStatusRegister: (state, action) => {
      return action.payload;
    },
  },
});

export const { setStatusRegister } = statusRegisterSlice.actions;
export default statusRegisterSlice.reducer;
