import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/customstepper.css";
import "./styles/modal.css";
import "./styles/aboutmessage.css";
import "./styles/modalsubmitreport.css";
import "./styles/modalverifyemail.css";
import "./styles/modalmoreinfo.css";
import "./styles/modalsignaturecanvas.css";
import App from "./App";
// import { BrowserRouter as Router } from "react-router-dom";
import { HashRouter as Router } from "react-router-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <App />
  </Router>
);

serviceWorkerRegistration.register();
