import { Formik, Form, ErrorMessage } from "formik";
import DefaultField from "../field/DefaultField";
import styles from "../../../styles/styles.module.css";
import { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import ImageUploader from "../ImageUploader";
import MaskedInput from "react-maskedinput";
import ScreenProtector from "./ScreenProtector";
import axiosConfig from "../../../config/axiosConfig";
import Cookies from "js-cookie";
import { IoIosClose } from "react-icons/io";
import AboutMessage from "../AboutMessage";
import SelectCountryCode from "../select/SelectCountryCode";
import fetchCountryCode from "../../../utils/fetchCountryCode";

const AddDriver = ({ setShowModal }) => {
  const [loading, setLoading] = useState(false);
  const [screenProtector, setScreenProtector] = useState(false);
  const [driverLicenceImage, setDriverLicenceImage] = useState([]);
  const [countryCode, setCountryCode] = useState([]);

  useEffect(() => {
    fetchCountryCode({ setCountryCode });
  }, []);

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60">
      <div id="modalstylesubmitreport" className="modal font-normal">
        <div id="modalcontentstylesubmitreport" className="modal-content">
          <div className="w-full h-auto flex flex-col justify-between items-start gap-2">
            <div className="w-full flex flex-row justify-between items-center pb-2">
              <p className="text-black text-base font-normal">Add Driver</p>
              <button onClick={() => setShowModal(false)}>
                <IoIosClose className="text-[1.5rem]" />
              </button>
            </div>

            <Formik
              initialValues={{
                first_name: "",
                middle_name: "",
                last_name: "",
                email: "",
                country_code: "",
                phone: "",
                backup_number: "",
                driver_licence_image: "",
                driver_licence: "",
                expiration: "",
              }}
              validate={(values) => {
                const errors = {};

                if (!values.first_name) {
                  errors.first_name = "Please enter first name";
                } else if (!/^[A-Za-z\s]+$/.test(values.first_name)) {
                  errors.first_name =
                    "Should only contain Latin letters a - z - A - Z";
                }
                if (
                  values.middle_name &&
                  !/^[A-Za-z\s]+$/.test(values.middle_name)
                ) {
                  errors.middle_name =
                    "Should only contain Latin letters a - z - A - Z";
                }

                if (!values.last_name) {
                  errors.last_name = "Please enter last name";
                } else if (!/^[A-Za-z\s]+$/.test(values.last_name)) {
                  errors.last_name =
                    "Should only contain Latin letters a - z - A - Z";
                }
                if (!values.country_code) {
                  errors.country_code = "Please select your country code";
                }

                if (!values.phone) {
                  errors.phone = "Please enter phone number";
                } else if (!/^[0-9]+$/.test(values.phone)) {
                  errors.phone = "Should only contain numbers 0 - 9";
                }

                if (!values.email) {
                  errors.email = "Please enter your email";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "The email address is invalid";
                }

                if (!values.backup_number) {
                  errors.backup_number = "Please enter emergency phone number";
                } else if (!/^[0-9]+$/.test(values.backup_number)) {
                  errors.backup_number = "Should only contain numbers 0 - 9";
                }

                if (!values.driver_licence) {
                  errors.driver_licence = "Please enter driver licence number";
                } else if (!/^[A-Za-z0-9]+$/.test(values.driver_licence)) {
                  errors.driver_licence =
                    "Driver licence number must contain only letters and numbers";
                }
                if (!values.expiration) {
                  errors.expiration = "Please enter expiration";
                }

                //  if (!values.driver_licence_image) {
                //    errors.driver_licence_image =
                //      "Please upload driver licence image";
                //  }

                return errors;
              }}
              onSubmit={async (values) => {
                try {
                  values.driver_licence_image = driverLicenceImage;

                  if (values.driver_licence_image.length === 0) {
                    AboutMessage({
                      status: "error",
                      message: "Please upload file driver licence image",
                    });
                    return;
                  }

                  setLoading(true);
                  setScreenProtector(true);
                  const token = Cookies.get("token");

                  // const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
                  const baseURL = "https://admin.888shipping.online/api/v1";
                  // const endpoint = process.env.REACT_APP_DRIVER_LINCENCE_STORE_URL;
                  const endpoint = "/register/driver-store";
                  const axiosInstance = axiosConfig(baseURL, token);

                  const config = {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  };

                  const response = await axiosInstance.post(
                    endpoint,
                    values,
                    config
                  );

                  if (response.status === 200) {
                    setShowModal(false);
                  }
                } catch (error) {
                  console.log(error.response.data.message);
                  if (error.response) {
                    const errors = error.response.data.message;

                    console.log(error.response.data);
                    AboutMessage({
                      status: "error",
                      message: errors,
                    });
                  }
                } finally {
                  setLoading(false);
                  setScreenProtector(false);
                }
              }}
            >
              {({ setFieldValue, values }) => (
                <Form className="w-full h-auto flex flex-col gap-2">
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="first_name"
                    minLength="0"
                    maxLength="255"
                    placeholder="First Name"
                  />

                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="middle_name"
                    minLength="0"
                    maxLength="255"
                    placeholder="Middle Name"
                  />

                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="last_name"
                    minLength="0"
                    maxLength="255"
                    placeholder="Last Name"
                  />

                  <SelectCountryCode
                    //title="Country Code"
                    name="country_code"
                    data={countryCode}
                    onChange={(selectedValue) =>
                      setFieldValue("country_code", selectedValue)
                    }
                  />
                  <ErrorMessage
                    name="country_code"
                    component="div"
                    className="w-full h-auto text-sm text-red-600 font-normal max-sm:text-xs"
                  />
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="phone"
                    minLength="0"
                    maxLength="255"
                    placeholder="Phone Number"
                  />

                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="backup_number"
                    minLength="0"
                    maxLength="255"
                    placeholder="Emergency Phone Number"
                  />

                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="email"
                    minLength="0"
                    maxLength="255"
                    placeholder="Email"
                  />

                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="driver_licence"
                    minLength="0"
                    maxLength="255"
                    placeholder="Driver Licence Number"
                  />

                  <MaskedInput
                    id="datefield"
                    className={styles.stylefield}
                    mask="11/11/1111"
                    placeholder="Expiration mm/dd/yyyy"
                    name="expiration"
                    value="expiration"
                    onChange={(e) => {
                      const result = e.target.value.replace(/\D/g, "-");
                      setFieldValue("expiration", result);
                    }}
                  />
                  <ErrorMessage
                    name="expiration"
                    component="div"
                    className="w-full h-auto text-sm text-red-600 font-normal max-sm:text-xs"
                  />

                  <ImageUploader
                    title={"Driver Licence Image"}
                    selectedFiles={driverLicenceImage}
                    setSelectedFiles={setDriverLicenceImage}
                  />

                  <ErrorMessage
                    name="driver_licence_image"
                    component="div"
                    className="w-full h-auto text-sm text-red-600 font-normal max-sm:text-xs"
                  />

                  <div className="w-full h-auto flex flex-col justify-center items-center gap-1">
                    <button
                      className="w-full h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                      type="submit"
                    >
                      {loading ? (
                        <CircularProgress size={16} sx={{ color: "black" }} />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      {screenProtector && <ScreenProtector />}
    </div>
  );
};

export default AddDriver;
