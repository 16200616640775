import { createSlice } from "@reduxjs/toolkit";

const initialState = [];
 
const messageListSlice = createSlice({
  name: "messageList",
  initialState,
  reducers: {
    setMessageList: (state, action) => {
      return action.payload;
    },
  },
});

export const { setMessageList } = messageListSlice.actions;
export default messageListSlice.reducer;
