import { HiLocationMarker } from "react-icons/hi";
import { BsCurrencyDollar } from "react-icons/bs";
import { format } from "date-fns";

const LoadDetailInfo = ({ loadData }) => {
  const status = loadData.status;
  const formattedStatus =
    status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

    const formatDateCreate = (dateString) => {
      const formattedDate = format(new Date(dateString), "dd/MM/yyyy");
      return formattedDate;
    };

  return (
    <div className="flex flex-col justify-start items-start gap-2 font-normal">
      <p className="cursor-default flex flex-row justify-start items-center gap-1 text-[#a2a6ad] max-md:text-xs">
        Posted on
        <span>{formatDateCreate(loadData.load_date)}</span>{" "}
      </p>
      <div className="w-full h-auto flex flex-col justify-between items-start font-normal max-sm:gap-1">
        <div className="flex flex-row justify-between items-center gap-2">
          <HiLocationMarker className="text-[#a2a6ad] text-lg" />
          <p className="text-base cursor-default max-md:text-sm">
            {loadData.load_pick_up}
          </p>
        </div>
        <div className="flex flex-row justify-start items-center gap-2">
          <HiLocationMarker className="text-[#a2a6ad] text-lg" />
          <p className="text-base cursor-default max-md:text-sm">
            {loadData.load_deliver_to}
          </p>
        </div>
      </div>
      <div className="w-full h-auto flex flex-col justify-start items-start gap-3 font-normal rounded-md p-2">
        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Status load
          </p>
          <p
            className={`${
              loadData.status === "reject" || loadData.status === "missed"
                ? "text-red-600"
                : loadData.status === "accept" || loadData.status === "delivered" || loadData.status === "transit"
                ? "text-green-600"
                : "text-yellow-500"
            }`}
          >
            {formattedStatus}
          </p>
        </div>

        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Turck type
          </p>
          <p>{loadData.user_truck_size}</p>
        </div>
        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Weight
          </p>
          <p>{loadData.load_weight}</p>
        </div>
        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Load miles
          </p>
          <p>{loadData.load_miles}</p>
        </div>

        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Location distance
          </p>
          <p>{loadData.location_distance}</p>
        </div>

        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Price
          </p>
          <p className="flex flex-row justify-start items-center">
            <BsCurrencyDollar />
            {loadData.price}
          </p>
        </div>

        {/* <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            load Notes
          </p>
          <p>{loadData.load_notes}</p>
        </div> */}
      </div>
    </div>
  );
};

export default LoadDetailInfo;
