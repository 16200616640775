import { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { IoIosClose, IoMdTime } from "react-icons/io";
import axiosConfig from "../../../config/axiosConfig";
import AboutMessage from "../AboutMessage";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";

const VerifyPhoneNumber = ({ otp, setOtp, setShowModal, setActiveStep }) => {
  const [timer, setTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let interval;
    if (timerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setTimerActive(false);
    }
    return () => clearInterval(interval);
  }, [timerActive, timer]);

  const reSendCode = async () => {
    const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
    const endpoint = "api/v1/register/resend-email-verify";
    const axiosInstance = axiosConfig(baseURL);

    const email = localStorage.getItem("email");

    if (!email) {
      return;
    }
    setLoading(true);
    try {
      const response = await axiosInstance.post(endpoint, { email });
      if (response.status === 200) {
        setLoading(false);
        if (!timerActive) {
          setTimerActive(true);
          setTimer(60);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const verifyCode = async () => {
    const data = {
      phone: localStorage.getItem("phone"),
      code: otp,
    };

    const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
    const endpoint = process.env.REACT_APP_VERIFY_EMAIL_URL;
    const axiosInstance = axiosConfig(baseURL);

    try {
      const response = await axiosInstance.post(endpoint, data);
      const token = response.data.token;

      let expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 365);
      Cookies.set("token", token, {
        expires: expirationDate,
      });

      if (response.status === 200) {
        setActiveStep("personal-info");
        localStorage.removeItem("email");
      }
    } catch (error) {
      if (error.response) {
        const errorsObject = error.response.data.message;
        AboutMessage({ status: "error", message: errorsObject });
      } else {
        console.error(error.message);
      }
    }
  };

  const handleOtpChange = (otp) => {
    const filteredOtp = otp.replace(/[^\d]/g, "");
    setOtp(filteredOtp);
  };

  const closeModal = () => {
    setShowModal(false);
    setOtp("");
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60">
      <div id="modalstyleverifyemail" className="modal font-normal">
        <div id="modalcontentstyleverifyemail" className="modal-content">
          <span className="close" onClick={closeModal}>
            <IoIosClose />
          </span>
          <h2 className="w-full h-full flex flex-row justify-center items-center text-base font-normal max-sm:text-sm">
            Enter code sent to your phone number
          </h2>
          <div className="pl-2">
            <OtpInput
              value={otp}
              onChange={handleOtpChange}
              numInputs={5}
              renderSeparator={<span> </span>}
              renderInput={(props, index) => (
                <input
                  {...props}
                  style={{
                    border: "1px solid #000",
                    borderRadius: "5px",
                    textAlign: "center",
                    marginRight: "10px",
                    width: "2.25rem",
                    height: "2.25rem",
                  }}
                />
              )}
            />
          </div>
          {otp.length < 5 && (
            <button
              type="submit"
              className={`w-4/6 h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md ${
                timerActive
                  ? "w-3/6 bg-gray-400 cursor-not-allowed"
                  : "bg-gradient-to-l from-[#1976D2] to-[#6fb7ff]"
              } text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm`}
              onClick={reSendCode}
              disabled={timerActive}
            >
              {timerActive ? (
                <div className="flex flex-row justify-center items-center gap-1">
                  <IoMdTime className="max-sm:text-base" />
                  <span className="relative -top-[0.5px]">{timer}</span>
                </div>
              ) : (
                <>
                  {loading ? (
                    <CircularProgress size={16} sx={{ color: "black" }} />
                  ) : (
                    "Resend code"
                  )}
                </>
              )}
            </button>
          )}
          {otp.length === 5 && (
            <button
              type="submit"
              className="w-4/6 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
              onClick={verifyCode}
            >
              Verify Code
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyPhoneNumber;
