import { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import '../../../styles/styles.module.css';

const SelectCountryCode = ({ data, onChange }) => {
  const [selected, setSelected] = useState('');

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setSelected(selectedValue);
    onChange && onChange(selectedValue);
  };

  return (
    <div className="w-full h-full">
      <Select
        className='w-full h-full'
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selected}
        onChange={handleChange}
        displayEmpty
        MenuProps={{
          PaperProps: {
            sx: {
              '& .MuiMenuItem-root': {
                padding: 2,
              },
            },
          },
        }}
      >
        <MenuItem value="">Country Code</MenuItem>
        <MenuItem value="US">United States +1</MenuItem>
        {/* {data && data.map((item) => (
          <MenuItem key={item.code} value={item.code}>
            {`${item.name} ${item.dial_code}`}
          </MenuItem>
        ))} */}
      </Select>
    </div>
  );
}

export default SelectCountryCode;
