import React, { useRef, useImperativeHandle, forwardRef } from "react";
import SignaturePad from "react-signature-canvas";

const SignatureCanvas = forwardRef((props, ref) => {
  const signatureRef = useRef(null);

  useImperativeHandle(ref, () => ({
    isEmpty: () => signatureRef.current.isEmpty(),
    toDataURL: () => signatureRef.current.toDataURL(),
  }));

  return (
    <SignaturePad
      ref={signatureRef}
      canvasProps={{ className: "w-full h-full rounded-md bg-[#fff]" }}
    />
  );
});

export default SignatureCanvas;



