import axiosConfig from "../config/axiosConfig";
import Cookies from "js-cookie";

const fetchUserInfo = async ({ setLoading, setData }) => {
  const token = Cookies.get("token");

  if (token) {
    const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
    const endpoint = process.env.REACT_APP_USER_INFO;
    const axiosInstance = axiosConfig(baseURL, token);

    try {
      const res = await axiosInstance.get(endpoint);
      if (res.status === 200) {
        setData(res.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }
};

export default fetchUserInfo;

