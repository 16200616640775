import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DisplayStatusUser from "../../components/module/DisplayStatusUser";
import MobileMenu from "../../components/module/MobileMenu";
import { useEffect, useState } from "react";
import axiosConfig from "../../config/axiosConfig";
import Cookies from "js-cookie";
import FetchUserId from "../../utils/fetchUserId";
import Stack from "@mui/material/Stack";
import { Pagination } from "@mui/material";
import ListLoadCards from "../../components/template/ListLoadCards";
import { PulseLoader } from "react-spinners";

const FilteredLoads = () => {
  const darkMode = useSelector((state) => state.darkMode);

  const location = useLocation();
  const loadStatus = new URLSearchParams(location.search).get("status");

  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);

  FetchUserId({ userId, setUserId });

  useEffect(() => {
    if (userId !== null) {
      const fetchInfoLoads = async () => {
        const token = Cookies.get("token");
        const baseURL = process.env.REACT_APP_LOADAL_BASE_URL;
        const endpoint = `/api/v1/user/${userId}/get-loads?status=${loadStatus}`;

        const axiosInstance = axiosConfig(baseURL, token);

        try {
          const res = await axiosInstance.get(endpoint);
          if (res.status === 200) {
            setPages(res.data.data.last_page);
            setCurrentPage(res.data.data.current_page);
          }
        } catch (error) {
          console.error(error);
        }
      };
      fetchInfoLoads();
    }
  }, [userId, loadStatus]);

  useEffect(() => {
    if (loadStatus !== "pending") {
      if (userId !== null) {
        const fetchLoads = async () => {
          const token = Cookies.get("token");
          const baseURL = process.env.REACT_APP_LOADAL_BASE_URL;
          const endpoint = `/api/v1/user/${userId}/get-loads?page=1&status=${loadStatus}`;

          const axiosInstance = axiosConfig(baseURL, token);

          try {
            const res = await axiosInstance.get(endpoint);
            if (res.status === 200) {
              setData(res.data.data.data);
              setLoading(false);
            }
          } catch (error) {
            console.error(error);
          }
        };
        fetchLoads();
      }
    }
  }, [userId, loadStatus]);

  useEffect(() => {
    if (loadStatus === "pending") {
      if (userId !== null) {
        const fetchLoads = async () => {
          const token = Cookies.get("token");
          const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
          const endpoint = "/api/v1/user/get-pending-loads";

          const axiosInstance = axiosConfig(baseURL, token);

          try {
            const res = await axiosInstance.get(endpoint);
            if (res.status === 200) {
              setData(res.data.data.data);
              setLoading(false);
            }
          } catch (error) {
            console.error(error);
          }
        };
        fetchLoads();
      }
    }
  }, [userId, loadStatus]);

  const handleChange = (event, value) => {
    if (value > currentPage) {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, pages));
    } else {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    }
  };

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div
        className={`w-full min-h-screen flex flex-col justify-start items-center ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <div className="w-full h-auto fixed top-0 left-0 right-0">
          <DisplayStatusUser />
        </div>

        {loading ? (
          <div
            className={` h-screen w-full flex flex-col justify-center items-center pb-[1.60rem]  ${
              darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
            }`}
          >
            <PulseLoader color="#fbbf24" />
          </div>
        ) : (
          <div className="w-full min-h-screen flex flex-col justify-between items-center gap-8 pt-20 pb-[7rem]">
            <ListLoadCards data={data} />
            <Stack spacing={2}>
              <Pagination
                count={pages}
                page={currentPage}
                onChange={handleChange}
              />
            </Stack>
          </div>
        )}
        <div className="fixed bottom-0 left-0 right-0">
          <MobileMenu />
        </div>
      </div>
    </>
  );
};

export default FilteredLoads;
