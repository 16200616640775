import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { FaBoxOpen } from "react-icons/fa";
import { FullscreenControl } from "react-leaflet-fullscreen";
import "react-leaflet-fullscreen/styles.css";
import { useSelector } from "react-redux";
import { divIcon } from "leaflet";
import { renderToStaticMarkup } from "react-dom/server";
import { AiFillInfoCircle } from "react-icons/ai";

const iconMarkup = renderToStaticMarkup(
  <FaBoxOpen className="text-[#2880CA] text-[1.75rem]" />
);
const originMarker = divIcon({
  className: 'custom-icon',
  html: iconMarkup,
});

const destinationMarker = new L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
  iconSize: [20, 32],
});

const LocationMap = ({ loadData }) => {
  const darkMode = useSelector((state) => state.darkMode);

  // مبدا
  const origin = loadData.load_pick_up_at_location
    ? loadData.load_pick_up_at_location
        .split(",")
        .map((item) => parseFloat(item.trim()))
    : null;

  // مقصد
  const destination = loadData.load_deliver_to_location
    ? loadData.load_deliver_to_location
        .split(",")
        .map((item) => parseFloat(item.trim()))
    : null;

  if (!origin || !destination) {
    return (
      <div
        className={`w-full h-auto flex flex-row justify-center items-center ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <div
          className={`w-10/12 h-auto flex flex-row justify-start items-center gap-1 rounded-md p-4 max-md:w-full ${
            darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
          }`}
        >
          <AiFillInfoCircle className="text-blue-500 text-xl" />
          <p className="cursor-default font-normal text-sm">
            The origin and destination are not available.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-auto flex flex-row justify-center items-center shadow-md cursor-pointer">
      <div
        style={{ position: "relative", width: "100%", height: "100%" }}
      >
        <MapContainer
          center={origin}
          zoom={7}
          scrollWheelZoom={true}
          style={{ height: "25vh", width: "100%" }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Marker position={origin} icon={originMarker}></Marker>
          <Marker position={destination} icon={destinationMarker}></Marker>
          <FullscreenControl />
        </MapContainer>
      </div>
    </div>
  );
};

export default LocationMap;