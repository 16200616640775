import { useEffect, useRef, useState } from "react";
import axiosConfig from "../../../config/axiosConfig";
import UserStatusTable from "../UserStatusTable";
import Cookies from "js-cookie";
import AboutMessage from "../../module/AboutMessage";
import Spinner from "../../module/Spinner";
import { IoMdTime } from "react-icons/io";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PhoneVerifyForm = ({ setActiveStep }) => {
  const [data, setData] = useState(null);
  const [show, setShow] = useState(false);
  const [codePhone, setCodePhone] = useState("");
  const [codeBackupNumber, setCodeBackupNumber] = useState("");
  const [showFieldCode, setShowFieldCode] = useState(false);
  const [loader, setLoader] = useState(true);

  const [loadingSendCode, setLoadingSendCode] = useState(false);
  const [loadingCheckCode, setLoadingCheckCode] = useState(false);

  const [timer, setTimer] = useState(60);
  const [timerActive, setTimerActive] = useState(false);

  const [statusPhone, setStatusPhone] = useState(false);
  const [statusBackupNumber, setStatusBackupNumber] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [phone, setPhone] = useState("");
  const [newPhone, setNewPhone] = useState(phone);
  const [backupPhone, setBackupPhone] = useState("");
  const [newBackupPhone, setNewBackupPhone] = useState(backupPhone);

  const inputRef = useRef(null);

  const navigate = useNavigate();
  const statusRegister = useSelector((state) => state.statusRegister);

  useEffect(() => {
    fetchData();
    setShow(true);
  }, []);

  const fetchData = async () => {
    const token = Cookies.get("token");
    const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
    const endpoint = process.env.REACT_APP_PHONE_VERIFY_URL;
    const axiosInstance = axiosConfig(baseURL, token);

    try {
      const response = await axiosInstance.get(endpoint);

      setData(response.data.data[0]);
      setPhone(response.data.data[0].phone);
      setBackupPhone(response.data.data[0].backup_number);
      response.data.data[0].phone_verified_at === null && setStatusPhone(false);
      response.data.data[0].phone_verified_at !== null && setStatusPhone(true);
      response.data.data[0].backup_number_verified_at === null &&
        setStatusBackupNumber(false);
      response.data.data[0].backup_number_verified_at !== null &&
        setStatusBackupNumber(true);
      setLoader(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let interval;
    if (timerActive && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setTimerActive(false);
    }
    return () => clearInterval(interval);
  }, [timerActive, timer]);

  const sendCodePhone = async () => {
    setLoadingSendCode(true);
    try {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_PHONE_VERIFY_SEND_CODE_INFO_URL;
      const axiosInstance = axiosConfig(baseURL, token);

      const value = {
        id: data.id,
        type: "phone",
        country_code: data.country_code,
        phone: phone,
      };

      const response = await axiosInstance.post(endpoint, value);
      if (response.status === 200) {
        setLoadingSendCode(false);
        AboutMessage({ status: "success", message: response.data.message });
        setShowFieldCode(true);
        if (!timerActive) {
          setTimerActive(true);
          setTimer(60);
        }
      }
    } catch (error) {
      // console.log(error)

      const errors = error.response.data.message;

      if (typeof errors === "string") {
        AboutMessage({
          status: "error",
          message: errors,
        });
      } else if (typeof errors === "object" && errors !== null) {
        Object.entries(errors).forEach(([key, errorMessages]) => {
          errorMessages.forEach((errorMessage) => {
            AboutMessage({
              status: "error",
              message: errorMessage,
            });
          });
        });
      } else {
        console.log("The error message type is invalid");
      }

      setLoadingSendCode(false);
    }
  };

  const checkCodePhone = async () => {
    if (codePhone.length === 0) {
      AboutMessage({ status: "error", message: "The code field is required" });
      return;
    }
    if (codePhone.length > 5 || codePhone.length < 5) {
      AboutMessage({
        status: "error",
        message: "The code field must be 5 digits",
      });
      return;
    }
    try {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_PHONE_VERIFY_CHECK_CODE_INFO_URL;
      const axiosInstance = axiosConfig(baseURL, token);

      const value = {
        id: data.id,
        code: codePhone,
        type: "phone",
      };
      setLoadingCheckCode(true);
      const response = await axiosInstance.post(endpoint, value);
      if (response.status === 200) {
        AboutMessage({ status: "success", message: response.data.message });
        setShowFieldCode(false);
        setLoadingCheckCode(false);
        setTimerActive(false);

        fetchData();
      }
    } catch (error) {
      setLoadingCheckCode(false);
      AboutMessage({ status: "error", message: error.response.data.message });
    }
  };

  const sendCodeBackupNumber = async () => {
    setLoadingSendCode(true);
    try {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_PHONE_VERIFY_SEND_CODE_INFO_URL;
      const axiosInstance = axiosConfig(baseURL, token);

      const value = {
        id: data.id,
        type: "backup_number",
        country_code: data.country_code,
        phone: backupPhone,
      };

      const response = await axiosInstance.post(endpoint, value);
      if (response.status === 200) {
        setLoadingSendCode(false);
        AboutMessage({ status: "success", message: response.data.message });
        setShowFieldCode(true);
        if (!timerActive) {
          setTimerActive(true);
          setTimer(60);
        }
      }
    } catch (error) {
      setLoadingSendCode(false);
      AboutMessage({ status: "error", message: error.message });
    }
  };

  const checkCodeBackupNumber = async () => {
    if (codeBackupNumber.length === 0) {
      AboutMessage({ status: "error", message: "The code field is required" });
      return;
    }
    if (codeBackupNumber.length > 5 || codeBackupNumber.length < 5) {
      AboutMessage({
        status: "error",
        message: "The code field must be 5 digits",
      });
      return;
    }
    try {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_PHONE_VERIFY_CHECK_CODE_INFO_URL;
      const axiosInstance = axiosConfig(baseURL, token);

      const value = {
        id: data.id,
        code: codeBackupNumber,
        type: "backup_number",
      };
      setLoadingCheckCode(true);
      const response = await axiosInstance.post(endpoint, value);
      if (response.status === 200) {
        AboutMessage({ status: "success", message: response.data.message });
        setShowFieldCode(false);
        setLoadingCheckCode(false);
        fetchData();
      }
    } catch (error) {
      setLoadingCheckCode(false);
      AboutMessage({ status: "error", message: error.response.data.message });
    }
  };

  const verify = () => {
    setShow(true);
    if (statusPhone === true && statusBackupNumber === true) {
      if (statusRegister === "complete") {
        navigate("/");
      } else {
        setActiveStep("sign-contract");
      }
    }
  };

  return (
    <div className="w-full h-screen flex flex-col justify-start items-center bg-[#F1F5F9] text-black font-normal pt-8">
      {loader ? (
        <div className="h-screen w-full flex flex-col justify-center items-center bg-[#F1F5F9]">
          <Spinner />
        </div>
      ) : (
        <div className="w-11/12 h-auto flex flex-col justify-center items-start gap-10 bg-[#E2E8F0] rounded-md p-8 max-sm:p-5">
          <div className="flex flex-row justify-center items-end gap-2">
            <h1 className="text-xl uppercase max-sm:text-sm">Register</h1>
            <span className="text-xl text-[#1976D2] uppercase max-sm:text-sm">
              Phone Verify
            </span>
          </div>
          <div className="w-full h-full flex flex-col gap-10">
            <UserStatusTable data={data} />
            <div className="flex flex-col justify-start items-center gap-2">
              {show && (
                <div className="w-full h-auto flex flex-col justify-center items-center gap-2">
                  {statusPhone === false && (
                    <div className="w-full h-auto flex flex-col justify-center items-center gap-2">
                      <div className="w-full h-auto flex flex-row justify-between items-center gap-1 rounded-md p-3 bg-[#F1F5F9] shadow-md max-sm:text-xs max-md:text-sm">
                        <input
                          className={
                            isEditing
                              ? "bg-inherit input-focused p-2 rounded-md"
                              : "bg-inherit rounded-md p-2"
                          }
                          value={isEditing ? newPhone : phone}
                          ref={inputRef}
                          onChange={(e) => setNewPhone(e.target.value)}
                          onFocus={() => setIsEditing(true)}
                        />
                        <div className="w-4/6 h-auto flex flex-row justify-center items-center gap-1">
                          <div className="w-4/12 h-auto flex flex-row justify-center items-center">
                            {isEditing ? (
                              <button
                                type="button"
                                onClick={() => {
                                  setIsEditing(false);
                                  setPhone(newPhone);
                                }}
                                className="w-full h-auto flex flex-row justify-center items-center p-2 bg-gradient-to-l from-[#3d3d3d] to-[#616161] rounded-md text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                              >
                                Save
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() => {
                                  setIsEditing(true);
                                  inputRef.current.focus();
                                }}
                                className="w-full h-auto flex flex-row justify-center items-center p-2 bg-gradient-to-l from-[#3d3d3d] to-[#616161] rounded-md text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                              >
                                Edit
                              </button>
                            )}
                          </div>
                          <button
                            onClick={sendCodePhone}
                            className={`w-8/12 h-auto flex flex-row justify-center items-center p-2 rounded-md ${
                              timerActive
                                ? "w-1/2 bg-[#E2E8F0] cursor-not-allowed"
                                : "bg-gradient-to-l from-[#1976D2] to-[#6fb7ff]"
                            } text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm`}
                          >
                            {loadingSendCode ? (
                              <CircularProgress
                                size={16}
                                sx={{ color: "black" }}
                              />
                            ) : timerActive ? (
                              <div className="flex flex-row justify-center items-center gap-1">
                                <IoMdTime className="max-sm:text-base" />
                                <span className="relative -top-[0.5px]">
                                  {timer}
                                </span>
                              </div>
                            ) : (
                              "Send Code"
                            )}
                          </button>
                        </div>
                      </div>
                      {showFieldCode === true && (
                        <div className="w-full h-auto flex flex-row justify-between items-center rounded-md p-3 bg-[#F1F5F9] shadow-md max-sm:text-xs max-md:text-sm">
                          <input
                            className="w-8/12 h-auto flex flex-row justify-start items-center bg-[#F1F5F9] outline-none max-sm:text-xs max-md:text-sm"
                            ref={inputRef}
                            value={codePhone}
                            onChange={(e) => setCodePhone(e.target.value)}
                            placeholder="Code"
                          />
                          <button
                            onClick={checkCodePhone}
                            className="w-4/12 h-auto flex flex-row justify-center items-center p-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                          >
                            {loadingCheckCode ? (
                              <CircularProgress
                                size={16}
                                sx={{ color: "black" }}
                              />
                            ) : (
                              "Check code"
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  {statusPhone === true && statusBackupNumber === false && (
                    <div className="w-full h-auto flex flex-col justify-center items-center gap-2">
                      <div className="w-full h-auto flex flex-row justify-between items-center gap-1 rounded-md p-3 bg-[#F1F5F9] shadow-md max-sm:text-xs max-md:text-sm">
                        <input
                          className={
                            isEditing
                              ? "bg-inherit input-focused p-2 rounded-md"
                              : "bg-inherit p-2 rounded-md"
                          }
                          ref={inputRef}
                          value={isEditing ? newBackupPhone : backupPhone}
                          onChange={(e) => setNewBackupPhone(e.target.value)}
                          onFocus={() => setIsEditing(true)}
                        />
                        <div className="w-4/6 h-auto flex flex-row justify-center items-center gap-1">
                          <div className="w-4/12 h-auto flex flex-row justify-center items-center">
                            {isEditing ? (
                              <button
                                type="button"
                                onClick={() => {
                                  setIsEditing(false);
                                  setBackupPhone(newBackupPhone);
                                }}
                                className="w-full h-auto flex flex-row justify-center items-center p-2 bg-gradient-to-l from-[#3d3d3d] to-[#616161] rounded-md text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                              >
                                Save
                              </button>
                            ) : (
                              <button
                                type="button"
                                onClick={() => {
                                  setIsEditing(true);
                                  inputRef.current.focus();
                                }}
                                className="w-full h-auto flex flex-row justify-center items-center p-2 bg-gradient-to-l from-[#3d3d3d] to-[#616161] rounded-md text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                              >
                                Edit
                              </button>
                            )}
                          </div>
                          <button
                            onClick={sendCodeBackupNumber}
                            className={`w-8/12 h-auto flex flex-row justify-center items-center p-2 rounded-md ${
                              timerActive
                                ? "w-1/2 bg-[#E2E8F0] cursor-not-allowed"
                                : "bg-gradient-to-l from-[#1976D2] to-[#6fb7ff]"
                            } text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm`}
                          >
                            {loadingSendCode ? (
                              <CircularProgress
                                size={16}
                                sx={{ color: "black" }}
                              />
                            ) : timerActive ? (
                              <div className="flex flex-row justify-center items-center gap-1">
                                <IoMdTime className="max-sm:text-base" />
                                <span className="relative -top-[0.5px]">
                                  {timer}
                                </span>
                              </div>
                            ) : (
                              "Send Code"
                            )}
                          </button>
                        </div>
                      </div>
                      {showFieldCode === true && (
                        <div className="w-full h-auto flex flex-row justify-between items-center rounded-md p-3 bg-[#F1F5F9] shadow-md max-sm:text-xs max-md:text-sm">
                          <input
                            className="w-8/12 h-auto flex flex-row justify-start items-center bg-[#F1F5F9] outline-none max-sm:text-xs max-md:text-sm"
                            value={codeBackupNumber}
                            onChange={(e) =>
                              setCodeBackupNumber(e.target.value)
                            }
                            placeholder="Code"
                          />
                          <button
                            onClick={checkCodeBackupNumber}
                            className="w-4/12 h-auto flex flex-row justify-center items-center p-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                          >
                            {loadingCheckCode ? (
                              <CircularProgress
                                size={16}
                                sx={{ color: "black" }}
                              />
                            ) : (
                              "Check code"
                            )}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="w-full h-auto flex flex-row justify-end items-center gap-1">
              {statusPhone === true && statusBackupNumber === true ? (
                <button
                  type="button"
                  className="w-1/2 h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                  onClick={verify}
                >
                  Next
                </button>
              ) : (
                <button
                  type="button"
                  className="w-1/2 h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md bg-gradient-to-l from-[#3d3d3d] to-[#616161] cursor-not-allowed text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhoneVerifyForm;
