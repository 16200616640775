import { Field, ErrorMessage } from "formik";

const PhoneNumberField = ({ name, minLength, maxLength, placeholder }) => {
  return (
    <div className="w-full flex flex-col justify-start items-center gap-1 font-normal">
      <div className="w-full h-auto flex flex-row justify-center items-center rounded-md bg-[#111827a6]">
        <p className="w-1/6 h-full flex flex-row justify-end items-center pr-3 text-white text-xl rounded-l-md max-md:text-base max-sm:text-xs">
          + 1
        </p>
        <Field
          className="w-5/6 h-full flex flex-row justify-start items-center rounded-r-md p-3 text-white text-base outline-none bg-[#00000000] placeholder:text-white max-sm:text-xs"
          name={name}
          minLength={minLength}
          maxLength={maxLength}
          placeholder={placeholder}
        />
      </div>
      <ErrorMessage
        name={name}
        component="div"
        className="w-full h-auto text-sm text-red-600 max-sm:text-xs"
      />
    </div>
  );
};

export default PhoneNumberField;
