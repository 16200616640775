import { IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { FaTruckLoading } from "react-icons/fa";
import { MdPendingActions, MdOutlineEventAvailable } from "react-icons/md";
import { FaTruckFast } from "react-icons/fa6";
import AcceptedBoxLoads from "../module/AcceptedBoxLoads";

const HomeDetails = ({ checked, loading, data,loadingFetchLoad, listAcceptedLoads }) => {
  const darkMode = useSelector((state) => state.darkMode);

  if (checked || loading || loadingFetchLoad) {
    return (
      <div
        className={`fixed h-screen w-full flex flex-col justify-center items-center pb-[11.5rem]  ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <PulseLoader color="#fbbf24" />
      </div>
    );
  }

  return (
    <div
      className={`w-full h-full flex flex-col justify-start items-center gap-3 font-normal px-4 ${
        darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
      }`}
    >
      <div
        className={`w-full h-auto flex flex-col justify-start items-center gap-6 rounded-md p-8 max-md:p-6 max-sm:p-4 max-md:gap-2 ${
          darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
        }`}
      >
        <h1 className="w-full h-auto text-xl font-normal uppercase max-sm:text-xl">
          Book vehicle for
        </h1>
        <div className="w-full h-auto flex flex-row justify-center items-center flex-wrap gap-0 max-md:py-5 ">
          <Link
            to={{
              pathname: "/filteredloads",
              search: "?status=pending",
            }}
            className={`w-4/12 h-auto flex flex-col justify-center items-start gap-4 rounded-md p-5 cursor-pointer shadow-md max-md:w-6/12 max-md:min-h-36 max-md:shadow-none relative ${
              darkMode
                ? "bg-gray-900 max-md:border-2 border-[#1E293B]"
                : "bg-[#F1F5F9] max-md:border-2 border-[#E2E8F0]"
            }`}
          >
            <p
              className={`w-6 h-6 rounded-lg flex flex-row justify-center items-center shadow text-sm absolute top-2 right-2 ${
                darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
              }`}
            >
              <span className="relative -top-[1px]">
                {data.load_data_count.pending_load_count}
              </span>
            </p>
            <MdPendingActions className="text-6xl max-sm:text-4xl" />
            <div className="w-full h-auto flex flex-row justify-between items-start">
              <div className="flex flex-row justify-center items-center gap-1 text-base max-md:text-sm max-sm:text-xs">
                <p>Pending</p>
                <p>Loads</p>
              </div>
              <IoIosArrowForward className="max-sm:text-xs max-sm:relative top-[2px]" />
            </div>
          </Link>
          <Link
            to={{
              pathname: "/filteredloads",
              search: "?status=accept",
            }}
            className={`w-4/12 h-auto flex flex-col justify-center items-start gap-4 rounded-md p-5 cursor-pointer shadow-md max-md:w-6/12 max-md:min-h-36 max-md:shadow-none relative ${
              darkMode
                ? "bg-gray-900 max-md:border-2 border-[#1E293B]"
                : "bg-[#F1F5F9] max-md:border-2 border-[#E2E8F0]"
            }`}
          >
            <p
              className={`w-6 h-6 rounded-lg flex flex-row justify-center items-center shadow text-sm absolute top-2 right-2 ${
                darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
              }`}
            >
              <span className="relative -top-[1px]">
                {data.load_data_count.accept_load_count}
              </span>
            </p>
            <MdOutlineEventAvailable className="text-6xl max-sm:text-4xl" />
            <div className="w-full h-auto flex flex-row justify-between items-start">
              <div className="flex flex-row justify-center items-center gap-1 text-base max-md:text-sm max-sm:text-xs">
                <p>Accept</p>
                <p>Loads</p>
              </div>
              <IoIosArrowForward className="max-sm:text-xs max-sm:relative top-[2px]" />
            </div>
          </Link>
          <Link
            to={{
              pathname: "/filteredloads",
              search: "?status=transit",
            }}
            className={`w-4/12 h-auto flex flex-col justify-center items-start gap-4 rounded-md p-5 cursor-pointer shadow-md max-md:w-6/12 max-md:min-h-36 max-md:shadow-none relative ${
              darkMode
                ? "bg-gray-900 max-md:border-2 border-[#1E293B]"
                : "bg-[#F1F5F9] max-md:border-2 border-[#E2E8F0]"
            }`}
          >
            <p
              className={`w-6 h-6 rounded-lg flex flex-row justify-center items-center shadow text-sm absolute top-2 right-2 ${
                darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
              }`}
            >
              <span className="relative -top-[1px]">
                {data.load_data_count.transit_load_count}
              </span>
            </p>
            <FaTruckFast className="text-6xl max-sm:text-4xl" />
            <div className="w-full h-auto flex flex-row justify-between items-start">
              <div className="flex flex-row justify-center items-center gap-1 text-base max-md:text-sm max-sm:text-xs">
                <p>In Transit</p>
                <p>Loads</p>
              </div>
              <IoIosArrowForward className="max-sm:text-xs max-sm:relative top-[2px]" />
            </div>
          </Link>
          <Link
            to={{
              pathname: "/filteredloads",
              search: "?status=delivered",
            }}
            className={`w-4/12 h-auto flex flex-col justify-center items-start gap-4 rounded-md p-5 cursor-pointer shadow-md max-md:w-6/12 max-md:min-h-36 max-md:shadow-none relative ${
              darkMode
                ? "bg-gray-900 max-md:border-2 border-[#1E293B]"
                : "bg-[#F1F5F9] max-md:border-2 border-[#E2E8F0]"
            }`}
          >
            <p
              className={`w-6 h-6 rounded-lg flex flex-row justify-center items-center shadow text-sm absolute top-2 right-2 ${
                darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
              }`}
            >
              <span className="relative -top-[1px]">
                {data.load_data_count.delivered_load_count}
              </span>
            </p>
            <FaTruckLoading className="text-6xl max-sm:text-4xl" />
            <div className="w-full h-auto flex flex-row justify-between items-start">
              <div className="flex flex-row justify-center items-center gap-1 text-base max-md:text-sm max-sm:text-xs">
                <p>Delivered</p>
                <p>Loads</p>
              </div>
              <IoIosArrowForward className="max-sm:text-xs max-sm:relative top-[2px]" />
            </div>
          </Link>
        </div>
      </div>
      <AcceptedBoxLoads listAcceptedLoads={listAcceptedLoads} />
    </div>
  );
};

export default HomeDetails;
