import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { BsCloudUpload } from "react-icons/bs";
import AboutMessage from "./AboutMessage";
import { ImageList, ImageListItem } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import FileAccess  from "././modal/FileAccess";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function InputFileUpload({
  title,
  selectedFiles,
  setSelectedFiles,
}) {
  const fileInputRef = useRef(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("fileAccess") === null) {
      localStorage.setItem("fileAccess", "false");
    }
  }, []);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    const validFiles = files.filter((file) => {
      if (file.size > 2 * 1024 * 1024) {
        AboutMessage({
          status: "error",
          message: `The size of the file ${file.name} is more than 2 MB`,
        });
        return false;
      }
      return true;
    });

    if (selectedFiles.length + validFiles.length > 3) {
      AboutMessage({
        status: "error",
        message: "You can only select up to 3 images.",
      });
      return;
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleModalConfirm = () => {
    localStorage.setItem("fileAccess", "true");
    setShowModal(false);
    fileInputRef.current.click();
  };

  const uploadFile = () => {
    const fileAccess = localStorage.getItem("fileAccess");

    if (fileAccess === "false") {
      setShowModal(true);
    } else {
      fileInputRef.current.click();
    }
  };

  const handleDeleteImage = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div className="flex flex-col justify-center items-start gap-4 bg-[#F1F5F9] font-normal rounded-md p-4">
      <p className="text-black cursor-default max-sm:text-xs max-md:text-sm">
        {title}
      </p>
      <Button
        className="w-full h-auto flex flex-row justify-center items-center gap-2 px-6 py-2 !rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] bg-[#E2E8F0] text-black text-base !font-normal shadow-md max-sm:text-xs max-md:text-sm"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<BsCloudUpload style={{ fontSize: 20 }} />}
        onClick={uploadFile}
      >
        Upload file
        <VisuallyHiddenInput
          ref={fileInputRef}
          type="file"
          onChange={handleFileChange}
          accept="image/jpeg, image/png"
        />
      </Button>
      <ImageList cols={1} rowHeight={164}>
        {selectedFiles.map((file, index) => (
          <ImageListItem key={index}>
            <img
              className="w-7/12 h-auto rounded-md max-sm:w-full"
              src={URL.createObjectURL(file)}
              alt={file.name}
            />
            <div className="w-5/12 h-full flex flex-col justify-center items-start">
              <div className="w-full h-auto flex flex-row justify-start items-center cursor-default text-black max-md:text-xs">
                {file.name}
              </div>
              <button
                type="button"
                onClick={() => handleDeleteImage(index)}
                className="text-red-500 max-md:text-sm"
              >
                Delete
              </button>
            </div>
          </ImageListItem>
        ))}
        {showModal && (
          <FileAccess
            open={showModal}
            handleClose={() => setShowModal(false)}
            handleConfirm={handleModalConfirm}
          />
        )}
      </ImageList>
    </div>
  );
}
