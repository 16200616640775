import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../module/Notification";
import { Helmet } from "react-helmet";
import useDarkMode from "../../hooks/useDarkMode";
import Cookies from "js-cookie";
import Pusher from "pusher-js";
import AboutMessage from "../module/AboutMessage";
import "firebase/compat/messaging";
import { setMessageList } from "../../redux_toolkit/features/messageListSlice";
import FetchUserId from "../../utils/fetchUserId";
import axiosConfig from "../../config/axiosConfig";
import { PulseLoader } from "react-spinners";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode);
  const messageList = useSelector((state) => state.messageList);

  const [userId, setUserId] = useState(null);
  const [viewportType, setViewportType] = useState(true);
  const [loading, setLoading] = useState(true);

  const themeColor = darkMode ? "#111827" : "#F1F5F9";

  useDarkMode();

  useEffect(() => {
    const checkWindowSize = () => {
      setViewportType(window.innerWidth > 767);
    };

    checkWindowSize();
    window.addEventListener("resize", checkWindowSize);
    return () => {
      window.removeEventListener("resize", checkWindowSize);
    };
  }, []);

  FetchUserId({ userId, setUserId });

  useEffect(() => {
    const fetchPusherOptions = async () => {
      try {
        const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
        const endpoint = process.env.REACT_APP_GET_TOKEN_PUSHER;
        const axiosInstance = axiosConfig(baseURL);
        const response = await axiosInstance.get(endpoint);

        const pusherOptions = response.data.data;
        Cookies.set("pusherOptions", JSON.stringify(pusherOptions));
        initializePusher(pusherOptions);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    const initializePusher = (pusherOptions) => {
      Pusher.logToConsole = false;
      const pusher = new Pusher(pusherOptions.PUSHER_APP_KEY, {
        cluster: pusherOptions.PUSHER_APP_CLUSTER,
      });
      const channel = pusher.subscribe(`atoz-front-channel-${userId}`);
      channel.bind("atoz-front-offerMessage-event", function (data) {
        const message = data.message.data.title;
        AboutMessage({ status: "info", message: message, darkMode });
        dispatch(setMessageList([...messageList, data.message.data]));
      });

      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    };

    fetchPusherOptions();
  }, [darkMode, userId]);

  if (loading) {
    return (
      <div
        className={`fixed h-screen w-full flex flex-col justify-center items-center ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <PulseLoader color="#fbbf24" />
      </div>
    );
  }

  if (viewportType) {
    return (
      <div className="h-screen w-full flex flex-col justify-center items-center bg-[#F1F5F9] text-black">
        <p className="w-10/12 h-auto flex flex-row justify-center items-center rounded-md bg-[#E2E8F0] p-10">
          Please open this app on a mobile device. The desktop version is under
          development. Thank you for your understanding!
        </p>
      </div>
    );
  } else {
    return (
      <>
        <Helmet>
          <meta name="theme-color" content={themeColor} />
        </Helmet>
        <Notification />
        {children}
      </>
    );
  }
};

export default Layout;
