import { PulseLoader } from "react-spinners";

const ScreenProtector = () => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-[#cecece38] z-50 -top-6">
      <PulseLoader color="#fbbf24"/>
    </div>
  );
};

export default ScreenProtector;
