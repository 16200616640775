import { useState, useEffect } from "react";
import { requestForToken, onMessageListener } from "../../firebase";
import AboutMessage from "./AboutMessage";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router-dom";
// import { setMessageBoxInfo } from "../../redux_toolkit/features/messageBoxInfoSlice";
import { setRefetchLoads } from "../../redux_toolkit/features/refetchLoadsSlice";
import { setRefetchMessages } from "../../redux_toolkit/features/refetchMessagesSlice";
// import { setRefetchMessagesAgain } from "../../redux_toolkit/features/refetchMessagesAgainSlice";

const Notification = () => {
  const dispatch = useDispatch();
  const darkMode = useSelector((state) => state.darkMode);
  // const messageBoxInfo = useSelector((state) => state.messageBoxInfo);
  const refetchLoads = useSelector((state) => state.refetchLoads);
  const refetchMessages = useSelector((state) => state.refetchMessages);
  // const refetchMessagesAgain = useSelector((state) => state.refetchMessagesAgain);

  // const { pathname } = useLocation();
  // const parts = pathname.split("/");
  // const loadId = parts[2];

  const [notification, setNotification] = useState({ title: "" });
  // const [dataPayload, setDataPayload] = useState("");

  useEffect(() => {
    requestForToken();
  }, []);

  // useEffect(() => {
  //   if (dataPayload) {
  //     if (dataPayload.type === "loadMessage") {
  //       if (dataPayload.loadId === loadId) {
  //         dispatch(setMessageBoxInfo([...messageBoxInfo, dataPayload]));
  //       }
  //     }
  //   }
  // }, [dataPayload, dispatch, loadId]);

  useEffect(() => {
    if (notification?.title) {
      AboutMessage({ status: "info", message: notification?.title, darkMode });
    }
  }, [notification, darkMode]);

  onMessageListener()
    .then((payload) => {
      const title = payload.notification.title;
      if (title === "New load") {
        dispatch(setRefetchLoads(refetchLoads + 1));
      }
      if (title === "A new message") {
        dispatch(setRefetchMessages(refetchMessages + 1));
      }

      setNotification({
        title: payload?.notification?.title,
      });
    })
    .catch((err) => console.error("failed: ", err));

  return null;
};

export default Notification;
