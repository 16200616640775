const UserStatusTable = ({ data }) => {
  return (
    <>
      {data && (
        <div className="w-full h-full flex flex-col justify-start items-center gap-2 bg-[#E2E8F0] cursor-default">
          <div className="w-full h-auto flex flex-row justify-between items-center rounded-md gap-2 p-3 bg-[#F1F5F9] shadow-md">
            <p className="w-4/12 h-auto max-sm:text-xs max-md:text-sm">Phone</p>
            <p className="w-4/12 h-auto flex flex-row justify-end items-center max-sm:text-xs max-md:text-sm">
              {data.phone}
            </p>
            <div className="w-4/12 h-auto flex flex-row justify-end items-center">
              {data.phone_verified_at === null ? (
                <p className="text-red-600 uppercase max-sm:text-xs max-md:text-sm">
                  not verified
                </p>
              ) : (
                <p className="text-green-600 uppercase max-sm:text-xs max-md:text-sm">
                  Verified
                </p>
              )}
            </div>
          </div>
          <div className="w-full h-auto flex flex-row justify-between items-center rounded-md gap-2 p-3 bg-[#F1F5F9] shadow-md">
            <p className="w-4/12 h-auto max-sm:text-xs max-md:text-sm">
              Backup Phone
            </p>
            <p className="w-4/12 h-auto flex flex-row justify-end items-center max-sm:text-xs max-md:text-sm">
              {data.backup_number}
            </p>
            <div className="w-4/12 flex flex-row justify-end items-center">
              {data.backup_number_verified_at === null ? (
                <p className="text-red-600 uppercase max-sm:text-xs max-md:text-sm">
                  not verified
                </p>
              ) : (
                <p className="text-green-600 uppercase max-sm:text-xs max-md:text-sm">
                  Verified
                </p>
              )}
            </div>
          </div>
          <div className="w-full h-auto flex flex-row justify-between items-center rounded-md gap-2 p-3 bg-[#F1F5F9] shadow-md">
            <p className="max-sm:text-xs max-md:text-sm">Status</p>
            <p className="text-green-600 uppercase max-sm:text-xs max-md:text-sm">
              {data.status}
            </p>
          </div>
          <div className="w-full h-auto flex flex-row justify-between items-center rounded-md gap-2 p-3 bg-[#F1F5F9] shadow-md">
            <p className="max-sm:text-xs max-md:text-sm">Verify</p>
            <div className="uppercase">
              {data.phone_verified_at !== null &&
              data.backup_number_verified_at !== null ? (
                <p className="text-green-600 max-sm:text-xs max-md:text-sm">
                  verified
                </p>
              ) : (
                <p className="text-red-600 max-sm:text-xs max-md:text-sm">
                  not verified
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UserStatusTable;
