import { useEffect } from "react";
import Cookies from "js-cookie";
import axiosConfig from "../config/axiosConfig";

const useUserInfoFetcher = ({ setData, setLoading }) => {
  useEffect(() => {
    const fetchUserInfo = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_USER_INFO;
      const axiosInstance = axiosConfig(baseURL, token);

      try {
        const res = await axiosInstance.get(endpoint);
        if (res.status === 200) {
          const userId = res.data.data.id;
          Cookies.set("userId", userId);

          setData(res.data.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, [setData, setLoading]);
};

export default useUserInfoFetcher;
