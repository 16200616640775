import { useEffect } from "react";

const useScreenWakeLock = () => {
  useEffect(() => {
    let wakeLock = null;

    const requestWakeLock = async () => {
      try {
        wakeLock = await navigator.wakeLock.request("screen");
      } catch (err) {
        console.error(`${err.name}, ${err.message}`);
      }
    };

    const releaseWakeLock = async () => {
      if (wakeLock !== null) {
        await wakeLock.release();
        wakeLock = null;
      }
    };

    requestWakeLock();

    return () => {
      releaseWakeLock();
    };
  }, []);
};

export default useScreenWakeLock;
