import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useEffect, useState } from "react";
import { TbLogout2 } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
// import { makeStyles } from "@mui/material";

const steps = [
  "Personal Info",
  "Truck Info",
  // "Driver Licence",
  "Bank Info",
  "Phone Verify",
  "Sign Contract",
];

const CustomStepper = ({ activeStep }) => {
  const navigate = useNavigate();
  const [activeStepIndex, setActiveStepIndex] = useState(0);

  // useEffect(() => {
  //   if (activeStep === "personal-info") {
  //     setActiveStepIndex(1);
  //   } else if (activeStep === "truck-info") {
  //     setActiveStepIndex(2);
  //   } else if (activeStep === "driver-licence") {
  //     setActiveStepIndex(3);
  //   } else if (activeStep === "bank-info") {
  //     setActiveStepIndex(4);
  //   } else if (activeStep === "phone-verify") {
  //     setActiveStepIndex(5);
  //   } else if (activeStep === "sign-contract") {
  //     setActiveStepIndex(6);
  //   }
  // }, [activeStep]);

  useEffect(() => {
    if (activeStep === "personal-info") {
      setActiveStepIndex(1);
    } else if (activeStep === "truck-info") {
      setActiveStepIndex(2);
    } else if (activeStep === "bank-info") {
      setActiveStepIndex(3);
    } else if (activeStep === "phone-verify") {
      setActiveStepIndex(4);
    } else if (activeStep === "sign-contract") {
      setActiveStepIndex(5);
    }
  }, [activeStep]);

  // const useStyles = makeStyles(() => ({
  //   root: {
  //     "& .MuiStepIcon-active": { color: "red" },
  //     "& .MuiStepIcon-completed": { color: "green" },
  //     "& .Mui-disabled .MuiStepIcon-root": { color: "cyan" }
  //   }
  // }));

  // const c = useStyles();

  return (
    <div className="flex flex-col justify-between items-center gap-8 bg-[#F1F5F9] text-2xl px-2 pt-4">
      <div className="w-full h-auto flex flex-row justify-start items-center px-4">
        <button
          type="button"
          className="w-auto h-auto flex flex-row justify-center items-center gap-1 px-4 py-1 rounded-md bg-inherit uppercase text-red-600 border-2 border-red-600 text-sm font-medium"
          onClick={() => {
            document.cookie =
              "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            navigate("/login");
          }}
        >
          <TbLogout2 className="text-xl" />
          Exit
        </button>
      </div>
      <Box sx={{ width: "100%" }}>
        <Stepper
          activeStep={activeStepIndex}
          sx={{
            "& .MuiStepIcon-text": {
              fill: "#000",
            },
            "& .MuiStepConnector-line": {
              borderTopWidth: "3px",
            },
            "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
              borderColor: "#1976D2",
            },
            "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
              borderColor: "#1e9e25",
            },
            "& .MuiSvgIcon-root.Mui-completed": {
              backgroundColor: "#1e9e25",
              border: "solid 3px #1e9e25",
              borderRadius: "50px",
            },
            "& .MuiSvgIcon-root": {
              border: "solid 3px #aaaaaa",
              borderRadius: "50px",
            },
          }}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
};

export default CustomStepper;
