import axiosConfig from "../config/axiosConfig";
import Cookies from "js-cookie";

const fetchAdminNotes = async ({ setAdminNote, dispatch }) => {
  const token = Cookies.get("token");
  const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
  const endpoint = process.env.REACT_APP_NOTE_DEIVER;
  const axiosInstance = axiosConfig(baseURL, token);

  try {
    const response = await axiosInstance.get(endpoint);
    dispatch(setAdminNote(response.data.data.note));
  } catch (error) {
    console.error(error);
  }
};

export default fetchAdminNotes;
