import { useSelector } from "react-redux";
import { BsCheckAll } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const MessageCard = ({
  title,
  content,
  status,
  created_at,
  link,
  for_type,
}) => {
  const darkMode = useSelector((state) => state.darkMode);

  const [path, setPath] = useState(null);

  useEffect(() => {
    const determiningRoute = () => {
      if (for_type === "driver_notification_offer_message") {
         setPath(`/${link}`);
      }
    };

    determiningRoute()
  }, [for_type, link]);

  return (
    <Link
      to={path}
      className={`w-full h-auto flex flex-col justify-center items-start gap-1 ${
        darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
      } rounded-md p-4 cursor-default text-sm`}
    >
      <p className="text-[#a2a6ad] text-xs">{title}</p>
      <p className={`p-1 ${darkMode ? "text-white" : "text-black"}`}>
        {content}
      </p>
      <div className="flex flex-row justify-center items-center gap-1">
        <BsCheckAll
          className={`text-base ${
            status === "unseen" ? "text-[#a2a6ad]" : "text-green-500"
          }`}
        />
        <p className="text-[#a2a6ad] text-xs">{created_at}</p>
      </div>
    </Link>
  );
};

export default MessageCard;
