import { createSlice } from "@reduxjs/toolkit";

const initialState = null;

const adminNoteSlice = createSlice({
  name: "adminNote",
  initialState,
  reducers: {
    setAdminNote: (state, action) => {
      return action.payload;
    },
  },
});

export const { setAdminNote } = adminNoteSlice.actions;
export default adminNoteSlice.reducer;
