import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import DisplayStatusUser from "../../components/module/DisplayStatusUser";
import MobileMenu from "../../components/module/MobileMenu";
import FinancialReportDetails from "../../components/template/FinancialReportDetails";
import axiosConfig from "../../config/axiosConfig";
import Cookies from "js-cookie";

const FinancialReport = () => {
  const userId = Cookies.get("userId");
  const darkMode = useSelector((state) => state.darkMode);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (userId) {
        const token = Cookies.get("token");
        const baseURL = process.env.REACT_APP_FINANCIAL_REPORTS_BASE_URL;
        const endpoint = `/api/v1/user/${userId}/get-reports`;
        const axiosInstance = axiosConfig(baseURL, token);

        try {
          const response = await axiosInstance.get(endpoint);
          response.status === 200 && setData(response.data);
          console.log(response.data)
          setLoading(false)
        } catch (error) {
          console.error(error);
          setLoading(false)
        }
      }
    };
    fetchData();
  }, [userId]);

  return (
    <>
      <Helmet>
        <title>Account</title>
      </Helmet>
      <div
        className={`fixed w-full min-h-screen flex flex-col justify-start items-center ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <div className="w-full h-auto fixed top-0 left-0 right-0">
          <DisplayStatusUser />
        </div>
        <div className="w-full h-full pt-20 overflow-auto">
          <FinancialReportDetails data={data} loading={loading} />
        </div>
        <div className="fixed bottom-0 left-0 right-0 shadow-2xl">
          <MobileMenu />
        </div>
      </div>
    </>
  );
};

export default FinancialReport;
