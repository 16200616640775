import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import "../../../styles/select.css";

const SelectCompanyType = ({ title, onChange }) => {
  const [selected, setSelected] = useState("");

  const data = [
    {
      title: "Trust/estate",
      value: "trust_estate",
    },
    {
      title: "Partnership",
      value: "partnership",
    },
    {
      title: "S Corporation",
      value: "s_corporation",
    },
    {
      title: "C Corporation",
      value: "c_corporation",
    },
    {
      title: "Individual/sole proprietor",
      value: "individual_sole_proprietor",
    },
    {
      title: "Limited Liability Company (LLC)",
      value: "limited_liability_company_llc",
    },
  ];

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setSelected(selectedValue);
    onChange && onChange(selectedValue);
  };

  return (
    <div className="w-full h-full">
      <Select
        className="w-full h-full"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selected}
        onChange={handleChange}
        displayEmpty
        MenuProps={{
          PaperProps: {
            sx: {
              "& .MuiMenuItem-root": {
                padding: 2,
              },
            },
          },
        }}
      >
        <MenuItem id="defaultvalue" value="">
          {title}
        </MenuItem>
        {data &&
          data.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.title}
            </MenuItem>
          ))}
      </Select>
    </div>
  );
};

export default SelectCompanyType;
