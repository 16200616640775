import { Modal, Box, Button, Typography } from "@mui/material";

const UploadModal = ({ open, handleClose, handleConfirm }) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" component="h2">
          Permission Request
        </Typography>
        <Typography sx={{ mt: 2, fontSize: 14 }}>
          {" "}
          To provide the best experience with our app, we need access to your
          camera and photo library. This will enable you to easily upload and
          share images. Please grant permission to use your camera and access
          your photos while using the app.
        </Typography>
        <Box mt={2} display="flex" justifyContent="flex-end" gap={1}>
          <Button
            variant="contained"
            sx={{ bgcolor: "red" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ bgcolor: "#1976D2" }}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UploadModal;
