import { useSelector } from "react-redux";
import { format } from "date-fns";

const MessageBox = ({ messageBoxInfo }) => {
  const darkMode = useSelector((state) => state.darkMode);

  const formatDateCreate = (dateString) => {
    const formattedDate = format(new Date(dateString), "HH:mm MM/dd/yyyy");
    return formattedDate;
  };

  return (
    <div
      className={`w-full h-auto flex flex-col justify-start items-end gap-2 rounded-md p-4 overflow-auto shadow ${
        darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
      }`}
    >
      {messageBoxInfo.map((message) => (
        <div
          className={`w-auto h-auto flex flex-row justify-start items-center ${
            message.sender === "admin"
              ? " text-black ml-auto"
              : " text-black mr-auto"
          }`}
          key={message.id}
        >
          <div>
            {message.sender === "admin" ? (
              <div className="flex flex-row justify-center items-start gap-1 bg-gradient-to-l from-[#fbbf24] to-[#f0ac00] text-white py-2 px-3 rounded-l-md rounded-br-md shadow-md">
                <div className="flex flex-col justify-center items-end font-normal max-md:text-xs">
                  <span className="text-base">{message.message}</span>
                  <span className="text-base">
                    {"Our price is " + message.price}
                  </span>
                  <span className="w-full flex flex-row justify-start items-center text-xs text-black relative right-1 pt-2">
                    {formatDateCreate(message.created_at)}
                  </span>
                </div>
              </div>
            ) : (
              <div className="flex flex-row justify-center items-center gap-1 bg-gradient-to-l from-cyan-500 to-blue-500 text-white py-2 px-3 rounded-r-md rounded-bl-md shadow-md">
                <div className="flex flex-col justify-center items-start flex-wrap font-normal">
                  <div className="w-full h-auto text-base flex flex-row justify-start items-start flex-wrap">
                    <span className="break-all">{message.message}</span>
                  </div>
                  <span className="text-base">
                    {"Our price is " + message.price}
                  </span>
                  <span className="w-full flex flex-row justify-end items-center text-xs text-black pt-2">
                    {formatDateCreate(message.created_at)}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MessageBox;
