import { Route, Routes } from "react-router-dom";
import store from "./redux_toolkit/store";
import { Provider } from "react-redux";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/home/index";
import NotFound from "./pages/NotFound";
import Loads from "./pages/loads/index";
import Truck from "./pages/truck/index";
import Profile from "./pages/profile/index";
import Account from "./pages/profile/Account";
import LoadId from "./pages/loads/LoadId";
import Layout from "./components/Layout/Layout";
import Reports from "./pages/loads/reports";
import ReportId from "./pages/loads/reports/reportId";
import useScreenWakeLock from "./hooks/useScreenWakeLock";
import Bank from "./pages/profile/Bank";
import TruckId from "./pages/truck/TruckId";
import FilteredLoads from "./pages/home/FilteredLoads";
import useFirebaseConfigFetcher from "./hooks/useFirebaseConfigFetcher";
import FinancialReport from "./pages/profile/FinancialReport";
import Messages from "./pages/profile/Messages";

const App = () => {
  // localStorage.removeItem("phone");
  // localStorage.removeItem("access");

  useScreenWakeLock();
  useFirebaseConfigFetcher();

  return (
    <Provider store={store}>
      <Layout>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="/" element={<Home />} />
          <Route path="/filteredloads" element={<FilteredLoads />} />
          <Route path="truck" element={<Truck />} />
          <Route path="/truck/:id" element={<TruckId />} />
          <Route path="profile" element={<Profile />} />
          <Route path="/profile/account" element={<Account />} />
          <Route path="/profile/bank" element={<Bank />} />
          <Route path="/profile/financialreport" element={<FinancialReport />} />
          <Route path="/profile/messages" element={<Messages />} />
          <Route path="/loads" element={<Loads />} />
          <Route path="/load/:id" element={<LoadId />} />
          <Route path="/loads/reports" element={<Reports />} />
          <Route path="/loads/report/:id" element={<ReportId />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </Provider>
  );
};

export default App;
