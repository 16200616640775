import { useState } from "react";
import { Formik, Form } from "formik";
import PasswordField from "../../module/field/PasswordField";
import axiosConfig from "../../../config/axiosConfig";
import AboutMessage from "../../module/AboutMessage";
import { useNavigate } from "react-router-dom";
import PhoneNumberField from "../../module/field/PhoneNumberField";
import VerifyPhoneNumber from "../../module/modal/VerifyPhoneNumber";
import { CircularProgress } from "@mui/material";

const RegisterForm = ({ setActiveStep, showModal, setShowModal }) => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          phone: "",
          password: "",
          password_confirmation: "",
        }}
        validate={(values) => {
          const errors = {};
          setPhone(values.phone);
          if (!values.phone) {
            errors.phone = "Please enter your phone numbar";
          } else if (!/^\d+$/.test(values.phone)) {
            errors.phone = "The phone numbar must be numeric";
          }
          if (!values.password) {
            errors.password = "Please enter your password";
          } else if (!/(?=.*[A-Z])(?=.*\d)/.test(values.password)) {
            errors.password =
              "The password must contain at least one uppercase letter and one number";
          }

          if (!values.password_confirmation) {
            errors.password_confirmation = "Please enter your password";
          } else if (
            !/(?=.*[A-Z])(?=.*\d)/.test(values.password_confirmation)
          ) {
            errors.password_confirmation =
              "The password must be a combination of upper and lower case letters and numbers";
          }
          if (values.password !== values.password_confirmation) {
            errors.password_confirmation = "Do not match";
          }
          return errors;
        }}
        onSubmit={async (values) => {
          localStorage.setItem("phone", values.phone);
          setBtnLoading(true);
          const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
          const endpoint = process.env.REACT_APP_REGISTER_URL;
          const axiosInstance = axiosConfig(baseURL);

          try {
            const response = await axiosInstance.post(endpoint, values);
            response.data.status === "success" && setShowModal(true);
            setBtnLoading(false);
          } catch (error) {
            if (error.response) {
              const errorsObject = error.response.data.message;
              Object.entries(errorsObject).forEach(([key, errorMessages]) => {
                errorMessages.forEach((errorMessage) => {
                  AboutMessage({ status: "error", message: errorMessage });
                  if (errorMessage === "The email has already been taken.") {
                    navigate("/login");
                    return;
                  }
                });
              });
            }
            setBtnLoading(false);
          }
        }}
      >
        {() => (
          <Form className="w-6/12 h-auto flex flex-col gap-2 font-medium max-sm:w-10/12 max-md:w-8/12">
            <PhoneNumberField
              name="phone"
              minLength="0"
              maxLength="255"
              placeholder="Phone numbar"
            />
            <PasswordField
              name="password"
              minLength="6"
              maxLength="255"
              placeholder="Password"
            />
            <PasswordField
              name="password_confirmation"
              minLength="8"
              maxLength="255"
              placeholder="Confirm password"
            />
            <div className="w-full h-auto flex flex-col gap-2 text-base pt-10 text-white font-normal">
              <button
                type="submit"
                className="w-full h-auto flex flex-row justify-center items-center gap-1 bg-gradient-to-l from-amber-500 to-amber-400 rounded-md p-2 max-sm:text-xs"
              >
                {btnLoading ? (
                  <CircularProgress size={16} sx={{ color: "black" }} />
                ) : (
                  "Register"
                )}
              </button>
              <button
                type="button"
                className="flex flex-row justify-start items-center gap-1 font-normal text-base text-white max-md:text-sm max-sm:text-xs"
                onClick={() => navigate("/login")}
              >
                <span>Have an account ?</span>
                <span className="text-amber-400">Login here</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {showModal && (
        <VerifyPhoneNumber
          phone={phone}
          setShowModal={setShowModal}
          otp={otp}
          setOtp={setOtp}
          setActiveStep={setActiveStep}
        />
      )}
    </>
  );
};

export default RegisterForm;
