import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDarkMode } from "../redux_toolkit/features/darkModeSlice";

const useDarkMode = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const initializeDarkMode = async () => {
      const darkModeLocalStorage = localStorage.getItem("darkMode");
      if (darkModeLocalStorage) {
        dispatch(setDarkMode(JSON.parse(darkModeLocalStorage)));
      }
    };
    initializeDarkMode();
  }, [dispatch]);
};

export default useDarkMode;
