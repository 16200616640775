import { useState, useEffect } from "react";
import styles from "../../../styles/styles.module.css";
import DefaultField from "../../module/field/DefaultField";
import { Formik, Form, ErrorMessage } from "formik";
import Checkbox from "@mui/material/Checkbox";
import SelectCountryCode from "../../module/select/SelectCountryCode";
import axiosConfig from "../../../config/axiosConfig";
import Cookies from "js-cookie";
import AboutMessage from "../../module/AboutMessage";
import CircularProgress from "@mui/material/CircularProgress";
import fetchCountryCode from "../../../utils/fetchCountryCode";
import Spinner from "../../module/Spinner";
import ScreenProtector from "../../module/modal/ScreenProtector";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi2";
import { Typography, Popover } from "@mui/material";
import SelectCompanyType from "../../module/select/SelectCompanyType";
import ImageUploader from "../../module/ImageUploader";
import MaskedInput from "react-maskedinput";

const PersonalInfoForm = ({ setActiveStep }) => {
  const [countryCode, setCountryCode] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [screenProtector, setScreenProtector] = useState(false);
  const [driverLicenceImage, setDriverLicenceImage] = useState([]);

  useEffect(() => {
    fetchCountryCode({ setCountryCode });
    setLoading(false);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "backup-number-popover" : undefined;

  if (loading) {
    return (
      <div className="h-screen w-full flex flex-col justify-center items-center bg-[#F1F5F9]">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col justify-start items-center bg-[#F1F5F9] text-black font-normal py-8">
      <div className="w-11/12 h-auto flex flex-col justify-center items-start gap-10 bg-[#E2E8F0] rounded-md p-8">
        <div className="flex flex-row justify-center items-end gap-2">
          <h1 className="text-xl uppercase cursor-default max-sm:text-sm">
            Register
          </h1>
          <p className="text-xl text-[#1976D2] cursor-default uppercase max-sm:text-sm">
            Personal Info
          </p>
        </div>
        <Formik
          initialValues={{
            first_name: "",
            middle_name: "",
            last_name: "",
            email: "",
            country_code: "",
            phone_number: "",
            backup_number: "",
            expiration: "",
            driver_licence: "",
            driver_licence_image: "",
            company: false,
            citizenship_ssn: "",
            name: "",
            type: "",
            citizenship_ein: "",
            registration_number: "",
            web_site: "",
            tax_indentification_number: "",
          }}
          validate={(values) => {
            const errors = {};

            if (!values.first_name) {
              errors.first_name = "Please enter your first name";
            } else if (!/^[A-Za-z\s]+$/.test(values.first_name)) {
              errors.first_name =
                "Should only contain Latin letters a - z - A - Z";
            }
            if (
              values.middle_name &&
              !/^[A-Za-z0-9]+$/.test(values.middle_name)
            ) {
              errors.middle_name =
                "should only contain Latin letters a - z - A - Z";
            }
            if (!values.last_name) {
              errors.last_name = "Please enter your last name";
            } else if (!/^[A-Za-z\s]+$/.test(values.last_name)) {
              errors.last_name =
                "Should only contain Latin letters a - z - A - Z";
            }
            if (!values.email) {
              errors.email = "Please enter your email";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "The email address is invalid";
            }
            if (!values.country_code) {
              errors.country_code = "Please select your country code";
            }
            if (!values.phone_number) {
              errors.phone_number = "Please enter your phone";
            } else if (!/^[0-9]+$/.test(values.phone_number)) {
              errors.phone_number = "should only contain numbers 0 - 9";
            }

            if (!values.backup_number) {
              errors.backup_number = "Please enter your backup number";
            } else if (!/^[0-9]+$/.test(values.backup_number)) {
              errors.backup_number = "should only contain numbers 0 - 9";
            }
            if (
              values.citizenship_ssn &&
              !/^[A-Za-z0-9]+$/.test(values.citizenship_ssn)
            ) {
              errors.citizenship_ssn =
                "should only contain Latin letters a - z - A - Z - 0 - 9";
            }
            if (!values.driver_licence) {
              errors.driver_licence = "Please enter driver licence number";
            } else if (!/^[A-Za-z0-9]+$/.test(values.driver_licence)) {
              errors.driver_licence =
                "Driver licence number must contain only letters and numbers";
            }
            if (!values.expiration) {
              errors.expiration = "Please enter expiration";
            }
            if (values.company === true) {
              if (!values.name) {
                errors.name = "Please enter your company name";
              } else if (!/^[A-Za-z\s]+$/.test(values.name)) {
                errors.name = "should only contain Latin letters a - z - A - Z";
              }
              if (!values.type) {
                errors.type = "Please select your company type";
              }
              if (
                values.citizenship_ein &&
                !/^[A-Za-z0-9]+$/.test(values.citizenship_ein)
              ) {
                errors.citizenship_ein =
                  "should only contain Latin letters a - z - A - Z - 0 - 9";
              }

              if (!values.registration_number) {
                errors.registration_number = "Please enter registration number";
              } else if (!/^[0-9]+$/.test(values.registration_number)) {
                errors.registration_number =
                  "should only contain numbers 0 - 9";
              }
            }

            return errors;
          }}
          onSubmit={async (values) => {
            try {
              values.driver_licence_image = driverLicenceImage;

              if (values.driver_licence_image.length === 0) {
                AboutMessage({
                  status: "error",
                  message: "Please upload file driver licence image",
                });
                return;
              }

              setBtnLoading(true);
              setScreenProtector(true);
              const token = Cookies.get("token");
              const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
              const endpoint = process.env.REACT_APP_PERSONAL_INFO_STORE_URL;
              const axiosInstance = axiosConfig(baseURL, token);

              const config = {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              };

              if (values.company === false) {
                const updatedValues = {
                  ...values,
                  company: "no",
                  name: "",
                  citizenship_ein: "",
                  registration_number: "",
                  web_site: "",
                  tax_indentification_number: "",
                };

                const response = await axiosInstance.post(
                  endpoint,
                  updatedValues,
                  config
                );
                response.status === 200 && setActiveStep("truck-info");
              } else {
                const updatedValues = {
                  ...values,
                  company: "yes",
                  citizenship_ssn: "",
                };

                const response = await axiosInstance.post(
                  endpoint,
                  updatedValues,
                  config
                );
                if (response.status === 200) {
                  setActiveStep("truck-info");
                }
              }
            } catch (error) {
              if (error.response) {
                const errors = error.response.data.message;
 
                AboutMessage({
                  status: "error",
                  message: errors,
                });
              }
            } finally {
              setBtnLoading(false);
              setScreenProtector(false);
            }
          }}
        >
          {({ setFieldValue, values }) => (
            <Form className="w-full h-auto flex flex-col gap-2 font-medium">
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="first_name"
                minLength="2"
                maxLength="255"
                placeholder="First Name"
                // disabled={personalInfo.first_name !== null}
              />

              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="middle_name"
                minLength="2"
                maxLength="255"
                placeholder="Middle Name"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="last_name"
                minLength="2"
                maxLength="255"
                placeholder="Last Name"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="email"
                name="email"
                minLength="0"
                maxLength="255"
                placeholder="Email"
              />
              <SelectCountryCode
                //title="Country Code"
                name="country_code"
                data={countryCode}
                onChange={(selectedValue) =>
                  setFieldValue("country_code", selectedValue)
                }
              />
              <ErrorMessage
                name="country_code"
                component="div"
                className="w-full h-auto text-sm text-red-600 font-normal max-sm:text-xs"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="phone_number"
                minLength="2"
                maxLength="255"
                placeholder="Phone"
              />
              <div className="relative">
                <DefaultField
                  stylefield={styles.stylefield}
                  type="text"
                  name="backup_number"
                  minLength="2"
                  maxLength="255"
                  placeholder="Backup Phone"
                />
                <HiOutlineQuestionMarkCircle
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  className="text-black absolute top-[13px] right-[11px] max-sm:text-lg cursor-pointer"
                />
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                >
                  <Typography sx={{ p: 2, fontSize: "0.80rem" }}>
                    This number will be called only in case of emergency.
                  </Typography>
                </Popover>
              </div>
              {!values.company && (
                <DefaultField
                  stylefield={styles.stylefield}
                  type="text"
                  name="citizenship_ssn"
                  minLength="2"
                  maxLength="255"
                  placeholder="Citizenship Status (Optional)"
                />
              )}
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="driver_licence"
                minLength="0"
                maxLength="255"
                placeholder="Driver Licence Number"
              />
              <MaskedInput
                id="datefield"
                className={styles.stylefield}
                mask="11/11/1111"
                placeholder="Expiration mm/dd/yyyy"
                name="expiration"
                value="expiration"
                onChange={(e) => {
                  const result = e.target.value.replace(/\D/g, "-");
                  setFieldValue("expiration", result);
                }}
              />
              <ErrorMessage
                name="expiration"
                component="div"
                className="w-full h-auto text-sm text-red-600 font-normal max-sm:text-xs"
              />
              <ImageUploader
                title={"Driver Licence Image"}
                selectedFiles={driverLicenceImage}
                setSelectedFiles={setDriverLicenceImage}
              />
              <div className="flex flex-row justify-start items-center text-white gap-1">
                <Checkbox
                  id="companyCheckbox"
                  checked={values.company}
                  onChange={() => {
                    setFieldValue("company", !values.company);
                  }}
                  sx={{ padding: "0.1rem" }}
                  size="small"
                />
                <label
                  htmlFor="companyCheckbox"
                  className="cursor-default font-normal text-base text-black max-sm:text-xs"
                >
                  Company
                </label>
              </div>
              {values.company && (
                <div className="w-full h-auto flex flex-col gap-2">
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="name"
                    minLength="2"
                    maxLength="255"
                    placeholder="Company Name"
                  />
                  <SelectCompanyType
                    title="Company Type"
                    name="type"
                    onChange={(selectedValue) =>
                      setFieldValue("type", selectedValue)
                    }
                  />
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="w-full h-auto text-sm text-red-600 font-normal max-sm:text-xs"
                  />
                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="citizenship_ein"
                    minLength="2"
                    maxLength="255"
                    placeholder="Citizenship Status (Optional)"
                  />

                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="registration_number"
                    minLength="2"
                    maxLength="255"
                    placeholder="Registration Number"
                  />

                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="web_site"
                    minLength="2"
                    maxLength="255"
                    placeholder="Website"
                  />

                  <DefaultField
                    stylefield={styles.stylefield}
                    type="text"
                    name="tax_indentification_number"
                    minLength="2"
                    maxLength="255"
                    placeholder="Tax Indentification Number"
                  />
                </div>
              )}
              <div className="w-full h-auto flex flex-row justify-end items-center pt-10">
                <button
                  type="submit"
                  className="w-full h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                >
                  {btnLoading ? (
                    <CircularProgress size={16} sx={{ color: "black" }} />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {screenProtector && <ScreenProtector />}
    </div>
  );
};

export default PersonalInfoForm;
