import { format } from "date-fns";
import { useSelector } from "react-redux";

const BankCard = ({
  name,
  account_type,
  account_number,
  routing_number,
  status_bank,
  created_at,
  galleries,
}) => {

  const darkMode = useSelector((state) => state.darkMode);

  const formatDateCreate = (dateString) => {
    const formattedDate = format(new Date(dateString), "dd/MM/yyyy");
    return formattedDate;
  };

  return (
    <div
      className={`w-full h-auto flex flex-col justify-start items-start gap-4 p-4 rounded-md font-normal ${
        darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
      }`}
    >
      <div className="w-full h-full flex flex-row justify-between items-start">
        <div className="w-1/2 flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Name
          </p>
          <p>{name}</p>
        </div>
        <div className="w-1/2 flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Account type
          </p>
          <p>{account_type}</p>
        </div>
      </div>
      <div className="w-full h-full flex flex-row justify-between items-start">
        <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="w-1/2 text-[#a2a6ad] uppercase max-md:text-xs">
            Account number
          </p>
          <p>{account_number}</p>
        </div>
        <div className="w-1/2 flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Routing number
          </p>
          <p>{routing_number}</p>
        </div>
      </div>
      <div className="w-full h-full flex flex-row justify-between items-start">
        <div className="w-1/2 flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            status bank
          </p>
          <p>{status_bank}</p>
        </div>
        <div className="w-1/2 flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Create
          </p>
          <p>{formatDateCreate(created_at)}</p>{" "}
        </div>
      </div>
      <div className="w-full h-auto flex flex-row justify-start items-center gap-1 flex-wrap pt-2">
        {galleries.map((index) => {
          return (
            <img
              className="h-20 w-20 rounded-md"
              key={index.id}
              src={`${process.env.REACT_APP_REGISTER_BASE_URL}/${index?.image}`}
              alt={index.name}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BankCard;
