import { useEffect } from "react";
import { Helmet } from "react-helmet";
import MobileMenu from "../../components/module/MobileMenu";
import DisplayStatusUser from "../../components/module/DisplayStatusUser";
import { useSelector } from "react-redux";
import { useState } from "react";
import useCheckRegistration from "../../hooks/useCheckRegistration";
import ListTruckCards from "../../components/template/ListTruckCards";
import fetchUserInfo from "../../utils/fetchUserInfo";

const Truck = () => {
  const darkMode = useSelector((state) => state.darkMode);
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useCheckRegistration({ setChecked });

  useEffect(() => {
    fetchUserInfo({ setData, setLoading });
  }, []);

  return (
    <>
      <Helmet>
        <title>Truck</title>
      </Helmet>
      <div
        className={`w-full min-h-screen flex flex-col justify-start items-center ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <div className="w-full h-auto flex flex-col justify-start items-center gap-2 pb-28">
          <div className="w-full h-auto fixed top-0 left-0 right-0">
            <DisplayStatusUser />
          </div>
          <div className="w-full h-full pt-20">
            <ListTruckCards data={data} checked={checked} loading={loading} />
          </div>
        </div>
        <div className="fixed bottom-0 left-0 right-0">
          <MobileMenu />
        </div>
      </div>
    </>
  );
};

export default Truck;
