import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Cookies from "js-cookie";
import DesktopNavber from "../../components/module/DesktopNavber";
import MobileMenu from "../../components/module/MobileMenu";
import { useSelector } from "react-redux";
import axiosConfig from "../../config/axiosConfig";
import ListLoadCards from "../../components/template/ListLoadCards";
import DisplayStatusUser from "../../components/module/DisplayStatusUser";
import useCheckRegistration from "../../hooks/useCheckRegistration";
import { PulseLoader } from "react-spinners";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import FetchUserId from "../../utils/fetchUserId";
import Pusher from "pusher-js";

const Loads = () => {
  const darkMode = useSelector((state) => state.darkMode);
  // const refetchLoads = useSelector((state) => state.refetchLoads);
  const [userId, setUserId] = useState(null);
  const [data, setData] = useState([]);
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(true);
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [refetchLoads, setRefetchLoads] = useState(1);

  useCheckRegistration({ setChecked });
  FetchUserId({ userId, setUserId });

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_LOADAL_BASE_URL;
      const endpoint = `/api/v1/user/${userId}/get-loads?page=${currentPage}`;
      const axiosInstance = axiosConfig(baseURL, token);
      if (userId !== null) {
        try {
          const response = await axiosInstance.get(endpoint);
          if (response.status === 200) {
            const { data, last_page } = response.data.data;

            setData(data);
            setPages(last_page);
            setLoading(false);
          }
        } catch (error) {
          console.error(error);
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [currentPage, userId, refetchLoads]);

  useEffect(() => {
    const pusherOptionsCookie = Cookies.get("pusherOptions");
    const pusherOptionsParse = JSON.parse(pusherOptionsCookie);

    const PUSHER_APP_KEY = pusherOptionsParse.PUSHER_APP_KEY;
    const PUSHER_APP_CLUSTER = pusherOptionsParse.PUSHER_APP_CLUSTER;

    const initializePusher = async () => {
      Pusher.logToConsole = false;

      const pusher = new Pusher(PUSHER_APP_KEY, {
        cluster: PUSHER_APP_CLUSTER,
      });

      const channel = pusher.subscribe(`atoz-front-channel-${userId}`);

      channel.bind("atoz-front-newLoad-event", function (data) {
        setRefetchLoads((prevRefetchLoads) => prevRefetchLoads + 1);
      });

      return () => {
        channel.unbind_all();
        channel.unsubscribe();
      };
    };
    initializePusher();
  }, [userId]);

  const handleChange = (event, value) => {
    if (value > currentPage) {
      setCurrentPage((prevPage) => Math.min(prevPage + 1, pages));
    } else {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    }
  };

  const loadingComponents = () => (
    <div
      className={`h-screen w-full flex flex-col justify-center items-center pt-[5.5rem] ${
        darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
      }`}
    >
      <PulseLoader color="#fbbf24" />
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Loads</title>
      </Helmet>
      <div className="w-full h-screen flex flex-col justify-start items-center">
        <DesktopNavber />
        <div
          className={`w-full h-auto flex flex-col justify-between items-center font-normal overflow-hidden ${
            darkMode ? "bg-gray-900 text-white" : "bg-[#F1F5F9] text-black"
          }`}
        >
          <div className="w-full h-auto flex flex-col justify-start items-center pb-28 overflow-auto">
            <div className="w-full h-auto fixed top-0 left-0 right-0">
              <DisplayStatusUser />
            </div>
            {checked || loading ? (
              loadingComponents()
            ) : (
              <div className="w-full h-auto flex flex-col justify-center items-center gap-8 pt-20">
                <ListLoadCards data={data} />
                <Stack spacing={2}>
                  <Pagination
                    count={pages}
                    page={currentPage}
                    onChange={handleChange}
                  />
                </Stack>
              </div>
            )}
          </div>
          <div className="fixed bottom-0 left-0 right-0">
            <MobileMenu />
          </div>
        </div>
      </div>
    </>
  );
};

export default Loads;
