import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axiosConfig from "../../../config/axiosConfig";
import Cookies from "js-cookie";
import AboutMessage from "../../../components/module/AboutMessage";
import ReportDetail from "../../../components/template/ReportDetail";
import MobileMenu from "../../../components/module/MobileMenu";
import DisplayStatusUser from "../../../components/module/DisplayStatusUser";

const ReportId = () => {
  let { id } = useParams();
  const darkMode = useSelector((state) => state.darkMode);
  const [data, setData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = "/api/v1/user/my-load/report/";
      const axiosInstance = axiosConfig(baseURL, token);

      try {
        const response = await axiosInstance.get(endpoint + id);
        if (response.status === 200) {
          setData(response.data.data);
        }
      } catch (error) {
        AboutMessage({ status: "error", message: error.message, darkMode });
        if (error.status === 429) {
          AboutMessage({
            status: "error",
            message: error.response.message,
            darkMode,
          });
        }
      }
    };

    fetchData();
  }, [id, darkMode]);

  return (
    <>
      <Helmet>
        <title>Report details</title>
      </Helmet>
      <div
        className={`w-full h-screen p-4 overflow-auto ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <div className="w-full h-auto fixed top-0 left-0 right-0 z-10 ">
          <DisplayStatusUser />
        </div>
        <div className="w-full h-auto flex flex-col justify-start items-center gap-2 pb-20 pt-16 overflow-auto">
          <ReportDetail data={data} darkMode={darkMode} />
        </div>
        <div className="fixed bottom-0 left-0 right-0">
          <MobileMenu />
        </div>
      </div>
    </>
  );
};

export default ReportId;
