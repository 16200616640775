import { configureStore } from "@reduxjs/toolkit";
import darkModeReducer from "./features/darkModeSlice";
import statusUserReducer from "./features/statusUserSlice";
import unConfirmedStepReducer from "./features/unConfirmedStepSlice";
import statusRegisterReducer from "./features/statusRegisterSlice";
import adminNoteReducer from "./features/adminNoteSlice";
import messageBoxInfoReducer from "./features/messageBoxInfoSlice";
import refetchLoadsReducer from "./features/refetchLoadsSlice";
import messageListReducer from "./features/messageListSlice";
import refetchMessagesReducer from "./features/refetchMessagesSlice";

const store = configureStore({
  reducer: {
    darkMode: darkModeReducer,
    statusUser: statusUserReducer,
    unConfirmedStep: unConfirmedStepReducer,
    statusRegister: statusRegisterReducer,
    adminNote: adminNoteReducer,
    messageBoxInfo: messageBoxInfoReducer,
    refetchLoads: refetchLoadsReducer,
    messageList: messageListReducer,
    refetchMessages: refetchMessagesReducer,
  },
});

export default store;
