import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import AboutMessage from "../../module/AboutMessage";
import { useNavigate } from "react-router-dom";
import axiosConfig from "../../../config/axiosConfig";
import Cookies from "js-cookie";
import { CircularProgress } from "@mui/material";
import ScreenProtector from "../../module/modal/ScreenProtector";

const SignContract = () => {
  const navigate = useNavigate();
  const [signContract, setSignContract] = useState("0");
  const [terms, setTerms] = useState("0");
  const [btnLoading, setBtnLoading] = useState(false);
  const [screenProtector, setScreenProtector] = useState(false);

  const submit = async () => {
    if (signContract === "0") {
      AboutMessage({ status: "error", message: "Select sign contract" });
      return;
    }
    if (terms === "0") {
      AboutMessage({ status: "error", message: "Select terms" });
      return;
    }
    setBtnLoading(true);
    setScreenProtector(true);
    const token = Cookies.get("token");
    const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
    const endpoint = process.env.REACT_APP_SIGN_CONTRACT_URL;
    const axiosInstance = axiosConfig(baseURL, token);

    const values = {
      sign_contract: signContract,
      terms: terms,
    };

    try {
      const response = await axiosInstance.post(endpoint, values);
      if (response.status === 200) {
        navigate("/");
      }
    } catch (error) {
      if (error.response) {
        const errorsObject = error.response.data.message;
        Object.entries(errorsObject).forEach(([key, errorMessages]) => {
          errorMessages.forEach((errorMessage) => {
            AboutMessage({ status: "error", message: errorMessage });
          });
        });
      } else {
        console.log(error.message);
      }
    } finally {
      setBtnLoading(false);
      setScreenProtector(false);
    }
  };

  return (
    <div className="w-full h-screen flex flex-col justify-start items-center bg-[#F1F5F9] text-black font-normal pt-8">
      <div className="w-11/12 h-auto flex flex-col justify-center items-start gap-10 bg-[#E2E8F0] rounded-md p-8">
        <div className="flex flex-row justify-center items-end gap-2">
          <h1 className="text-xl uppercase max-sm:text-sm">Register</h1>
          <p className="text-xl text-[#1976D2] uppercase max-sm:text-sm">
            Sign Contract
          </p>
        </div>
        <div className="w-full h-auto flex flex-col gap-2 font-medium">
          <div className="flex flex-row justify-start items-center text-white gap-1">
            <Checkbox
              id="signContractCheckbox"
              value={signContract}
              onChange={() => setSignContract(signContract === "0" ? "1" : "0")}
              sx={{ padding: "0.1rem" }}
              size="small"
            />
            <label
              htmlFor="signContractCheckbox"
              className="cursor-default text-base text-black max-sm:text-xs"
            >
              I have read and accept the contract
            </label>
          </div>
          <div className="flex flex-row justify-start items-center text-white gap-1">
            <Checkbox
              id="termsCheckbox"
              value={terms}
              onChange={() => setTerms(terms === "0" ? "1" : "0")}
              sx={{ padding: "0.1rem" }}
              size="small"
            />
            <label
              htmlFor="termsCheckbox"
              className="cursor-default text-base text-black max-sm:text-xs"
            >
              I agree to all Terms
            </label>
          </div>
          <div className="w-full h-auto flex flex-row justify-end items-center gap-1 pt-10">
            <button
              type="submit"
              className="w-1/2 h-auto px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
              onClick={submit}
            >
              {btnLoading ? (
                <CircularProgress size={16} sx={{ color: "black" }} />
              ) : (
                "Finish"
              )}
            </button>
          </div>
        </div>
      </div>
      {screenProtector && <ScreenProtector />}
    </div>
  );
};

export default SignContract;
