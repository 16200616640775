const InfoDisplayBox = ({ title, data, setSelected, setShowModal }) => {
  const handleCardClick = (index) => {
    setSelected(index);
    setShowModal(true);
  };

  return (
    <div className="pb-8">
      <div className="bg-[#F1F5F9] flex flex-col justify-start items-start gap-2 rounded-md p-2">
        <p className="text-[#000] font-normal max-md:text-sm">{title}</p>
        {data.map((index) => (
          <div
            key={index.id}
            className="w-full h-auto flex flex-row justify-between items-end gap-2 p-3 rounded-md bg-[#E2E8F0]"
            onClick={() => handleCardClick(index)}
          >
            <p className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
              {index.created_at}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoDisplayBox;
