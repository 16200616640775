import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import ListBankCards from "../../components/template/ListBankCards";
import fetchUserInfo from "../../utils/fetchUserInfo";
import MobileMenu from "../../components/module/MobileMenu";
import DisplayStatusUser from "../../components/module/DisplayStatusUser";

const Bank = () => {
  const darkMode = useSelector((state) => state.darkMode);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserInfo({ setData, setLoading });
  }, []);

  return (
    <>
      <Helmet>
        <title>Bank</title>
      </Helmet>
      <div
        className={`fixed w-full max-h-screen flex flex-col justify-start items-center ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <div className="w-full h-auto fixed top-0 left-0 right-0">
          <DisplayStatusUser />
        </div>
        <div className="w-full h-full pt-20 overflow-auto">
          <ListBankCards loading={loading} data={data} />
        </div>
        <div className="fixed bottom-0 left-0 right-0 shadow-2xl">
          <MobileMenu />
        </div>
      </div>
    </>
  );
};

export default Bank;
