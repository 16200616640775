import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import HomeDetails from "../../components/template/HomeDetails";
import MobileMenu from "../../components/module/MobileMenu";
import DisplayStatusUser from "../../components/module/DisplayStatusUser";
import { useSelector } from "react-redux";
import useCheckRegistration from "../../hooks/useCheckRegistration";
import axiosConfig from "../../config/axiosConfig";
import Cookies from "js-cookie";
import useUserInfoFetcher from "../../hooks/useUserInfoFetcher";

const Home = () => {
  const darkMode = useSelector((state) => state.darkMode);
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loadingFetchLoad, setLoadingFetchLoad] = useState(true);
  const [data, setData] = useState(null);
  const [listAcceptedLoads, setListAcceptedLoads] = useState([]);

  useCheckRegistration({ setChecked });
  useUserInfoFetcher({ setData, setLoading });

  useEffect(() => {
    const userId = Cookies.get("userId");
    if (userId !== null) {
      const fetchLoads = async () => {
        const token = Cookies.get("token");
        const baseURL = process.env.REACT_APP_LOADAL_BASE_URL;
        const endpoint = `/api/v1/user/${userId}/get-loads?page=1&status=transit`;

        const axiosInstance = axiosConfig(baseURL, token);

        try {
          const res = await axiosInstance.get(endpoint);
          if (res.status === 200) {
            setListAcceptedLoads(res.data.data.data);
            setLoadingFetchLoad(false);
          }
        } catch (error) {
          console.error(error);
        }
      };
      fetchLoads();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div
        className={`w-full h-auto flex flex-col justify-start items-center ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <div className="w-full h-auto fixed top-0 left-0 right-0 z-20">
          <DisplayStatusUser />
        </div>
        <div className="w-full h-auto pt-20 pb-[6.5rem] z-10">
          <HomeDetails
            checked={checked}
            loading={loading}
            data={data}
            loadingFetchLoad={loadingFetchLoad}
            listAcceptedLoads={listAcceptedLoads}
          />
        </div>
        <div className="fixed bottom-0 left-0 right-0 z-20">
          <MobileMenu />
        </div>
      </div>
    </>
  );
};

export default Home;
