import { useSelector } from "react-redux";
import BankCard from "../module/card/BankCard";
import { PulseLoader } from "react-spinners";

const ListBankCards = ({ data, loading }) => {
  const darkMode = useSelector((state) => state.darkMode);

  if (loading) {
    return (
      <div
        className={`fixed h-screen w-full flex flex-col justify-center items-center pb-[11.5rem] ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <PulseLoader color="#fbbf24" />
      </div>
    );
  }

  return (
    <div
      className={`w-full h-full flex flex-col justify-center items-start cursor-default font-normal text-sm px-4 ${
        darkMode ? "bg-gray-900 text-white" : "bg-[#F1F5F9] text-black"
      }`}
    >
      <div className="w-full h-full flex flex-col justify-center items-start gap-3 pb-[6.5rem]">
        {data.banks.map((index) => {
          return <BankCard key={index.id} {...index} />;
        })}
      </div>
    </div>
  );
};

export default ListBankCards;
