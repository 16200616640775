import axiosConfig from "../config/axiosConfig";
import Cookies from "js-cookie";

const fetchTruckType = async ({ setTruckType }) => {
  const token = Cookies.get("token");
  const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
  const endpoint = process.env.REACT_APP_GET_TRUCK_TYPE;
  const axiosInstance = axiosConfig(baseURL, token);

  try {
    const response = await axiosInstance.get(endpoint);
    response.status === 200 && setTruckType(response.data.data);
  } catch (error) {
    console.error(error);
  }
};

export default fetchTruckType;
