import { Link } from "react-router-dom";
import { format } from "date-fns";
import { FaCircle } from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";
import { HiLocationMarker } from "react-icons/hi";
import { BsCurrencyDollar } from "react-icons/bs";

const LoadCard = ({
  id,
  created_at,
  status,
  load_pick_up,
  load_deliver_to,
  price,
  darkMode,
}) => {
  const formattedStatus =
    status.charAt(0).toUpperCase() + status.slice(1).toLowerCase();

  const formatDateCreate = (dateString) => {
    const formattedDate = format(new Date(dateString), "dd/MM/yyyy");
    return formattedDate;
  };

  return (
    <Link
      to={`/load/${id}`}
      className={`w-4/12 h-auto flex flex-col justify-center items-start gap-4 rounded-md p-4 cursor-pointer font-normal text-sm max-md:w-full ${
        darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
      }`}
    >
      <div className="w-full h-auto flex flex-row justify-between items-center ">
        <p className="cursor-default flex flex-row justify-center items-center gap-1 text-[#a2a6ad] max-md:text-sm">
          Posted on
          <span>{formatDateCreate(created_at)}</span>{" "}
        </p>
        <FaAngleRight className="text-[#a2a6ad]" />
      </div>
      <div className="w-full h-auto flex flex-col justify-between items-start font-normal max-sm:gap-1">
        <div className="flex flex-row justify-between items-center gap-2">
          <HiLocationMarker className="text-[#a2a6ad] text-lg" />
          <p className="text-base cursor-default max-md:text-sm">
            {load_pick_up}
          </p>
        </div>
        <div className="flex flex-row justify-start items-center gap-2">
          <HiLocationMarker className="text-[#a2a6ad] text-lg" />
          <p className="text-base cursor-default max-md:text-sm">
            {load_deliver_to}
          </p>
        </div>
      </div>
      <div className="w-full flex flex-row justify-between items-center pl-[2px]">
        <div className="flex flex-row justify-center items-center gap-2 cursor-default font-normal max-md:text-sm">
          <FaCircle
            className={`text-xs ${
              status === "reject" || status === "missed"
                ? "text-red-600"
                : status === "accept" || status === "delivered"
                ? "text-green-600"
                : "text-yellow-500"
            }`}
          />
          <span
            className={`${
              status === "reject" || status === "missed"
                ? "text-red-600"
                : status === "accept" || status === "delivered"
                ? "text-green-600"
                : "text-yellow-500"
            }`}
          >
            {formattedStatus}
          </span>
        </div>
        <div className="flex flex-row justify-start items-center gap-2">
          <p className="flex flex-row justify-start items-center cursor-default font-normal max-md:text-sm">
            <BsCurrencyDollar />
            {price}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default LoadCard;
