import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { HiLocationMarker } from "react-icons/hi";
import { FaAngleRight } from "react-icons/fa6";
import { format } from "date-fns";
import { BsCurrencyDollar } from "react-icons/bs";
import { v4 as uuidv4 } from "uuid";
import { GoInbox } from "react-icons/go";

const AcceptedBoxLoads = ({ listAcceptedLoads }) => {
  const darkMode = useSelector((state) => state.darkMode);

  const formatDateCreate = (dateString) => {
    const formattedDate = format(new Date(dateString), "dd/MM/yyyy");
    return formattedDate;
  };

  return (
    <div
      className={`w-full h-full flex flex-col justify-start items-center font-normal ${
        darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
      }`}
    >
      <div
        className={`w-full h-auto flex flex-col justify-start items-center gap-6 rounded-md p-8 max-md:p-6 max-sm:p-4 ${
          darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
        }`}
      >
        <h1 className="w-full h-auto text-xl font-normal uppercase max-sm:text-xl">
          Accepted Loads
        </h1>
        <div className="w-full h-full flex flex-row justify-center items-center flex-wrap gap-1 ">
          {listAcceptedLoads.length === 0 ? (
            <div
              className={`w-full h-auto flex flex-col justify-center items-center gap-2 rounded-md p-4 ${
                darkMode ? "bg-gray-900 text-white" : "bg-[#F1F5F9] text-black"
              }`}
            >
              <GoInbox className="text-3xl" />
              <p className="cursor-default text-base max-md:text-sm max-sm:text-xs">
              There are no accepted loads.
              </p>
            </div>
          ) : (
            listAcceptedLoads.slice(0, 2).map((index) => (
              <Link
                key={uuidv4()}
                to={`/load/${index.id}`}
                className={`w-4/12 h-auto flex flex-col justify-center items-start gap-4 rounded-md p-4 cursor-pointer text-sm max-md:w-full ${
                  darkMode
                    ? "bg-gray-900 text-white"
                    : "bg-[#F1F5F9] text-black"
                }`}
              >
                <div className="w-full h-auto flex flex-row justify-between items-center ">
                  <p className="cursor-default flex flex-row justify-center items-center gap-1 text-[#a2a6ad] max-md:text-sm">
                    Posted on
                    <span>{formatDateCreate(index.created_at)}</span>{" "}
                  </p>
                  <FaAngleRight className="text-[#a2a6ad]" />
                </div>
                <div className="w-full h-auto flex flex-row justify-start items-end font-normal">
                  <div className="w-8/12 h-auto flex flex-col justify-between items-start">
                    <div className="flex flex-row justify-between items-center gap-2">
                      <HiLocationMarker className="text-[#a2a6ad] text-lg" />
                      <p className="text-base max-md:text-sm">
                        {index.load_pick_up}
                      </p>
                    </div>
                    <div className="flex flex-row justify-start items-center gap-2">
                      <HiLocationMarker className="text-[#a2a6ad] text-lg" />
                      <p className="text-base max-md:text-sm">
                        {index.load_deliver_to}
                      </p>
                    </div>
                  </div>
                  <div className="w-4/12 h-full flex flex-row justify-end items-center text-sm">
                    <BsCurrencyDollar />
                    <p>{index.price}</p>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default AcceptedBoxLoads;
