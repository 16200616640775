import { format } from "date-fns";
import { IoIosClose } from "react-icons/io";

const DetailsFinancialReports = ({
  setModalIsOpen,
  created_at,
  history_number,
  amount,
  status,
  description,
  type,
}) => {
  const formatDateCreate = (dateString) => {
    const formattedDate = format(new Date(dateString), "dd/MM/yyyy");
    return formattedDate;
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-60 z-10 text-black">
      <div className="modal font-normal">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            onClick={() => setModalIsOpen(false)}
          >
            <IoIosClose />
          </button>
          <h2 className="w-full h-full flex flex-row justify-start items-center text-sm font-normal">
            Details Financial Reports
          </h2>
          <div className="w-full h-auto flex flex-col justify-center items-start gap-3">
            <div className="w-full h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
              <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                Created at
              </p>
              <p>{formatDateCreate(created_at)}</p>
            </div>
            <div className="w-full h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
              <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                Type
              </p>
              <p>{type}</p>
            </div>
            <div className="w-full h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
              <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                Number
              </p>
              <p>{history_number}</p>
            </div>
            <div className="w-full h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
              <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                Amount
              </p>
              <p>{amount}</p>
            </div>
            <div className="w-full h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
              <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                Status
              </p>
              <p className="text-green-600">{status}</p>
            </div>
            <div className="w-full h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
              <p className="text-[#a2a6ad] uppercase max-md:text-xs">
                Description
              </p>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsFinancialReports;
