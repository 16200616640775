import { createSlice } from "@reduxjs/toolkit";

const initialState = "";

const messageBoxInfoSlice = createSlice({
  name: "messageBoxInfo",
  initialState,
  reducers: {
   setMessageBoxInfo: (state, action) => {
      return action.payload;
    },
  },
});

export const { setMessageBoxInfo } = messageBoxInfoSlice.actions;
export default messageBoxInfoSlice.reducer;
