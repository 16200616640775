import React, { useEffect, useState, Suspense } from "react";
import { Helmet } from "react-helmet";
import axiosConfig from "../../config/axiosConfig";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import Map from "../../components/module/Map";
import LoadDetail from "../../components/template/LoadDetail";
import { PulseLoader } from "react-spinners";
import MobileMenu from "../../components/module/MobileMenu";
import { useSelector } from "react-redux";

const LoadId = () => {
  let { id } = useParams();
  const darkMode = useSelector((state) => state.darkMode);
  const [loadData, setLoadData] = useState("");
  const [userData, setUserData] = useState("");
  const [loading, setLoading] = useState(true);
  const [reloadPage, setReloadPage] = useState(false);

  useEffect(() => {
    const fetchLoadData = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_LOADAL_BASE_URL;
      const endpoint = process.env.REACT_APP_GET_LOADAL_BY_ID_URL;
      const axiosInstance = axiosConfig(baseURL, token);

      try {
        const response = await axiosInstance.get(endpoint + id);
        setLoadData(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchUserData = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_PERSONAL_INFO_URL;
      const axiosInstance = axiosConfig(baseURL, token);

      try {
        const response = await axiosInstance.get(endpoint);
        setUserData(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchLoadData(), fetchUserData()]);
      setLoading(false);
    };

    fetchData();
    setReloadPage(false);
  }, [id, reloadPage]);

  return (
    <>
      <Helmet>
        <title>Load details</title>
      </Helmet>
      <div
        className={`w-full h-screen p-4 overflow-auto ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        {loading ? (
          <div
            className={`h-full w-full flex flex-col justify-center items-center relative -top-5 ${
              darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
            }`}
          >
            <PulseLoader color="#fbbf24" />
          </div>
        ) : (
          <Suspense fallback={<PulseLoader color="#fbbf24" />}>
            <div className="w-full h-auto flex flex-col justify-start items-center gap-3 pb-20 overflow-auto">
              <div className="w-full h-auto z-10">
                <Map loadData={loadData} />
              </div>
              <div className="w-full h-auto z-20">
                <LoadDetail
                  loadData={loadData}
                  userData={userData}
                  darkMode={darkMode}
                  setReloadPage={setReloadPage}
                />
              </div>
            </div>
          </Suspense>
        )}
        <div className="fixed bottom-0 left-0 right-0 z-20">
          <MobileMenu />
        </div>
      </div>
    </>
  );
};

export default LoadId;
