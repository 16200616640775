import { useSelector } from "react-redux";
import { HiStatusOffline, HiStatusOnline } from "react-icons/hi";
import { LuBell } from "react-icons/lu";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import axiosConfig from "../../config/axiosConfig";
import { useNavigate } from "react-router-dom";

const DisplayStatusUser = () => {
  const userId = Cookies.get("userId");
  const navigate = useNavigate();

  const number = localStorage.getItem("unseenMessageslength");

  const darkMode = useSelector((state) => state.darkMode);
  const statusUser = useSelector((state) => state.statusUser);
  const refetchMessages = useSelector((state) => state.refetchMessages);

  const [messengeLength, setMessengeLength] = useState(number);

  useEffect(() => {
    const fetchMessenge = async () => {
      if (userId) {
        const token = Cookies.get("token");
        // const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
        const baseURL = "https://atoz-messenger-service.888shipping.online";
        // const endpoint = process.env.REACT_APP_NOTE_DEIVER;
        const endpoint =
        `/api/v1/user/${userId}/get-messages`;
        const axiosInstance = axiosConfig(baseURL, token);

        try {
          const res = await axiosInstance.get(endpoint);

          const unseenMessages = res.data.data.data.filter(
            (message) => message.status === "unseen"
          );
          localStorage.setItem("unseenMessageslength", unseenMessages.length);
          setMessengeLength(unseenMessages.length);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchMessenge();
  }, [userId , refetchMessages]);

  return (
    <div
      className={`w-full h-20 flex flex-row justify-center items-center p-4 ${
        darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
      }`}
    >
      <div
        className={`w-10/12 h-auto flex flex-row justify-between items-center rounded-md p-3 max-md:w-full ${
          darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
        }`}
      >
        <div className="flex flex-row justify-center items-center gap-2">
          <p className="cursor-default font-normal uppercase max-md:text-sm max-sm:text-xs">
            {statusUser === "online" ? "Online" : "Offline"}
          </p>
          <span className="text-xl relative top-[0.75px]">
            {statusUser === "online" ? (
              <HiStatusOnline className="text-green-600" />
            ) : (
              <HiStatusOffline className="text-red-600" />
            )}
          </span>
        </div>

        <div>
          <button
            type="button"
            className={`h-8 w-8 flex flex-row justify-center items-center rounded-full relative ${
              darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
            }`}
            onClick={() => navigate("/profile/messages")}
          >
            <LuBell className="text-lg" />
            {messengeLength > 0 && ( // Check if messageLength is greater than 0
              <span className="w-4 h-4 flex flex-row justify-center items-center bg-red-500 text-[10px] text-white rounded-full absolute -top-2 left-5">
                {messengeLength}
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DisplayStatusUser;
