import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DesktopNavber from "../../../components/module/DesktopNavber";
import MobileMenu from "../../../components/module/MobileMenu";
import { useSelector } from "react-redux";
import DisplayStatusUser from "../../../components/module/DisplayStatusUser";
import ListReportsCards from "../../../components/template/ListReportsCards";
import axiosConfig from "../../../config/axiosConfig";
import Cookies from "js-cookie";
import AboutMessage from "../../../components/module/AboutMessage";

const Reports = () => {
  const darkMode = useSelector((state) => state.darkMode);
  const[data,setData] = useState([]);

  useEffect(() => {
    const offerLoadId = Cookies.get("offerLoadId");
    const offerLoadIdJSON = JSON.parse(offerLoadId);

    const fetchReports = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = `/api/v1/user/my-load/reports/${offerLoadIdJSON}`;
      const axiosInstance = axiosConfig(baseURL, token);

      try {
        const response = await axiosInstance.get(endpoint);
        if (response.status === 200) {
          setData(response.data.data)
        }
      } catch (error) {
        console.error(error);
        if (error.status === 429) {
          AboutMessage({
            status: "error",
            message: error.response.message,
            darkMode,
          });
        }
      }
    };
    fetchReports();
  }, [darkMode]);

  return (
    <>
      <Helmet>
        <title>Reports</title>
      </Helmet>
      <div className="w-full h-screen flex flex-col justify-start items-center">
        <DesktopNavber />
        <div
          className={`w-full h-auto flex flex-col justify-between items-center font-normal ${
            darkMode ? "bg-gray-900 text-white" : "bg-[#F1F5F9] text-black"
          }`}
        >
          <div className="w-full min-h-screen flex flex-col justify-start items-center pb-28">
            <div className="w-full h-auto fixed top-0 left-0 right-0 z-10 ">
              <DisplayStatusUser />
            </div>
            <div className="w-full h-auto pt-20 z-0">
              <ListReportsCards data={data} />
            </div>
          </div>
          <div className="fixed bottom-0 left-0 right-0">
            <MobileMenu />
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
