import { format } from "date-fns";
import { Link } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa6";

const TruckCard = ({
  darkMode,
  id,
  created_at,
  truck_name,
  ownership,
  status_truck,
}) => {
  const formatDateCreate = (dateString) => {
    const formattedDate = format(new Date(dateString), "MM/dd/yyyy");
    return formattedDate;
  };

  return (
    <Link
      to={`/truck/${id}`}
      className={`w-4/12 h-auto flex flex-col justify-center items-start gap-4 rounded-md p-4 cursor-pointer font-normal text-sm max-md:w-full ${
        darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
      }`}
    >
      <div className="w-full h-auto flex flex-row justify-between items-center ">
        <div className="cursor-default text-[#a2a6ad] max-md:text-sm">
          <p>Posted on {formatDateCreate(created_at)}</p>{" "}
        </div>
        <FaAngleRight className="text-[#a2a6ad]" />
      </div>
      <div className="flex flex-col justify-center items-start cursor-default max-md:text-sm">
        <p className="text-[#a2a6ad] uppercase max-md:text-xs">
          Truck name
        </p>
        {truck_name !== null ? <p>{truck_name}</p> : <p> - - - </p>}
      </div>
      <div className="w-1/2 flex flex-col justify-center items-start cursor-default max-md:text-sm">
        <p className="text-[#a2a6ad] uppercase max-md:text-xs">
          Ownership
        </p>
        <p>{ownership}</p>
      </div>
      <div className="w-1/2 flex flex-col justify-center items-start cursor-default max-md:text-sm">
        <p className="text-[#a2a6ad] uppercase max-md:text-xs">
          status_truck
        </p>
        <p>{status_truck}</p>
      </div>
    </Link>
  );
};

export default TruckCard;
