import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const NotFound = () => {
  const navigate = useNavigate();
  const darkMode = useSelector((state) => state.darkMode);

  useEffect(() => {
    const token = Cookies.get('token');
    !token && navigate('/login');
  },[navigate]);

  return (
    <div
    className={
      darkMode ?
      "w-full h-screen flex flex-col justify-center items-center bg-slate-900 text-white font-normal" :
      "w-full h-screen flex flex-col justify-center items-center bg-[#F1F5F9] text-black font-normal"
    }
    >
      <div className="w-1/2 h-auto flex flex-col justify-start items-start gap-2 max-sm:w-4/6">
        <h1 className="text-8xl font-semibold max-sm:text-6xl">404</h1>
        <h2 className="text-4xl max-sm:text-lg">UH OH! You're lost.</h2>
        <p className="max-sm:text-sm">
          The page you are looking for does not exist. How you got here is a
          mystery. But you can click the button below to go back to the
          homepage.
        </p>
        <button
          className="px-6 py-2 rounded-md bg-gradient-to-l from-amber-500 to-amber-400"
          onClick={() => navigate("/")}
        >
          Home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
