import { useParams } from "react-router-dom";
import TruckDetails from "../../components/template/TruckDetails";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import MobileMenu from "../../components/module/MobileMenu";
import { useEffect, useState } from "react";
import axiosConfig from "../../config/axiosConfig";
import Cookies from "js-cookie";
import DisplayStatusUser from "../../components/module/DisplayStatusUser";

const TruckId = () => {
  let { id } = useParams();
  const darkMode = useSelector((state) => state.darkMode);
  const [data, setData] = useState("");

  useEffect(() => {
    if (id) {
      const fetchTruckDetails = async () => {
        const token = Cookies.get("token");
        const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
        const endpoint = `api/v1/user/get-truck/${id}`;
        const axiosInstance = axiosConfig(baseURL, token);
        try {
          const response = await axiosInstance.get(endpoint);
          if (response.status === 200) {
            setData(response.data.data);
          }
        } catch (error) {
          console.error(error);
        }
      };

      fetchTruckDetails();
    }
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Load details</title>
      </Helmet>
      <div
        className={`w-full min-h-screen p-4 ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <div className="w-full h-auto fixed top-0 left-0 right-0">
          <DisplayStatusUser />
        </div>
        <div className="w-full h-full flex flex-col justify-start items-center gap-2 pt-16 pb-[5.5rem] ">
          <TruckDetails data={data} />
        </div>
        <div className="fixed bottom-0 left-0 right-0">
          <MobileMenu />
        </div>
      </div>
    </>
  );
};

export default TruckId;
