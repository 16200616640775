import DefaultField from "../../module/field/DefaultField";
import { Formik, Form } from "formik";
import { Checkbox, CircularProgress } from "@mui/material";
import styles from "../../../styles/styles.module.css";
import ImageUploader from "../../module/ImageUploader";
import { useEffect, useState } from "react";
import AboutMessage from "../../module/AboutMessage";
import Cookies from "js-cookie";
import axiosConfig from "../../../config/axiosConfig";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Spinner from "../../module/Spinner";
import InfoDisplayBox from "../../module/InfoDisplayBox";
import MoreInfoBank from "../../module/modal/MoreInfoBank";
import ScreenProtector from "../../module/modal/ScreenProtector";

const BankInfoForm = ({ setActiveStep }) => {
  const navigate = useNavigate();
  const statusRegister = useSelector((state) => state.statusRegister);

  const [bankConfirmationCheck, setBankConfirmationCheck] = useState([]);
  const [bankConfirmationLetter, setBankConfirmationLetter] = useState([]);
  const [screenProtector, setScreenProtector] = useState(false);
  const [quickPay, setQuickPay] = useState("0");
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const [bankInfo, setBankInfo] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(null);
  const [companyStatus, setCompanyStatus] = useState(null);
  const [access, setAccess] = useState(false);

  useEffect(() => {
    const fetchTruckInfo = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_BANK_INFO;
      const axiosInstance = axiosConfig(baseURL, token);

      try {
        const res = await axiosInstance.get(endpoint);
        setBankInfo(res.data.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchTruckInfo();
  }, []);

  useEffect(() => {
    const fetchPersonalInfo = async () => {
      const token = Cookies.get("token");
      const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
      const endpoint = process.env.REACT_APP_PERSONAL_INFO_URL;
      const axiosInstance = axiosConfig(baseURL, token);

      try {
        const res = await axiosInstance.get(endpoint);
        setCompanyStatus(res.data.data.company);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchPersonalInfo();
  }, []);

  if (loading) {
    return (
      <div className="h-screen w-full flex flex-col justify-center items-center bg-[#F1F5F9]">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="w-full h-auto flex flex-col justify-start items-center bg-[#F1F5F9] text-black font-normal py-8">
      <div className="w-11/12 h-auto flex flex-col justify-center items-start gap-10 bg-[#E2E8F0] rounded-md p-8">
        <div className="flex flex-row justify-center items-end gap-2">
          <h1 className="text-xl uppercase max-sm:text-sm">Register</h1>
          <p className="text-xl text-[#1976D2] uppercase max-sm:text-sm">
            Bank Info
          </p>
        </div>
        <Formik
          initialValues={{
            bank_name: "",
            account_type: "",
            name: "",
            account_number: "",
            account_number_confirm: "",
            routing_number: "",
            routing_number_confirm: "",
            quick_pay: "",
            bank_confirmation_check: "",
            bank_confirmation_letter: "",
          }}
          validate={(values) => {
            const errors = {};

            if (values.bank_name && !/^[A-Za-z\s]+$/.test(values.bank_name)) {
              errors.bank_name =
                "Should only contain Latin letters a - z - A - Z";
            }

            if (!values.name) {
              errors.name = "Please enter bank account holder name";
            } else if (!/^[A-Za-z\s]+$/.test(values.name)) {
              errors.name = "Should only contain Latin letters a - z - A - Z";
            }
            if (!values.account_number) {
              errors.account_number = "Please enter account number";
            } else if (!/^[0-9]+$/.test(values.account_number)) {
              errors.account_number = "Should only contain numbers 0 - 9";
            }
            if (!values.account_number_confirm) {
              errors.account_number_confirm =
                "Please enter account number confirm";
            } else if (!/^[0-9]+$/.test(values.account_number_confirm)) {
              errors.account_number_confirm =
                "Should only contain numbers 0 - 9";
            }
            if (values.account_number !== values.account_number_confirm) {
              errors.account_number = "Do not match";
              errors.account_number_confirm = "Do not match";
            }
            if (!values.routing_number) {
              errors.routing_number = "Please enter routing number";
            } else if (!/^[0-9]+$/.test(values.routing_number)) {
              errors.routing_number = "Should only contain numbers 0 - 9";
            } else if (values.routing_number.length !== 9) {
              errors.routing_number = "Routing number must be exactly 9 digits";
            }

            if (!values.routing_number_confirm) {
              errors.routing_number_confirm =
                "Please enter routing number confirm";
            } else if (!/^[0-9]+$/.test(values.routing_number_confirm)) {
              errors.routing_number_confirm =
                "Should only contain numbers 0 - 9";
            } else if (values.routing_number_confirm.length !== 9) {
              errors.routing_number_confirm =
                "Routing number confirm must be exactly 9 digits";
            }

            if (values.routing_number !== values.routing_number_confirm) {
              errors.routing_number = "Do not match";
              errors.routing_number_confirm = "Do not match";
            }

            return errors;
          }}
          onSubmit={async (values) => {
            if (access === false) {
              AboutMessage({
                status: "error",
                message:
                  "To continue, please confirm your agreement to provide access to your banking information.",
              });
              return;
            }
            try {
              values.quick_pay = quickPay;
              values.bank_confirmation_check = bankConfirmationCheck;
              values.bank_confirmation_letter = bankConfirmationLetter;

              if (values.bank_confirmation_check.length === 0) {
                AboutMessage({
                  status: "error",
                  message: "Please upload file void check",
                });
                return;
              }
              setBtnLoading(true);
              setScreenProtector(true);
              const token = Cookies.get("token");
              const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
              const endpoint = process.env.REACT_APP_BANK_INFO_URL;
              const axiosInstance = axiosConfig(baseURL, token);

              const config = {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              };

              const response = await axiosInstance.post(
                endpoint,
                values,
                config
              );
              if (response.status === 200) {
                if (statusRegister === "complete") {
                  navigate("/");
                } else {
                  // setActiveStep("phone-verify");
                  setActiveStep("sign-contract");
                }
              }
            } catch (error) {
              console.error(error);
              if (error.response) {
                const errorsObject = error.response.data.message;
                Object.entries(errorsObject).forEach(([key, errorMessages]) => {
                  errorMessages.forEach((errorMessage) => {
                    AboutMessage({ status: "error", message: errorMessage });
                  });
                });
              } else {
                console.log(error.message);
              }
            } finally {
              setBtnLoading(false);
              setScreenProtector(false);
            }
          }}
        >
          {({ setFieldValue }) => (
            <Form className="w-full h-auto flex flex-col gap-2 font-medium">
              {bankInfo.length !== 0 && (
                <InfoDisplayBox
                  title={"Your driver licence information"}
                  data={bankInfo}
                  setSelected={setSelected}
                  setShowModal={setShowModal}
                />
              )}
              {showModal && (
                <MoreInfoBank data={selected} setShowModal={setShowModal} />
              )}
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="bank_name"
                minLength="0"
                maxLength="255"
                placeholder="Bank Name"
              />
              {companyStatus === "yes" && (
                <div className="flex flex-col justify-center items-start gap-2 py-2">
                  <p className="cursor-default font-normal text-sm text-[#4b5563] max-sm:text-xs">
                    Account type
                  </p>
                  <div className="flex flex-row justify-center items-center gap-6">
                    <div className="flex flex-row justify-start items-center gap-1">
                      <input
                        type="radio"
                        id="personal"
                        name="account_type"
                        value="personal"
                        onChange={() =>
                          setFieldValue("account_type", "personal")
                        }
                      />
                      <label
                        htmlFor="personal"
                        className="relative bottom-[1px] font-normal text-[#4b5563] cursor-default max-sm:text-xs"
                      >
                        Personal
                      </label>
                    </div>
                    <div className="flex flex-row justify-start items-center gap-1">
                      <input
                        type="radio"
                        id="legal"
                        name="account_type"
                        value="legal"
                        onChange={() => setFieldValue("account_type", "legal")}
                      />
                      <label
                        htmlFor="legal"
                        className="relative bottom-[1px] font-normal text-[#4b5563] cursor-default max-sm:text-xs"
                      >
                        Business
                      </label>
                    </div>
                  </div>
                </div>
              )}
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="name"
                minLength="0"
                maxLength="255"
                placeholder="Bank Account Holder Name"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="account_number"
                minLength="0"
                maxLength="255"
                placeholder="Account Number"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="account_number_confirm"
                minLength="0"
                maxLength="255"
                placeholder="Account Number Confirmation"
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="routing_number"
                minLength="0"
                maxLength="255"
                placeholder="Routing Number"
              />
              <DefaultField
                stylefield={styles.stylefield}
                type="text"
                name="routing_number_confirm"
                minLength="0"
                maxLength="255"
                placeholder="Routing Number Confirmation"
                onPaste={(e) => e.preventDefault()}
                onCopy={(e) => e.preventDefault()}
              />
              <div className="flex flex-row justify-start items-center text-white gap-1">
                <Checkbox
                  id="quickPay"
                  value={quickPay}
                  onChange={() => setQuickPay(quickPay === "0" ? "1" : "0")}
                  sx={{ padding: "0.1rem" }}
                  size="small"
                />
                <label
                  htmlFor="quickPay"
                  className="cursor-default font-normal text-base text-black max-sm:text-xs"
                >
                  Default to Quick Pay
                </label>
              </div>
              <p className="text-xs">
                This application requires permission to access your bank
                information for processing payments. Rest assured, your data
                will be encrypted and handled with utmost security. Please
                confirm your consent to proceed.
              </p>
              <div className="flex flex-row justify-start items-start gap-1">
                <Checkbox
                  id="accessCheckbox"
                  value={access}
                  onChange={() => setAccess(!access)}
                  size="small"
                  sx={{
                    padding: "0.1rem",
                    // color: "#fbbf24 ",
                  }}
                  // style={{
                  //   color: access ? "#fbbf24" : "#fbbf24",
                  // }}
                />
                <label
                  htmlFor="accessCheckbox"
                  className="flex flex-row justify-start items-start flex-wrap cursor-default font-normal text-base relative top-[3px] max-sm:text-xs"
                >
                  I agree to provide access to my bank information.
                </label>
              </div>
              <ImageUploader
                title={"Void Check"}
                selectedFiles={bankConfirmationCheck}
                setSelectedFiles={setBankConfirmationCheck}
              />
              <ImageUploader
                title={"Bank Confirmation Letter (Optional)"}
                selectedFiles={bankConfirmationLetter}
                setSelectedFiles={setBankConfirmationLetter}
              />
              <div className="w-full h-auto flex flex-row justify-end items-center gap-1">
                <button
                  type="submit"
                  className="w-1/2 h-auto flex flex-row justify-center items-center px-6 py-2 rounded-md bg-gradient-to-l from-[#1976D2] to-[#6fb7ff] text-white text-base font-normal shadow-md max-sm:text-xs max-md:text-sm"
                >
                  {btnLoading ? (
                    <CircularProgress size={16} sx={{ color: "black" }} />
                  ) : (
                    "Next"
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      {screenProtector && <ScreenProtector />}
    </div>
  );
};

export default BankInfoForm;
