import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";
import FinancialReportCard from "../module/card/FinancialReportCard";
import { v4 as uuidv4 } from "uuid";

const FinancialReportDetails = ({ data, loading }) => {
  const darkMode = useSelector((state) => state.darkMode);

  if (loading) {
    return (
      <div
        className={`fixed h-screen w-full flex flex-col justify-center items-center pb-[11.5rem] ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <PulseLoader color="#fbbf24" />
      </div>
    );
  }

  return (
    <div
      className={`w-full h-full flex flex-col justify-start items-center cursor-default gap-2 font-normal text-sm px-4 ${
        darkMode ? "bg-gray-900 text-white" : "bg-[#F1F5F9] text-black"
      }`}
    >
      <h1 className="w-full h-full text-xl uppercase max-sm:text-xl">
        Wallet History
      </h1>
      <div className="w-full h-screen flex flex-col justify-start items-center text-base gap-2 pb-[14rem]">
        {data.data.length === 0 ? (
          <p className="w-full h-auto text-xs">
            No transactions have been created yet.
          </p>
        ) : data.length !== 0 ? (
          <>
            <div
              className={`w-full h-auto flex flex-row justify-start items-center gap-1 uppercase rounded-md p-4 cursor-pointer font-normal text-sm max-md:w-full ${
                darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
              }`}
            >
              <p>Total Amount :</p>
              <p>{data.totalAmount}</p>
            </div>
            <div className="w-full h-full flex flex-col justify-start items-center gap-2 overflow-auto">
              {data.data.data.map((index) => {
                return <FinancialReportCard key={uuidv4()} {...index} />;
              })}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default FinancialReportDetails;
