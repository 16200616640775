import Cookies from "js-cookie";
import axiosConfig from "../config/axiosConfig";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const useCheckRegistration = ({ setChecked  }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get("token");
      if (token) {
        const baseURL = process.env.REACT_APP_REGISTER_BASE_URL;
        const endpoint = process.env.REACT_APP_PERSONAL_INFO_URL;
        const axiosInstance = axiosConfig(baseURL, token);
        try {
          const response = await axiosInstance.get(endpoint);
          if (response.data.data.register_level_title !== "complete") {
            navigate("/register");
          } else {
            setChecked(false);
          }
        } catch (error) {
          console.error(error);
          setChecked(false);
        }
      } else {
        navigate("/login");
      }
    };
    fetchData();
  }, [navigate, setChecked]);
};

export default useCheckRegistration;