import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PulseLoader } from "react-spinners";

const TruckDetails = ({ data }) => {
  const darkMode = useSelector((state) => state.darkMode);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  if (loading) {
    return (
      <div
        className={`fixed h-screen w-full flex flex-col justify-center items-center pb-[11.5rem] ${
          darkMode ? "bg-gray-900" : "bg-[#F1F5F9]"
        }`}
      >
        <PulseLoader color="#fbbf24" />
      </div>
    );
  }

  return (
    <div
      className={`w-full h-full flex flex-col justify-center items-start gap-4 rounded-md p-4 cursor-default font-normal text-sm ${
        darkMode ? "bg-[#1E293B] text-white" : "bg-[#E2E8F0] text-black"
      }`}
    >
      <div className="w-full flex flex-col justify-center items-start gap-4">
        <h1 className="w-full h-auto text-base font-normal uppercase">
          Truck information
        </h1>
        <div className="w-full h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
          <p className="text-[#a2a6ad] uppercase max-md:text-xs">
            Created at
          </p>
          {data.created_at !== null ? <p>{data.created_at}</p> : <p> - - - </p>}
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              Truck name
            </p>
            {data.truck_name !== null ? (
              <p>{data.truck_name}</p>
            ) : (
              <p> - - - </p>
            )}
          </div>
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              Ownership
            </p>
            {data.ownership !== null ? <p>{data.ownership}</p> : <p> - - - </p>}
          </div>
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              Backdoor height
            </p>
            {data.backdoor_height !== null ? (
              <p>{data.backdoor_height}</p>
            ) : (
              <p> - - - </p>
            )}
          </div>
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              Backdoor width
            </p>
            {data.backdoor_width !== null ? (
              <p>{data.backdoor_width}</p>
            ) : (
              <p> - - - </p>
            )}
          </div>
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              Vehicle height
            </p>
            {data.vehicle_height !== null ? (
              <p>{data.vehicle_height}</p>
            ) : (
              <p> - - - </p>
            )}
          </div>
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              Vehicle length
            </p>
            {data.vehicle_length !== null ? (
              <p>{data.vehicle_length}</p>
            ) : (
              <p> - - - </p>
            )}
          </div>
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              Vin number
            </p>
            {data.vin_number !== null ? (
              <p>{data.vin_number}</p>
            ) : (
              <p> - - - </p>
            )}
          </div>
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              Year vehicle manufacture
            </p>
            {data.year_vehicle_manufacture !== null ? (
              <p>{data.year_vehicle_manufacture}</p>
            ) : (
              <p> - - - </p>
            )}
          </div>
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              Mileage
            </p>
            {data.mileage !== null ? <p>{data.mileage}</p> : <p> - - - </p>}
          </div>
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              Wheel width
            </p>
            {data.wheel_width !== null ? (
              <p>{data.wheel_width}</p>
            ) : (
              <p> - - - </p>
            )}
          </div>
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              Sidedoor height
            </p>
            {data.sidedoor_height !== null ? (
              <p>{data.sidedoor_height}</p>
            ) : (
              <p> - - - </p>
            )}
          </div>
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              Sidedoor width
            </p>
            {data.sidedoor_width !== null ? (
              <p>{data.sidedoor_width}</p>
            ) : (
              <p> - - - </p>
            )}
          </div>
        </div>
        <div className="w-full flex flex-row justify-between items-center">
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              payload
            </p>
            {data.payload !== null ? <p>{data.payload}</p> : <p> - - - </p>}
          </div>
          <div className="w-1/2 h-auto flex flex-col justify-center items-start cursor-default max-md:text-sm">
            <p className="text-[#a2a6ad] uppercase max-md:text-xs">
              max_pallets
            </p>
            {data.max_pallets !== null ? (
              <p>{data.max_pallets}</p>
            ) : (
              <p> - - - </p>
            )}
          </div>
        </div>
        <div className="w-full h-auto flex flex-row justify-start items-center gap-1 flex-wrap pt-2">
          {data.galleries.map((index) => {
            return (
              <img
                className="h-20 w-20 rounded-md"
                key={index.id}
                src={`${process.env.REACT_APP_REGISTER_BASE_URL}/${index?.image}`}
                alt={index.name}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TruckDetails;
